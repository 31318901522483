import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducers';
import { filter, take } from 'rxjs/operators';
import { PartyData } from '../../../shared/models/party-data.model';
import { AdminService } from '../../../shared/services/admin.service';
import { FormGroup, FormControl } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.css']
})
export class HtmlEditorComponent implements OnInit, OnDestroy {

  isLoading: boolean = true;

  mailCampaignForm: FormGroup;

  partyData: PartyData;
  _partyDataSubscription: Subscription = new Subscription();
  jukeboxDataSubscription: Subscription = new Subscription();

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
    {
      name: 'quote',
      class: 'quote',
    },
    {
      name: 'redText',
      class: 'redText'
    },
    {
      name: 'titleText',
      class: 'titleText',
      tag: 'h1',
    },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

  constructor( private store: Store<AppState>,
               private adminService: AdminService ) { }
  

  ngOnInit() {

    this.mailCampaignForm = new FormGroup({
      'emailSubject': new FormControl(null),
      'htmlContent': new FormControl(null),
      'isEmailCampaignActive': new FormControl(null),
    });  

    this.jukeboxDataSubscription = this.store.select('jukeboxData')
      .pipe(
        filter( jukeboxState => jukeboxState.jukeboxData != null )
      )
      .subscribe( jukeboxState => {
        this.isLoading = false;
        this.observePartyData(jukeboxState.jukeboxData.partiesId);
      });

  }


  observePartyData(partiesId: string) {
    
    this._partyDataSubscription = 
      this.adminService.observePartyData(partiesId)
        .pipe(take(1))
        .subscribe( (partyData: PartyData) => {
          this.partyData = partyData;
          this.updateUI();
        });

  }

  updateUI() {

    if (this.partyData) {
      this.mailCampaignForm.get('isEmailCampaignActive').setValue(this.partyData.isEmailCampaignActive);
      this.mailCampaignForm.get('emailSubject').setValue(this.partyData.emailSubject);
      this.mailCampaignForm.get('htmlContent').setValue(this.partyData.emailHtmlText);
    }
  }

  onMailCampaignSaveFormSubmit() {
    this.partyData.isEmailCampaignActive = this.mailCampaignForm.get('isEmailCampaignActive').value;
    //let isEmailCampaignActive: boolean = this.mailCampaignForm.get('isEmailCampaignActive').value;
    this.partyData.emailSubject = this.mailCampaignForm.get('emailSubject').value;
    // let emailSubject: string = this.mailCampaignForm.get('emailSubject').value;
    this.partyData.emailHtmlText = this.mailCampaignForm.get('htmlContent').value;
    //let mailCampaign: string = this.mailCampaignForm.get('htmlContent').value;
    this.adminService.updatePartyData(this.partyData);
  }


  ngOnDestroy(): void {
    this._partyDataSubscription.unsubscribe();
  }

}
