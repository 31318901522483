import { Component, OnInit, Input } from '@angular/core';
import { Track } from 'src/app/shared/models/track.model';
import { JukeboxService } from 'src/app/shared/services/jukebox.service';
import { SubdomainService } from '../../../../shared/services/subdomain.service';

@Component({
  selector: 'app-song-item',
  templateUrl: './song-item.component.html',
  styleUrls: ['./song-item.component.css']
})
export class SongItemComponent implements OnInit {

  @Input() track: Track;

  constructor( public jukeboxService: JukeboxService,
               public subdomainService: SubdomainService ) { }

  ngOnInit() {
  }

  onTrackSelected() {
    this.jukeboxService.searchOpenSelectedTrack = this.track;
  }
  
}
