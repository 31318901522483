
<br>

<div *ngIf="isLoading || isProcessingData" class="container text-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div *ngIf="!isLoading && !isProcessingData && this.salesTotal == 0"
    class="row">
  <div class="col text-center">
    <h4>No hay canciones en las fechas seleccionadas</h4>
  </div>   
</div>

<div *ngIf="!isLoading && !isProcessingData">

  <div class="row">
    <div class="col-12" >
      <h4 style="text-align:center;">Solicitudes por día</h4>

      <ngx-charts-line-chart
        [view]="salesPerDayChartDimensions"
        [scheme]="salesPerDayChartColorScheme"
        [legend]="false"
        [showXAxisLabel]="false"
        [showYAxisLabel]="true"
        [xAxis]="true"
        [yAxis]="true"
        [xAxisLabel]="salesPerDayxAxisLabel"
        [yAxisLabel]="salesPerDayyAxisLabel"
        [timeline]="true"
        [results]="salesPerDayChartData">
      </ngx-charts-line-chart>
    </div>
  </div>
  
  <br>

  <div class="row">
    <div class="col-12" >
      <h4 style="text-align:center;">Solicitudes por plataforma (Web / Tablet)</h4>

      <ngx-charts-advanced-pie-chart
        [view]="salesPerPlatformDimensions"
        [scheme]="salesPerPlatformColorScheme"
        [results]="salesPerPlatformChartData"
        [gradient]="true">
      </ngx-charts-advanced-pie-chart>
    </div>
  </div>


  <br>

  <div class="row">
    <div class="col-12" joyrideStep="step-6" [stepContent]="customJoyrideStepContent2">
      <h4 style="text-align:center;">Top 5 grupos</h4>
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr style="text-align: center;">
              <th style="text-decoration: underline;">GRUPO</th>
              <th style="text-decoration: underline;">SOLICITUDES</th>
            </tr>
          </thead>
          <tbody *ngIf="topArtistsMap.size > 0">
            <tr *ngFor="let iTrack of topArtistsMap | keyvalue: valueDescOrder">
              <th scope="row"> {{ iTrack.key }} </th>
              <td style="text-align: center;">{{ iTrack.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>


  <br><br>
  
  <ng-template #customJoyrideStepContent1>
    <i class="fas fa-chart-pie"></i>
    Podrás ver cuántas canciones han sido <span style="color: green;">seleccionadas</span>.
  </ng-template>

  <ng-template #customJoyrideStepContent2>
    <i class="fas fa-chart-pie"></i>
    También te damos una vista de la música más popular en tu local según las elecciones de tus clientes.
  </ng-template>

</div>