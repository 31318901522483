


import { Pipe, PipeTransform } from '@angular/core';
import { Commerce } from 'src/app/shared/models/commerce.model';

@Pipe({
  name: 'commerceFilter'
})
export class CommerceFilterPipe implements PipeTransform {
  
  transform(commerces: Commerce[], searchText: string, musicStyleFilterSelectedItems: any[]): any {
    
    if(!commerces) return [];
    if(!searchText && !musicStyleFilterSelectedItems) return commerces;

    searchText = searchText.toLowerCase();

    // sale items array, items which match and return true will be kept, false will be filtered out
    return commerces.filter( (iCommerce: Commerce) => {
      return (iCommerce.place?.profile['displayName'].toLowerCase().includes(searchText) 
            || iCommerce['subdomain'].toLowerCase().includes(searchText))
            && this.isMusicStyleInCommerce(iCommerce, musicStyleFilterSelectedItems)
    });

  }

  isMusicStyleInCommerce(iCommerce: Commerce, musicStyleFilterSelectedItems: any[]): boolean {    
    let isMusicStyle: boolean = false;
    musicStyleFilterSelectedItems.forEach( iMusicStyle => {
      if ( iCommerce.place?.profile?.music?.includes(iMusicStyle.item_text) ) {
        isMusicStyle = true;
      }
    });
    return isMusicStyle;
  }

}
