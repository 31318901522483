import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';

import { CommerceFilterPipe } from './pipes/commerce-filter.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SaleFilterPipe } from './pipes/sale-filter.pipe';
import { SongFilterPipe } from './pipes/song-filter.pipe';
import { StylizePipe } from './pipes/stylize.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { SharedRoutingModule } from './shared-routing.module';



@NgModule({
  declarations: [
    SongFilterPipe,
    TruncatePipe,
    SaleFilterPipe,
    SafeHtmlPipe,
    CommerceFilterPipe,
    StylizePipe,
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  exports: [
    CommonModule,
    SongFilterPipe,
    SaleFilterPipe,
    TruncatePipe,
    SafeHtmlPipe,
    CommerceFilterPipe,
    StylizePipe,
    NgMultiSelectDropDownModule,
    NgbModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ]
})
export class SharedModule { }
