


import { JukeboxRealtime } from './jukebox-realtime.model';
export class Jukebox {
  jukebox_configuration: JukeboxConfig
  jukebox_data: JukeboxData
  jukebox_realtime: JukeboxRealtime
}

export class JukeboxConfig {  
  id: string;
  adminExitCode: string;
  dialogConfiguration?: DialogConfiguration;
  dialogConfigurationWeb?: DialogConfigurationWeb;
  dialogType: string;
  dialogTypeWeb: string;
  displayName: string;
  imgUrlDialogFormBk: string;
  imgUrlPopupInactivityBk: string;
  songOrder: string;
  songQueueMaxSize: number;
  isSongSearchOpen: boolean;
  isJukeboxOnline: boolean;
  timeDurationPromoPopup: number;
  timeElapsedPromoPopup: number;
  timeElapsedRepeatArtist?: number;
  timeElapsedRepeatSong: number;
  instagramUser?: string;
  ratingSiteUrl?: string;
  subdomain: string;
  imgUrlBanner?: string;
  imgUrlPlaylistHeader: string;
  imgs_url: {
      popups: AdImage[]
  }
  ads_mode: AdsMode;
}

export class JukeboxData {  
  id: string;
  partiesId: string;
  playlistsId: string;
  master: boolean;  
  buildVersionCode: number;
  updateVersionDate: string;  
  company: string;
}

export class DialogConfiguration {  
  name: FormGroupText;
  shortText: FormGroupText;
  longText: FormGroupText;
  number: FormGroupText;  
  year: FormGroupText;
  gender: FormGroupRadio;
  privacyText: FormGroupLabel;
  termsCheckbox: FormGroupCheckbox;
  primaryButton: FormGroupButton;
  secondaryButton: FormGroupButton;
}

export class DialogConfigurationWeb {  
  name: FormGroupWeb;
  email: FormGroupWeb;
  promocode: FormGroupWeb;
  shortText: FormGroupWeb;
  longText: FormGroupWeb;
  primaryButton: FormGroupWeb;
  privacyText: FormGroupWeb;
  termsCheckbox: FormGroupWeb;
}

export class DialogConfigurationAds {  
  name: FormGroupWeb;
  email: FormGroupWeb;
  promocode: FormGroupWeb;
  shortText: FormGroupWeb;
  longText: FormGroupWeb;
  primaryButton: FormGroupWeb;
  privacyText: FormGroupWeb;
  termsCheckbox: FormGroupWeb;
}

export class FormGroupWeb {  
  isActive: boolean;  
  isRequired?: boolean;
  hintText?: string;  
  maxLenght?: boolean;
  content?: string;
}

export class FormGroupText {  
  isEditTextActive: boolean;
  isTextViewActive: boolean;
  isRequired: boolean;
  hintText: string;  
  labelText: string;
  maxLenght: number;
}

export class FormGroupRadio {  
  isActive: boolean;
  isRequired: boolean;
}

export class FormGroupLabel {  
  isActive: boolean;
  labelText: string;
}

export class FormGroupCheckbox {  
  isActive: boolean;
  isRequired: boolean;
  labelText: string;
}

export class FormGroupButton {  
  isActive: boolean;
  labelText: string;
}


export class AdImage {  
  id: string;
  url: string;
  tags: string;
  isActive: boolean;
  storagePath: string;
}


export class AdsMode {  
  dialogConfigurationAds: DialogConfigurationAds;
  imgUrlDialogFormBk: string;
  isAdsModeActive: boolean;
  isDataGatheringActive: boolean;
}