
<!--   
<iframe width="560" height="315" src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen></iframe> 
-->


<br><br>

<div *ngIf="isLoading" class="container text-center">
  <div class="spinner-border" role="status" style="color: whitesmoke">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div *ngIf="!isLoading" class="container-fluid">

  <div *ngIf="isFeedbackMessageSent" class="d-flex justify-content-center">
    <div class="card text-center" style="max-width: 90%;">
      <div class="card-body">
        <h4 class="card-title">¡Gracias!</h4>
        <h6 class="card-subtitle text-muted">Mensaje enviado</h6>
      </div>
      
      <!-- Image 
      <img src="/pix/samples/12s.jpg" alt="Photo of sunset">-->
      
      <!-- Text Content -->
      <div class="card-body">
        <p class="card-text">Apreciamos tu interés. No dudes en volver a escribir si tienes más sugerencias.</p>
      </div>
      
    </div>
  </div>

  <div *ngIf="!isFeedbackMessageSent" class="d-flex justify-content-center">
    <form [formGroup]="feedbackForm" style="max-width: 90%;">

      <div class="text-center">
        <ngb-rating [(rate)]="currentRate" max="5" [readonly]="false" (click)="onClickRate()">
          <ng-template let-fill="fill" let-index="index">
            <span class="star" [class.filled]="fill === 100">&#9733;</span>
          </ng-template>
        </ngb-rating>
      </div>

      <div class="text-center">
        <p style="color: white; font-size: 1em;">
          <br/>
          ¿ Nos ayudas a mejorar {{ commerce?.place?.profile?.displayName }} ?
        </p>
      </div>

      <div class="input-group mb-2">
        
        <textarea type="text" name="message" class="form-control" value="" placeholder="message"
                formControlName="message" maxlength="256" rows="8" placeholder="Cuéntanos tu experiencia..."
                [ngClass]="{ 'is-invalid': feedbackForm.get('message').invalid && feedbackForm.get('message').touched,
                            'is-valid': feedbackForm.get('message').valid && feedbackForm.get('message').dirty }">  
        </textarea>
      </div>

      <div class="input-group mb-2">
        <div *ngIf="feedbackForm.get('message').invalid && feedbackForm.get('message').dirty" 
              style="color: coral; font-size: small;"
              class="notification is-danger">
              Debes comentar algo para enviar el mensaje
        </div>
      </div>

      <div class="input-group mb-3">
        <div class="input-group-append">
          <re-captcha 
            #captchaRef="reCaptcha"
            [siteKey] = "siteKey" 
            size = "invisible"
            (resolved)="$event && onFeedbackFormSubmit($event)">
          </re-captcha>
        </div>
      </div>
      
      <div class="d-flex justify-content-center mt-3">
        <button name="button" class="btn btn-primary"
                [disabled]="!feedbackForm.valid || !feedbackForm.dirty"
                (click)="captchaRef.execute()">
                Enviar mensaje
        </button>
      </div>

    </form>
  </div>
        
</div>

