import { Component, OnInit, Input } from '@angular/core';
import { PromoCode } from 'src/app/shared/models/promo-code.model';

@Component({
  selector: 'app-promo-code-item',
  templateUrl: './promo-code-item.component.html',
  styleUrls: ['./promo-code-item.component.css']
})
export class PromoCodeItemComponent implements OnInit {

  @Input() promoCode: PromoCode;

  constructor() { }

  ngOnInit() {
  }

}
