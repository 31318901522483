import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/app.reducers';
import { NgfireHelperService } from 'src/app/shared/services/ngfire-helper.service';
import { UIService } from 'src/app/shared/services/ui.service';

import { Commerce, MenuImage } from '../models/commerce.model';
import { SetCommerceAction, SetCommercesAction, UnsetCommerceAction } from '../redux/actions/commerce.actions';
import { LoadingAddAction, LoadingRemoveAction } from '../redux/actions/ui.actions';
import { SubdomainService } from './subdomain.service';

@Injectable({
  providedIn: 'root'
})
export class CommerceService {
  
  commerceId: string;
  
  commerceSubscription: Subscription = new Subscription();
  commercesSubscription: Subscription = new Subscription();

  constructor( private alog: NGXLogger,
               private uiService: UIService,
               private store: Store<AppState>,
               public subdomainService: SubdomainService,
               private ngFireService: NgfireHelperService ) { }


  cancellSubscriptions() {
    this.commerceSubscription.unsubscribe();
    this.commercesSubscription.unsubscribe();
  }

  cancelCommerceSubscription() {
    this.store.dispatch( new UnsetCommerceAction() ); 
    this.commerceSubscription.unsubscribe();
  }

  /**
   * Observe all commerces
   */
  observeCommerces() {
    this.alog.debug('CommerceService > observeCommerces START');
    this.store.dispatch( new LoadingAddAction('commerces') );
    this.commercesSubscription.unsubscribe();

    this.commercesSubscription = this.ngFireService.observeCommerces()
      .subscribe(
        (commerces: Commerce[]) => {
          this.alog.debug('CommerceService > observeCommerces response', commerces);
          this.store.dispatch( new SetCommercesAction(commerces) ); 
          this.store.dispatch( new LoadingRemoveAction('commerces') );
        },
        (err) => {
          this.alog.debug('CommerceService > observeCommerces err', err);
          this.store.dispatch( new LoadingRemoveAction('commerces') );
        },
        () => {
          this.alog.debug('CommerceService > observeCommerces complete');
        }
      );
  }

  /**
   * Observe all commerces
   */
  observeCommerce(commerceId: string) {
    this.alog.debug('CommerceService > observeCommerce START - commerceId:', commerceId);
    this.store.dispatch( new LoadingAddAction('commerce') );

    this.commerceSubscription.unsubscribe();
    this.commerceId = commerceId;
    this.commerceSubscription = this.ngFireService.observeCommerce(commerceId)
      .subscribe(
        (commerce: Commerce) => {
          this.alog.debug('CommerceService > observeCommerce response', commerce);
          this.store.dispatch( new SetCommerceAction(commerce) ); 
          this.store.dispatch( new LoadingRemoveAction('commerce') );
        },
        (err) => {
          this.alog.debug('CommerceService > observeCommerce err', err);
        }
      );
  }


  updateCommerceMenuImage(commerceId: string, menuImage: MenuImage): Promise<any> {
    this.alog.debug('CommerceService > updateCommerceMenuImage menuImage', menuImage);

    this.store.dispatch( new LoadingAddAction('commerce-menu-image-update') );

    return this.ngFireService.updateCommerceMenuImage(commerceId, menuImage)
      .then((_) => {
        this.uiService.showSuccess('Se han guardado los cambios en tu menú');
        this.store.dispatch( new LoadingRemoveAction('commerce-menu-image-update') );
      })
      .catch((err) => {
        this.alog.error('AdminService > updatePopupImage err', err);
        this.uiService.showError('Se ha producido un error. Vuelve a intentarlo mas tarde');
        this.store.dispatch( new LoadingRemoveAction('commerce-menu-image-update') );
      });    
  }


  deleteCommerceMenuImage(commerceId: string, menuImageId: string): Promise<any> {
    this.alog.debug('CommerceService > deleteMenuImage menuImageId', menuImageId);

    this.store.dispatch( new LoadingAddAction('commerce-menu-image-remove') );

    return this.ngFireService.deleteCommerceMenuImage(commerceId, menuImageId)
      .then((_) => {
        this.uiService.showSuccess('Se han guardado los cambios en tu menú');
        this.store.dispatch( new LoadingRemoveAction('commerce-menu-image-remove') );
      })
      .catch((err) => {
        this.alog.error('AdminService > deletePopupImage err', err);
        this.uiService.showError('Se ha producido un error. Vuelve a intentarlo mas tarde');
        this.store.dispatch( new LoadingRemoveAction('commerce-menu-image-remove') );
      });    
  }

}
