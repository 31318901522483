


import * as fromMessages from '../actions/messages.actions';
import { Message } from '../../models/message.model';

export interface MessageState {
  messages: Message[];
}

// export interface AppState extends AppState {
//   sales: SaleState;
// }

const initialState: MessageState = {
  messages: []
};


export function messagesReducer( state = initialState, action: fromMessages.actions ): MessageState {

  switch ( action.type ) {

    case fromMessages.SET_MESSAGES:
      return {
        messages: [
          ...action.messages.map( item => {
            return {
                ...item
            };
          })
        ]
      };

    case fromMessages.UNSET_MESSAGES:
      return {
        messages: []
      };

    default:
      return state;

  }
}