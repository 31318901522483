


import * as fromCommerce from '../actions/commerce.actions';
import { Commerce } from '../../models/commerce.model';

export interface CommerceState {
  commerces: Commerce[];
  commerce: Commerce;
}

// export interface AppState extends AppState {
//   sales: SaleState;
// }

const initialState: CommerceState = {
  commerces: [],
  commerce: null
};


export function commerceReducer( state = initialState, action: fromCommerce.acciones ): CommerceState {

  switch ( action.type ) {

    case fromCommerce.SET_COMMERCES:
      return {
        ...state, 
        commerces: [
          ...action.commerces.map( item => {
            return {
                ...item
            };
          })
        ],
      };

    case fromCommerce.UNSET_COMMERCES:
      return {
        ...state,
        commerces: []
      };

    case fromCommerce.SET_COMMERCE:        
      return {
        ...state,
        commerce: {
          ...action.commerce
        }
      };
  
      case fromCommerce.UPDATE_COMMERCE:
        // TODO seems incomplete
        return {
            ...state
        };

      case fromCommerce.UNSET_COMMERCE:
        return {
          ...state,
          commerce: null,
        };

    default:
        return state;

  }
}