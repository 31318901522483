


<div class="row">
  <div class="col-12 col-sm-12 col-md-12"
        style="color: whitesmoke">

      <form [formGroup]="leadBasicForm"
            (ngSubmit)="onLeadBasicFormSubmit()">

            <input type="text" class="form-control" 
                    id="name"
                    formControlName="name"
                    maxlength="32"
                    placeholder="(Opcional) Nombre">

            <input type="text" class="form-control" 
                    id="email"
                    [ngClass]="{ 'is-invalid': leadBasicForm.get('email').invalid && leadBasicForm.get('email').touched,
                                 'is-valid': leadBasicForm.get('email').valid && leadBasicForm.get('email').touched }"
                    formControlName="email"
                    oninput="if(this.value.length >= 4) { this.value = this.value.trim(); }"
                    maxlength="64"
                    placeholder="Email">
            
              <span style="color: coral"
                    *ngIf="leadBasicForm.get('email').invalid 
                          && leadBasicForm.get('email').touched">
                      Escribe tu correo para solicitar la canción
              </span>

            <br>
            <label class="btn btn-default" style="background-color: transparent;color: whitesmoke;">
              <input type="checkbox" name="conditions" formControlName="terms"> Aceptar terminos y condiciones (<a href="http://paviado.com/privacy-policy">Ver</a>)<br>   
            </label>

            <br>
            <button type="submit" name="" id="" class="btn btn-primary" [disabled]="!leadBasicForm.valid">Solicitar!</button>
            <br>

      </form>

      <br>
  </div>
</div>