import { Injectable } from '@angular/core';
import { Observable, EMPTY, from, of } from 'rxjs';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { SubdomainService } from '../../../shared/services/subdomain.service';
import { CommerceService } from '../../../shared/services/commerce.service';
import { NGXLogger } from 'ngx-logger';
import { JukeboxService } from '../../../shared/services/jukebox.service';



@Injectable()
export class JukeboxResolver implements Resolve<any> {
 
  constructor( private alog: NGXLogger,
               private router: Router,
               private jukeboxService: JukeboxService,
               private subdomainService: SubdomainService ) { }
 
  /**
   * Subscribe to selected jukebox
   */
  async resolve( route: ActivatedRouteSnapshot,
                 state: RouterStateSnapshot ): Promise<any> {

    const jukeboxId = route.params['jukeboxId'];

    // Check if selected jukebox exists in subdomain
    const jukeboxes_parties = Object.keys(this.subdomainService.subdomain.jukeboxes_parties);
    if ( !jukeboxes_parties.includes(jukeboxId) ) {      
      return this.router.navigate(['/']);
    }
    
    await this.jukeboxService.getJukeboxData(jukeboxId);
    
    // Observe selected jukebox
    this.alog.debug('jukebox-resolver > resolve - jukeboxId, jukeboxIdSubscribed:', jukeboxId, this.jukeboxService.jukeboxIdSubscribed);
    if (!this.jukeboxService.jukeboxIdSubscribed || jukeboxId != this.jukeboxService.jukeboxIdSubscribed) {
      // TODO Check possible memory leak if change jukebox without unsubscribe
      this.jukeboxService.cancellSubscriptions();
      this.jukeboxService.observeJukebox(jukeboxId);
    }
    
    return Promise.resolve();
  }


  

}