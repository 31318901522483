import { Injectable } from '@angular/core';
import { Observable, EMPTY, from, of } from 'rxjs';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { SubdomainService } from '../../../shared/services/subdomain.service';
import { CommerceService } from '../../../shared/services/commerce.service';
import { NGXLogger } from 'ngx-logger';
import { JukeboxService } from '../../../shared/services/jukebox.service';
import { MessageService } from '../../../shared/services/message.service';
import { AuthService } from 'src/app/shared/services/auth.service';



@Injectable()
export class MessagesResolver implements Resolve<any> {
 
  constructor( private alog: NGXLogger,
               private router: Router,
               private authService: AuthService,
               private jukeboxService: JukeboxService,
               private messageService: MessageService,
               private commerceService: CommerceService,
               private subdomainService: SubdomainService ) { }
 
  /**
   * Subscribe to selected jukebox
   */
  resolve( route: ActivatedRouteSnapshot,
           state: RouterStateSnapshot ): Promise<any> {

    if (!this.subdomainService.subdomain.commerces) {
      return this.router.navigate(['/']);
    }

    this.messageService.commerceId = this.commerceService.commerceId;
    // this.messageService.chatGroupId = this.authService.userId;
    
    return Promise.resolve();
  }


  

}