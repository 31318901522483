import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { User } from '../models/user.model';
import { NGXLogger } from 'ngx-logger';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducers';
import { UIService } from 'src/app/shared/services/ui.service';
import { NgfireHelperService } from 'src/app/shared/services/ngfire-helper.service';
import { LOADING_ITEM_FEEDBACK_MESSAGE_PUSH, LoadingAddAction, LoadingRemoveAction } from 'src/app/shared/redux/actions/ui.actions';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  

  constructor(private log: NGXLogger,
              private store: Store<AppState>,
              private uiService: UIService,
              private ngFireService: NgfireHelperService) { }


  /**
   * 
   * @param commerceId 
   * @param message 
   * @param user 
   */
  async pushFeedbackMessage(commerceId: string, message: any, rating: number, user: User) {

    this.store.dispatch( new LoadingAddAction(LOADING_ITEM_FEEDBACK_MESSAGE_PUSH) );
    
    var now = moment().locale('es').format('YYYY-MM-DDTHH:mm:ssZ');

    let feedbackMessage = {
      id: '',
      commerceId: commerceId,
      rating: rating,
      message: message,
      user: user,
      datetime: now
    }

    try {
      await this.ngFireService.pushFeedbackMessage(feedbackMessage);
      return Promise.resolve();

    } catch (err) {
      this.log.error("FeedbackService > pushQueuedTrack - catch", err);
      return Promise.reject();

    } finally {
      this.store.dispatch( new LoadingRemoveAction(LOADING_ITEM_FEEDBACK_MESSAGE_PUSH) );
    }
  }
}
