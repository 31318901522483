import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthAnonymousGuard implements CanActivate {

  constructor(
    private log: NGXLogger,
    private authService: AuthService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean  {

    if (this.authService.isLoggedInAny) {
      return true;
      
    } else {      
      return new Promise((resolve, reject) => {
        this.authService.signInAnonymously()
          .then((resp) => {
            resolve(true);
          })
          .catch((err) => {
            this.log.error('AuthAnonymousGuard > canActivate - err:', err);
            resolve(false);
          });
         
        });
      }
    }
    
  }
