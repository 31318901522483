


<div class="container-fluid">

  <div *ngIf="isLoading" class="container text-center">
    <br><br>
    <div class="spinner-border" role="status" style="color: purple">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div class="progress" *ngIf="uploadProgress && isLoading">
    <div class="progress-bar progress-bar-striped bg-success" role="progressbar" 
          [style.width]="(uploadProgress | async) + '%'" 
          [attr.aria-valuenow]="(uploadProgress | async)" 
          aria-valuemin="0" aria-valuemax="100">
    </div>
  </div>

  <div *ngIf="!isLoading && commerce"
       class="container">  
    
    <br>

    <div class="row">
      <div class="col-12">
        <div>
          <h4>Menú - {{ this.commerce.place.profile.displayName }}</h4> 
          <p></p>

        </div>

        <div *ngIf="commerceMenuImages.length < MAX_MENU_IMAGES"
             class="text-center">
          <div class="upload-btn-wrapper">
            <button class="btn-upload">Subir menú</button>
            <input type="file" name="file" accept=".png,.jpg" (change)="upload($event, 'img_menu')"/>
          </div>
        </div>
        
        <br>
        
        <div class="text-center">
          
          <div *ngIf="commerceMenuImages.length > 0">
            <div *ngFor="let iMenuImage of commerceMenuImages; let i = index"
                 class="card bg-transparent">
              <div style="width:100%; text-align:center">                
                <div class="img-wrap">
                  <span (click)="onClickDeleteMenuImage(iMenuImage.id)" class="close" style="cursor: pointer;"><i class="far fa-times-circle"></i></span>
                  <img src="{{ iMenuImage.url }}" style="height: 300px; width: 200px;">
                </div>
              </div>
              <div class="card-body">
                <div class="upload-wrap text-center">
                  <button *ngIf="iMenuImage.isActive" type="button" class="btn btn-success" (click)="onClickToogleIsActive(iMenuImage.id)">Activa</button>
                  <button *ngIf="!iMenuImage.isActive" type="button" class="btn btn-danger" (click)="onClickToogleIsActive(iMenuImage.id)">Inactiva</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <br>

  </div>

</div>
