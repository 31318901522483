import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StatisticsComponent } from '@app/admin/statistics/statistics.component';

import { ChatItemComponent } from './booking/chat-item/chat-item.component';
import { ChatListComponent } from './booking/chat-list/chat-list.component';
import { MenuImagesComponent } from './commerce/menu-images/menu-images.component';
import { HomeAdminComponent } from './home/home-admin.component';
import { JukeboxMusicComponent } from './jukebox-config/jukebox-music/jukebox-music.component';
import { JukeboxConfigComponent } from './jukebox-config/jukebox-settings/jukebox-config.component';
import { JukeboxTracksComponent } from './jukebox-config/jukebox-tracks/jukebox-tracks.component';
import { HtmlEditorComponent } from './jukebox-config/mail-campaign/html-editor.component';
import { PromoCodesComponent } from './jukebox-config/promo-codes/promo-codes.component';
import { PromoImagesComponent } from './jukebox-config/promo-images/promo-images.component';
import { LeadsDownloadComponent } from './leads-download/leads-download.component';
import { ProfileComponent } from './profile/profile.component';
import { SaleListComponent } from './sale-list/sale-list.component';
import { PlaylistsComponent } from './spotify/playlists/playlists.component';

export const routes: Routes = [

  { path: '', component: HomeAdminComponent },

  { path: 'profile', component: ProfileComponent },
  { path: 'playlists', component: PlaylistsComponent },
  { path: 'jukebox-music', component: JukeboxMusicComponent },
  { path: 'jukebox-tracks', component: JukeboxTracksComponent },
  // Subscription required --> , canActivate: [SubscriptionBasicGuard]
  { path: 'monitor', component: SaleListComponent },
  { path: 'statistics', component: StatisticsComponent },
  { path: 'promo-codes', component: PromoCodesComponent },
  { path: 'menu-images', component: MenuImagesComponent },
  { path: 'promo-images', component: PromoImagesComponent },
  { path: 'mail-campaign', component: HtmlEditorComponent },
  { path: 'jukebox-config', component: JukeboxConfigComponent },
  { path: 'leads-download', component: LeadsDownloadComponent },
  { path: 'booking', 
    children: [
      { path: '', component: ChatListComponent, pathMatch: 'full' },
      { path: ':chatId', component: ChatItemComponent },
    ]
  },
  
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
