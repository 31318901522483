import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }



  setUserFeedbackOnLocalStorage(rate: string) {
    var now = moment().locale('es').format('YYYY-MM-DDTHH:mm:ssZ');
    localStorage.setItem('user_feedback_date', now);
    localStorage.setItem('user_feedback', rate);
  }

  getCommerceUserFeedbackRating(): number {
    return +localStorage.getItem('user_feedback') || 4;
  }

  setCommerceUserFeedbackRating(rating: number) {
    let rate: string = rating.toString();
    localStorage.setItem('user_feedback', rate);
  }
}
