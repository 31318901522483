


<div class="container-fluid">
  
  <!-- <div class="row">
    <div class="col-12">
      <a [routerLink]="['../../']">
        <i class="fa fa-arrow-circle-left fa-3x" aria-hidden="true"></i>
      </a>
    </div>
  </div> -->
  
  <div *ngIf="isLoading" class="container text-center">
    <div class="spinner-border" role="status" style="color: whitesmoke">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div *ngIf="!isLoading">
    
    <div *ngIf="trackSelected"
         class="row">
      <div class="col-12 text-center">
        <app-form-track
          [track] = trackSelected>
        </app-form-track>
      </div>
    </div>

    <!-- Loading service -->
    <br>
    

    <div *ngIf="jukeboxConfiguration"
         class="container text-center">

      <div class="text-info" role="status"
            *ngIf="!jukeboxConfiguration.isJukeboxOnline || !this.isCurrentlyPlaying">
            <h3>La gramola no se encuentra activa en estos momentos  :/</h3>
      </div>

      <div class="text-info" role="status"
            *ngIf="jukeboxConfiguration.isJukeboxOnline && playQueueSize >= jukeboxConfiguration.songQueueMaxSize">
            <h3>Hay {{ playQueueSize }} canciones en lista de espera. Vuelve cuando acabe el hit actual..</h3>
      </div>  

      <div class="text-info" role="status"
            *ngIf="tooManyRequestsByUser">
            <h3>Has pedido una canción hace poco. Limitamos el tiempo para permitir que otros puedan participar. Puedes solicitar de nuevo al finalizar la siguiente canción.</h3>
      </div>  
    </div>
      
    <!-- FORMS SECTION -->
    <div *ngIf="jukeboxData && jukeboxConfiguration 
                && trackSelected && jukeboxConfiguration.isJukeboxOnline 
                && playQueueSize < jukeboxConfiguration.songQueueMaxSize 
                && !tooManyRequestsByUser
                && isCurrentlyPlaying">
      
      <!-- PROMO CODE -->
      <div class="container" 
          *ngIf="jukeboxConfiguration.dialogTypeWeb == 'promos'">
        <app-form-promos [trackSelected]="trackSelected"
                         [partyId]="jukeboxData.partiesId">
        </app-form-promos>    
      </div>

      <!-- CUSTOM -->
      <div class="container" 
            *ngIf="jukeboxConfiguration.dialogTypeWeb == 'custom'">    
        <app-form-custom 
          [trackSelected]="trackSelected"
          [dialogConfigurationWeb]="jukeboxConfiguration.dialogConfigurationWeb"
          [partyId]="jukeboxData.partiesId"
          [user]="user"
          (trackRequested)="onTrackRequested($event)">
        </app-form-custom>    
      </div>

      <!-- LEAD SECTION -->
      <div class="container text-center"
           *ngIf="jukeboxConfiguration.dialogTypeWeb != 'promos' && jukeboxConfiguration.dialogTypeWeb != 'custom'">

        <app-form-leads [trackSelected]="trackSelected" 
                        [partyId]="jukeboxData.partiesId"
                        [user]="user">
        </app-form-leads>
      </div>

    </div>

  </div>
  
</div>
  
  
  