import { ActionReducerMap } from '@ngrx/store';

import * as fromUI from './shared/redux/reducers/ui.reducer';
import * as fromAuth from './shared/redux/reducers/auth.reducer';
import * as fromSales from './shared/redux/reducers/sale.reducer';
// import * as fromJukebox from './shared/redux/reducers/jukebox.reducer';
import * as fromJukeboxData from './shared/redux/reducers/jukebox-data.reducer';
import * as fromJukeboxRealtime from './shared/redux/reducers/jukebox-realtime.reducer';
import * as fromJukeboxConfiguration from './shared/redux/reducers/jukebox-configuration.reducer';
import * as fromQueuedTracks from './shared/redux/reducers/queued-tracks.reducer';
import * as fromTracks from './shared/redux/reducers/tracks.reducer';
import * as fromCommerces from './shared/redux/reducers/commerce.reducer';
import * as fromMessages from './shared/redux/reducers/messages.reducer';


export interface AppState {
  ui: fromUI.UIState;
  auth: fromAuth.AuthState;
  sales: fromSales.SaleState;
  jukeboxData: fromJukeboxData.JukeboxDataState;
  jukeboxConfiguration: fromJukeboxConfiguration.JukeboxConfigurationState;
  jukeboxRealtime: fromJukeboxRealtime.JukeboxRealtimeState;
  queuedTracks: fromQueuedTracks.QueuedTracksState;
  tracks: fromTracks.TracksState;
  commerces: fromCommerces.CommerceState;
  messages: fromMessages.MessageState;
}

export const appReducers: ActionReducerMap<AppState> = {
  ui: fromUI.uiReducer,
  auth: fromAuth.authReducer,
  sales: fromSales.salesReducer,
  jukeboxData: fromJukeboxData.jukeboxDataReducer,
  jukeboxConfiguration: fromJukeboxConfiguration.jukeboxConfigurationReducer,
  jukeboxRealtime: fromJukeboxRealtime.jukeboxRealtimeReducer,
  queuedTracks: fromQueuedTracks.queuedTracksReducer,
  tracks: fromTracks.tracksReducer,
  commerces: fromCommerces.commerceReducer,
  messages: fromMessages.messagesReducer
}
