


<div id="messages-card-container" class="">

    <!-- Messages container -->
    <div id="messages-card" class="mdl-card mdl-shadow--2dp mdl-cell mdl-cell--12-col mdl-cell--6-col-tablet mdl-cell--6-col-desktop">
      <div class="mdl-card__supporting-text mdl-color-text--grey-600">
  
        <div id="messages">
          <span id="message-filler"></span>
          <div class="message-container" *ngFor="let message of messages">
            <div class="spacing">
              <div class="pic" [style.background-image]="'url(' + message.photoUrl + ')'"></div>
            </div>
            <div class="message">
              <span *ngIf="message.text">{{ message.text }}</span>
              <!-- <span *ngIf="message.text" [style]="message.sentiment | stylize">{{ message.text }}</span>
              <img *ngIf="message.imageUrl" [src]="message.imageUrl + (message.moderated ? '&amp;moderated=1' : '')"> -->
            </div>
            <div class="name">{{ message.displayName }}</div>
          </div>
        </div>
  
        <div *ngIf="topics"><em>Recent topics: {{ topics }}</em></div>
  
        <form id="message-form" (submit)="saveMessage($event, box)">
          <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
            <input #box class="mdl-textfield__input" type="text" id="message" autocomplete="off"
              (keyup)="update(box.value)" (blur)="update(box.value)"
              placeholder="Message...">
          </div>
          <button id="submit" [disabled]="!value" type="submit" class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect">
            Send
          </button>
        </form>
        <!-- <form id="image-form" action="#">
          <input id="mediaCapture" type="file" accept="image/*,capture=camera" (change)="saveImageMessage($event)">
          <button id="submitImage" title="Add an image" (click)="onImageClick($event)"
            class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-color--amber-400 mdl-color-text--white">
            <i class="material-icons">image</i>
          </button>
        </form> -->
      </div>
    </div>
    
  </div>
  