import { Component, OnInit } from '@angular/core';
import { CommerceService } from '@app/shared/services/commerce.service';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducers';
import { User } from 'src/app/shared/models/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SubdomainService } from 'src/app/shared/services/subdomain.service';

import { Message } from '../../../shared/models/message.model';
import { MessageService } from '../../../shared/services/message.service';


const CHAT_GENERAL = 'general';


@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.css']
})
export class ChatListComponent implements OnInit {

  // user: Observable<firebase.User>;
  user: User;
  currentUser: firebase.User;
  chatGroupId: string;

  // messages: Observable<any[]>;
  messages: Message[] = [];
  topics = '';
  value = '';

  isLoading: boolean = false;

  uiSubscription: Subscription = new Subscription();
  authSubscription: Subscription = new Subscription();
  messagesSubscription: Subscription = new Subscription();

  constructor( private alog: NGXLogger,
               private store: Store<AppState>,
               private authService: AuthService,
               private messageService: MessageService,
               private commerceService: CommerceService,
               private subdomainService: SubdomainService ) { }


  ngOnInit(): void {

    this.uiSubscription = this.store.select('ui')
      .subscribe( ui => {
        this.isLoading = ui.loadingItems.length > 0;
    });

    this.authSubscription = this.store.select('auth')
      .pipe(filter( (authState) => authState.user != null))
      .subscribe( authState => {
        this.user = authState.user;

        // this.messageService.cancellSubscriptions();
        // this.messageService.observeMessages(this.commerceService.commerceId, this.chatGroupId); // this.user.uid
        // this.messageService.saveMessagingDeviceToken(this.commerceService.commerceId, this.chatGroupId); //this.user.uid
    });

    this.messagesSubscription = this.store.select('messages')
      .subscribe( messagesState => {
        this.messages = messagesState.messages;
        this.alog.debug('Booking > ngOnInit - messagesState: ', messagesState.messages);

        // Make sure new message scroll into view
        setTimeout(() => {
          const messageList = document.getElementById('messages');
          messageList.scrollTop = messageList.scrollHeight;
          document.getElementById('message').focus();
        }, 500);
    });

  }

}
