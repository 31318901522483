import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { debounceTime, map, take } from 'rxjs/operators';

import { Commerce, MenuImage } from '../models/commerce.model';
import { AdImage, JukeboxConfig, JukeboxData } from '../models/jukebox.model';
import { Message } from '../models/message.model';
import { PartyData } from '../models/party-data.model';
import { QueuedTrack } from '../models/queued-track.model';
import { Sale } from '../models/sale.model';
import { Subdomain } from '../models/subdomain.model';
import { User } from '../models/user.model';


@Injectable({
  providedIn: 'root'
})
export class NgfireHelperService {
              
  readonly PATH_SUBDOMAINS: string = "subdomains";
  readonly PATH_USERS: string = "users";
  readonly PATH_PROVIDER_DATA: string = "provider_data";
  readonly PATH_JUKEBOXES: string = "jukeboxes";
  readonly PATH_PLAYLISTS: string = "playlists";
  readonly PATH_PLAYLISTS_PLAYLIST: string = "playlist";
  readonly PATH_PLAYLISTS_NAME: string = "name";
  readonly PATH_PARTIES: string = "parties";
  readonly PATH_PARTY_DATA: string = "party_data";
  readonly PATH_PARTIES_SALES: string = "sales";
  readonly PATH_PARTIES_QUEUED_TRACKS: string = "queued_tracks";
  readonly PATH_PARTIES_PROMO_CODES: string = "promo_codes";
  readonly PATH_PARTIES_SONG_REQUESTS: string = "song_requests";

  readonly PATH_PLAYLIST: string = "playlist";
  readonly PATH_SONGS: string = "songs";
  readonly PATH_USER_JUKEBOXES: string = "user_jukeboxes";
  readonly PATH_JUKEBOX_CONFIGURATION: string = "jukebox_configuration";
  readonly PATH_IMGS_URL: string = "imgs_url"; // imgsUrl
  readonly PATH_IMGS_URL_POPUPS: string = "popups"; // imgsUrl
  readonly PATH_JUKEBOX_REALTIME: string = "jukebox_realtime";
  readonly PATH_JUKEBOX_DATA: string = "jukebox_data";
  readonly PATH_COMMERCES: string = "commerces";
  readonly PATH_COMMERCE_MESSAGES: string = "commerce_messages";
  readonly PATH_COMMERCE_EVENTS: string = "commerce_events";
  readonly PATH_COMMERCE_FEEDBACK_MESSAGES: string = "feedback_messages";
  readonly PATH_MESSAGES: string = "messages";
  readonly PATH_FCM_TOKENS: string = "fcm_tokens";
  readonly PATH_FEATURES: string = "features";
  readonly PATH_MENU: string = "menu";
  readonly PATH_DEFAULT: string = "default";
  readonly PATH_MENU_IMAGES: string = "menu_images";


  constructor( private alog: NGXLogger,
               private db: AngularFireDatabase ) { }


  /* *****************************/
  /* **** ADMIN           ********/
  /* *****************************/

  getPlaylistLoaded(jukeboxesId: string) {
    const path = this.PATH_PLAYLISTS + '/' + jukeboxesId + '/' + this.PATH_PLAYLISTS_PLAYLIST + '/' + this.PATH_PLAYLISTS_NAME;
    return this.db.object(path).valueChanges();
  }

  getSongRequests(partiesId: string) {
    const path = this.PATH_PARTIES + '/' + partiesId + '/' + this.PATH_PARTIES_SONG_REQUESTS;
    return this.db.list(path, ref => ref.limitToLast(20).orderByKey()).valueChanges();
  }

  updatePlaylist(playlistId:string, payload:any): Promise<void> {    
    const path = this.PATH_PLAYLISTS + '/' + playlistId;
    return this.db.list(path).set(this.PATH_PLAYLIST, payload);
  }

  updatePlaylistData(playlistId:string, playlist: any): Promise<void> {
    const path = this.PATH_PLAYLISTS + '/' + playlistId + '/' + this.PATH_PLAYLIST;
    return this.db.object(path).update(playlist);
  }

  updatePlaylistSongs(playlistId:string, songs: any): Promise<void> {
    const path = this.PATH_PLAYLISTS + '/' + playlistId + '/' + this.PATH_PLAYLIST + '/' + this.PATH_SONGS;
    return this.db.object(path).set(songs);
  }

  getTracksAvailableToPlay(playlistsId: string) {
    const path = this.PATH_PLAYLISTS + '/' + playlistsId + '/' + this.PATH_PLAYLIST + '/' + this.PATH_SONGS;
    //return this.db.list(path, ref => ref.orderByChild('isAvailableToPlay').equalTo(true)).valueChanges();
    return this.db.list(path, ref => ref.limitToFirst(500)).valueChanges();//.stateChanges();
  }

  getPlaylistTracks(playlistId: string) {
    const path = this.PATH_PLAYLISTS + '/' + playlistId + '/' + this.PATH_PLAYLIST + '/' + this.PATH_SONGS;
    //return this.db.list(path, ref => ref.orderByChild('isAvailableToPlay').equalTo(true)).valueChanges();
    return this.db.list(path, ref => ref.limitToFirst(500)).valueChanges();//.stateChanges();
  }

  getSongs(partiesId:string) {
    const path = this.PATH_PLAYLISTS + '/' + partiesId + '/' + this.PATH_PLAYLIST + '/' + this.PATH_SONGS;
    return this.db.list(path).valueChanges();
  }

  getJukebox(jukebox_uuid: string) {
    const path = this.PATH_JUKEBOXES + '/' + jukebox_uuid;
    return this.db.object(path).valueChanges();
  }

  subscribeJukeboxData(jukeboxId: string) {
    const path = this.PATH_JUKEBOXES + '/' + jukeboxId + '/' + this.PATH_JUKEBOX_DATA;
    return this.db.object(path).valueChanges();
  }

  subscribeJukeboxConfiguration(jukeboxId: string) {
    const path = this.PATH_JUKEBOXES + '/' + jukeboxId + '/' + this.PATH_JUKEBOX_CONFIGURATION;
    return this.db.object(path).valueChanges();
  }

  subscribeJukeboxRealtime(jukeboxId: string) {
    const path = this.PATH_JUKEBOXES + '/' + jukeboxId + '/' + this.PATH_JUKEBOX_REALTIME;
    return this.db.object(path).valueChanges();
  }

  removeTrack(jukebox_uuid:string, songKey: string): Promise<void> {
    const pathToObject = this.PATH_PLAYLISTS + '/' + jukebox_uuid + '/' + this.PATH_PLAYLIST + '/' + this.PATH_SONGS + '/' + songKey;
    return this.db.object(pathToObject).remove();
  }

  updateJukeboxConfig(jukeboxConfig: JukeboxConfig) {
    const path = this.PATH_JUKEBOXES + '/' + jukeboxConfig.id + '/' + this.PATH_JUKEBOX_CONFIGURATION; // Remove ": uid.replace(/['"]+/g, '')
    return this.db.object(path).update(jukeboxConfig);
  }

  pushImagePopup(jukeboxConfig: JukeboxConfig, adImage: AdImage) {
    const path = this.PATH_JUKEBOXES + '/' + jukeboxConfig.id + '/' + this.PATH_JUKEBOX_CONFIGURATION + '/' + this.PATH_IMGS_URL + '/' + this.PATH_IMGS_URL_POPUPS;
    adImage.id = this.db.createPushId();
    return this.db.list(path).set(adImage.id, adImage);
  }

  updatePopupImage(jukeboxId: string, adImage: AdImage) {
    const path = this.PATH_JUKEBOXES + '/' + jukeboxId + '/' + this.PATH_JUKEBOX_CONFIGURATION + '/' + this.PATH_IMGS_URL + '/' + this.PATH_IMGS_URL_POPUPS + '/' + adImage.id;
    return this.db.object(path).update(adImage);
  }

  deletePopupImage(jukeboxId: string, adImageId: string): Promise<void> {
    const path = this.PATH_JUKEBOXES + '/' + jukeboxId + '/' + this.PATH_JUKEBOX_CONFIGURATION + '/' + this.PATH_IMGS_URL + '/' + this.PATH_IMGS_URL_POPUPS + '/' + adImageId;
    return this.db.object(path).remove();
  }

  fetchSales(partyId: string, dateStart: string, dateEnd: string): Promise<Sale[]> {
    const path = this.PATH_PARTIES + '/' + partyId + '/' + this.PATH_PARTIES_SALES;
    //return this.db.list(path, ref => ref.limitToLast(600).orderByKey().limitToLast(20).startAt(dateStart)).valueChanges();
    return this.db.list<Sale>(path, ref => ref.orderByChild('date_transaction')).valueChanges().pipe(take(1)).toPromise();
  }

  getSalesData(partyId: string, dateStart: string, dateEnd: string): Sale[] | PromiseLike<Sale[]> {
    const path = `${this.PATH_PARTIES}/${partyId}/${this.PATH_PARTIES_SALES}`
    return this.db.list(path, ref => ref.orderByChild('date_transaction').startAt(dateStart).endAt(dateEnd)).valueChanges().pipe(take(1)).toPromise() as Promise<Sale[]>;    
  }


  /* *****************************/
  /* **** TRACK LIST      ********/
  /* *****************************/

  pushSongRequests(partiesId: string, requestedSong: string): Promise<any> {
    const path = this.PATH_PARTIES + '/' + partiesId + '/' + this.PATH_PARTIES_SONG_REQUESTS;
    return this.db.list(path).push(requestedSong);
  }

  subscribeSelectedTrack(playlistId: string, trackId: string) {
    const path = this.PATH_PLAYLISTS + '/' + playlistId + '/' + this.PATH_PLAYLIST + '/' + this.PATH_SONGS + '/' + trackId;
    return this.db.object(path).valueChanges();
  }


  /* *****************************/
  /* **** QUEUED TRACKS   ********/
  /* *****************************/

  getQueuedTracks(partiesId: string) {
    const path = this.PATH_PARTIES + '/' + partiesId + '/' + this.PATH_PARTIES_QUEUED_TRACKS;
    return this.db.list(path).valueChanges();
  }

  subscribeQueuedTracks(partiesId: string) {
    const path = this.PATH_PARTIES + '/' + partiesId + '/' + this.PATH_PARTIES_QUEUED_TRACKS;
    return this.db.list(path).valueChanges();
  }


  /* *****************************/
  /* **** CART TRACKS     ********/
  /* *****************************/

  pushQueuedTrack(partiesId: string, songQueued: QueuedTrack) {
    const path = this.PATH_PARTIES + '/' + partiesId + '/' + this.PATH_PARTIES_QUEUED_TRACKS;
    songQueued.id = this.db.createPushId();
    const promise = this.db.list(path).set(songQueued.id, songQueued);
    return promise;
  }


  /* *****************************/
  /* ****    SALES        ********/
  /* *****************************/

  pushSale(partiesId: string, sale: Sale) {
    const path = this.PATH_PARTIES + '/' + partiesId + '/' + this.PATH_PARTIES_SALES;
    sale.id = this.db.createPushId();
    const promise = this.db.list(path).set(sale.id, sale);
    return promise;
  }

  getSalesList(partiesId: string, startAtDateStr: string) {
    const path = this.PATH_PARTIES + '/' + partiesId + '/' + this.PATH_PARTIES_SALES;
    //return this.db.list(path, ref => ref.limitToLast(600).orderByKey().limitToLast(20)).valueChanges();
    return this.db.list(path, ref => ref.orderByChild('date_transaction')
                                        .startAt(startAtDateStr))
                                        .valueChanges();
  }

  updateSale(sale: Sale, partiesId: string): Promise<void> {
    const path = this.PATH_PARTIES + '/' + partiesId + '/' + this.PATH_PARTIES_SALES + '/' + sale.id;
    return this.db.object(path).update(sale);
  }


  /* *****************************/
  /* ****  PROMO CODES  **********/
  /* *****************************/

  getPromoCodesList(partiesId: string) {
    const path = this.PATH_PARTIES + '/' + partiesId + '/' + this.PATH_PARTIES_PROMO_CODES;
    return this.db.list(path).valueChanges();
  }

  removePromoCode(partiesId: string, promoCodeId: string): Promise<void> {
    const path = this.PATH_PARTIES + '/' + partiesId + '/' + this.PATH_PARTIES_PROMO_CODES + '/';
    return this.db.list(path + promoCodeId).remove();
  }  

  setPromoCodes(partiesId: string, promoCodes: any): Promise<void> {
    const path = this.PATH_PARTIES + '/' + partiesId + '/';
    return this.db.list(path).set(this.PATH_PARTIES_PROMO_CODES, promoCodes);
  }

  pushPromoCodes(partiesId: string, promoCodes: any): Promise<any> {
    const path = this.PATH_PARTIES + '/' + partiesId + '/' + this.PATH_PARTIES_PROMO_CODES;
    return this.db.list(path).push(promoCodes);
  }

  validatePromoCode(partyId:string, promoCode: string) {
    return this.db.object(this.PATH_PARTIES + '/' + partyId + '/' + this.PATH_PARTIES_PROMO_CODES + '/' + promoCode)
      .valueChanges()
      .pipe(
        debounceTime(500),
        take(1),
        map((promoCodeExists: any) => {
          if (!promoCodeExists) {                      
            return {
              promoCodeExists: false
            }
          }
          return null;
        })
      );
  }

  getPromoCode(partyId: string, promoCodeId: string) {
    const path = this.PATH_PARTIES + '/' + partyId + '/' + this.PATH_PARTIES_PROMO_CODES + '/' + promoCodeId
    return this.db.object(path).valueChanges();
  }

  /* *****************************/
  /* **** JUKEBOXES       ********/
  /* *****************************/

  getJukeboxData(jukeboxId: string): Promise<JukeboxData> {
    const path = this.PATH_JUKEBOXES + '/' + jukeboxId + '/' + this.PATH_JUKEBOX_DATA;
    this.alog.debug('ngfire-helper.service > getJukeboxData - path:', path);
    return this.db.object(path).valueChanges().pipe(take(1)).toPromise() as Promise<JukeboxData>;
  }


  /* *****************************/
  /* **** USER            ********/
  /* *****************************/

  updateUser(userId:string, user: User): Promise<void> {
    const path = this.PATH_USERS + '/' + userId;
    return this.db.object(path).update(user);
  }

  setInitialUserData(userId:string, user: User): Promise<void> {
    const path = this.PATH_USERS + '/' + userId;
    return this.db.object(path).set(user);
  }

  getUser(userId: string): Promise<User> {
    const path = this.PATH_USERS + '/' + userId;
    return this.db.object(path).valueChanges().pipe(take(1)).toPromise() as Promise<User>;
  }

  /* *****************************/
  /* ****  PARTY DATA   **********/
  /* *****************************/

  observePartyData(partiesId: string): Observable<any> {
    const path = this.PATH_PARTIES + '/' + partiesId + '/' + this.PATH_PARTY_DATA;
    return this.db.object(path).valueChanges();
  }

  updatePartyData(partyData: PartyData) {
    const path = this.PATH_PARTIES + '/' + partyData.id + '/' + this.PATH_PARTY_DATA;
    return this.db.object(path).update(partyData);
  }


  /* *****************************/
  /* ****  SUBDOMAIN    **********/
  /* *****************************/

  setSubdomainRequested(subdomain: Subdomain): Promise<void> {
    const path = this.PATH_SUBDOMAINS + '/' + subdomain.id;
    return this.db.object(path).set(subdomain);
  }

  getSubdomain(subdomainId: string): Observable<any> {
    const path = this.PATH_SUBDOMAINS + '/' + subdomainId;
    return this.db.object(path).valueChanges();
  }

  checkSubdomainAvailability(subdomain:string) {
    return this.db.object(this.PATH_SUBDOMAINS + '/' + subdomain)
      .valueChanges()
      .pipe(
        debounceTime(500),
        take(1),
        map((subdomainExists: any) => {
          if (subdomainExists) {                      
            return {
              subdomainExists: false
            }
          }
          return null;
        })
      );
  }

  /* *****************************/
  /* ****  COMMERCES    **********/
  /* *****************************/

  observeCommerces(): Observable<any> {
    const path = this.PATH_COMMERCES;
    //return this.db.list(path, ref => ref.limitToLast(600).orderByKey()).valueChanges();
    return this.db.list(path, ref => ref.limitToLast(500).orderByKey()).valueChanges();
  }

  observeCommerce(commerceId: string): Observable<any> {
    this.alog.debug('NgfireHelperService > observeCommerce');
    const path = this.PATH_COMMERCES + '/' + commerceId;
    return this.db.object(path).valueChanges();
  }

  pushCommerceMenuImage(commerce: Commerce, menuImage: MenuImage) {
    const path = this.PATH_COMMERCES + '/' + commerce.id + '/' + this.PATH_FEATURES + '/' + this.PATH_MENU + '/' + this.PATH_DEFAULT + '/' + this.PATH_MENU_IMAGES;
    menuImage.id = this.db.createPushId();
    return this.db.list(path).set(menuImage.id, menuImage);
  }

  deleteCommerceMenuImage(commerceId: string, menuImageId: string): Promise<void> {
    const path = this.PATH_COMMERCES + '/' + commerceId + '/' + this.PATH_FEATURES + '/' + this.PATH_MENU + '/' + this.PATH_DEFAULT + '/' + this.PATH_MENU_IMAGES + '/' + menuImageId;
    return this.db.object(path).remove();
  }

  updateCommerceMenuImage(commerceId: string, menuImage: MenuImage) {
    const path = this.PATH_COMMERCES + '/' + commerceId + '/' + this.PATH_FEATURES + '/' + this.PATH_MENU + '/' + this.PATH_DEFAULT + '/' + this.PATH_MENU_IMAGES + '/' + menuImage.id;
    return this.db.object(path).update(menuImage);
  }

  /* *****************************/
  /* ****  MESSAGES     **********/
  /* *****************************/

  observeMessages(commerceId: string, chatGroupId: string) {
    const path = this.PATH_COMMERCE_MESSAGES + '/' + commerceId + '/' + chatGroupId + '/' + this.PATH_MESSAGES;
    return this.db.list(path, ref => ref.limitToLast(20).orderByKey()).valueChanges();
  }

  pushMessage(commerceId: string, userId: string, message: Message): Promise<void> {
    const path = this.PATH_COMMERCE_MESSAGES + '/' + commerceId + '/' + userId + '/' + this.PATH_MESSAGES;
    message.id = this.db.createPushId();
    return this.db.list(path).set(message.id, message);
  }

  addFcmToken(currentToken: string, commerceId: string, chatId: string, userId: string) {
    this.alog.debug('NgfireHelperService > addFcmToken - currentToken, commerceId, userId', currentToken, commerceId, userId);
    const path = this.PATH_COMMERCE_MESSAGES + '/' + commerceId + '/' + chatId + '/' + this.PATH_FCM_TOKENS;
    return this.db.list(path).set(currentToken, userId);
  }

  /**
   * Insert message on collection commerce_events / commerceId / feedback_messages
   * @param feedbackMessage all composed data to save on database
   */
  pushFeedbackMessage(feedbackMessage: { id: string; commerceId: string; message: any; user: User; datetime: string; }): Promise<void> {
    const path = `${this.PATH_COMMERCE_EVENTS}/${feedbackMessage.commerceId}/${this.PATH_COMMERCE_FEEDBACK_MESSAGES}`;
    feedbackMessage.id = this.db.createPushId();
    return this.db.list(path).set(feedbackMessage.id, feedbackMessage);
  }

}
