import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.reducers';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Jukebox, JukeboxConfig } from '../../../shared/models/jukebox.model';
import { JoyrideService } from 'ngx-joyride';
import { User } from '../../../shared/models/user.model';
import { ToggleSidebarAction } from 'src/app/shared/redux/actions/ui.actions';
import { AdminService } from '../../../shared/services/admin.service';

@Component({
  selector: 'app-sidebar-admin',
  templateUrl: './sidebar-admin.component.html',
  styleUrls: ['./sidebar-admin.component.css']
})
export class SidebarAdminComponent implements OnInit, OnDestroy {
    
  user: User;
  jukeboxConfiguration: JukeboxConfig;

  displayName: string = '';
  showSidebar: boolean = true;
  roleAccessRestrictions: string[] = [];
  
  uiSubscription: Subscription = new Subscription();
  authSubscription: Subscription = new Subscription();
  jukeboxConfigurationSubscription: Subscription = new Subscription();

  constructor( private store: Store<AppState>,
               private adminService: AdminService,
               private readonly joyrideService: JoyrideService ) { }

  ngOnInit() {

    this.uiSubscription = this.store.select('ui')
        .subscribe( ui => {
          this.showSidebar = ui.showSidebar;
    });

    this.authSubscription =  this.store.select('auth')
        .pipe(
          filter( auth => auth.user != null)
        )
        .subscribe( auth => { 
          this.user = JSON.parse(JSON.stringify(auth.user));

          this.displayName = this.user.displayName;
          if (auth.user.roleAccessRestrictions) {
            this.roleAccessRestrictions = auth.user.roleAccessRestrictions.split(';');
          }

          if ( !this.user.isAdminTourCompleted ) {
            this.store.dispatch( new ToggleSidebarAction() );
            if ( this.user.jukeboxes_parties ) {
              this.onClickTutorial();
            }
          }
    });

    this.jukeboxConfigurationSubscription =  this.store.select('jukeboxConfiguration')
        .subscribe( jukeboxState => this.jukeboxConfiguration = jukeboxState.jukeboxConfiguration );
  }

  onClickTutorial() {

    var steps: string[] = [];

    if ( Object.keys(this.user.jukeboxes_parties.length > 1) ) {
      steps = ['step-1@admin', 'step-2', 'step-3@admin', 
                'step-4@admin/statistics', 'step-5@admin/statistics', 'step-6@admin/statistics', 
                'step-7@admin/jukebox-music', 'step-8@admin/jukebox-music', 'step-9@admin/jukebox-music', 'step-10@admin/jukebox-music',
                'step-11@admin/promo-codes', 'step-12@admin/promo-codes', 'step-13@admin/promo-codes',
                'step-14@admin/promo-images', 'step-15@admin/promo-images', 
                'step-16@admin/jukebox-config', 'step-17@admin/jukebox-config',
                'step-18@admin/monitor',
                'step-19@admin'
              ];
    } else {
      steps = ['step-1@admin', 'step-3@admin', 
                'step-4@admin/statistics', 'step-5@admin/statistics', 'step-6@admin/statistics', 
                'step-7@admin/jukebox-music', 'step-8@admin/jukebox-music', 'step-9@admin/jukebox-music', 'step-10@admin/jukebox-music',
                'step-11@admin/promo-codes', 'step-12@admin/promo-codes', 'step-13@admin/promo-codes',
                'step-14@admin/promo-images', 'step-15@admin/promo-images', 
                'step-16@admin/jukebox-config', 'step-17@admin/jukebox-config',
                'step-18@admin/monitor',
                'step-19@admin'
              ];
    }
    
    this.joyrideService.startTour({ 
      steps: steps,
      waitingTime: 200,
      logsEnabled: true,
      themeColor: '#6200EE',
      stepDefaultPosition: 'top'
    }).subscribe(
      (step) => { 
        /*Do something*/
      },
      (error) => { 
        /*handle error*/
      },
      () => { 
        /*Tour is finished here, do something*/
        localStorage.setItem('tour_finished', 'true');
        this.user.isAdminTourCompleted = true;
        this.adminService.updateUser(this.user.uid, this.user);
      }
    );
  }

  

  /*********************
   *** LIFECYCLE   ****
   *********************/

  ngOnDestroy() {
    this.uiSubscription.unsubscribe();
    this.authSubscription.unsubscribe();
    this.jukeboxConfigurationSubscription.unsubscribe();
  }

}
