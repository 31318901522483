import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivationEnd, ActivatedRouteSnapshot } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppState } from 'src/app/app.reducers';
import { User } from 'src/app/shared/models/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Commerce } from 'src/app/shared/models/commerce.model';
import { JukeboxService } from 'src/app/shared/services/jukebox.service';
import { SubdomainService } from 'src/app/shared/services/subdomain.service';

const PROFILE_PLACEHOLDER_IMAGE_URL = '/assets/images/profile_placeholder.png';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy {

  user: User;
  commerce: Commerce;
  subdomain: string;
  jukeboxId: string;
  trackId: string; 
  pageActive: string;

  isLoading: boolean = false;
  isMenuCollapsed: boolean = true;
  profilePicStyles: {};

  private uiSubscription: Subscription = new Subscription();
  private authSubscription: Subscription = new Subscription();
  private routeSubscription$: Subscription = new Subscription();
  private commerceReduxSubscription$: Subscription = new Subscription();
  

  constructor( private log: NGXLogger,
               private router: Router,
               private store: Store<AppState>,
               private ngbModal: NgbModal,
               private authService: AuthService,
               private jukeboxService: JukeboxService,
               private subdomainService: SubdomainService ) {

    this.routeSubscription$ = this.getRouteParams()
      .subscribe( ( eventSnapshot: ActivatedRouteSnapshot ) => {
          this.log.debug('Navbar > constructor - eventSnapshot:', eventSnapshot);
          this.jukeboxId = eventSnapshot.params['jukeboxId'];
          this.subdomain = eventSnapshot.params['subdomain'];
          this.trackId = eventSnapshot.params['trackId'];
          this.pageActive = eventSnapshot.routeConfig.path || '';
      });
  }


  ngOnInit() {

    this.uiSubscription = this.store.select('ui')
      .subscribe( uiState => {
        this.isLoading = uiState.loadingItems.length > 0;
        this.log.debug('Navbar > ngOnInit uiState.loadingItems:', uiState.loadingItems);
    });

    this.authSubscription = this.store.select('auth')
      .subscribe( authState => {
        this.log.debug('Navbar > ngOnInit authState: ', authState);
        this.user = authState.user;
    });

    this.subscribeReduxCommerce();
  }

  /**
   * Angular component lifecycle end
   */
  ngOnDestroy() {    
    this.uiSubscription.unsubscribe();
    this.authSubscription.unsubscribe();
    this.routeSubscription$.unsubscribe();
    this.commerceReduxSubscription$.unsubscribe();
    this.jukeboxService.cancellSubscriptions();
  }

  /**
   * 
   */
  subscribeReduxCommerce() {
    this.commerceReduxSubscription$ = this.store.select('commerces')
      .subscribe( commerceState => {
        this.log.debug('Navbar > subscribeCommerce commerceState.commerce:', commerceState.commerce);
        this.commerce = commerceState.commerce;        
    });
  }
  
  /**
   * 
   * @param content 
   */
  onClickOpenModalMenu(content) {
    this.log.debug('Navbar > onClickOpenModalMenu - content: ', content);
    this.ngbModal.open(content, {ariaLabelledBy: 'modal-basic-title'})
      .result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  signInWithGoogle() {
    this.authService.signInWithGoogle();
    this.isMenuCollapsed = true;
  }

  signInWithTwitter() {
    this.authService.signInWithTwitter();
    this.isMenuCollapsed = true;
  }

  signInWithFacebook() {
    this.authService.signInWithFacebook();
    this.isMenuCollapsed = true;
  }

  /**
   * Close any opened session: Facebook, Google, Paviado
   */
  logout() {
    this.authService.signOut('users');
    this.isMenuCollapsed = true;
  }

  /**
   * User click back navigation icon
   */
  onClickNavigationBack() {
    this.log.debug('NavbarComponent > onClickNavigationBack');

    if (this.pageActive === 'menu') {
      this.router.navigate(['', this.subdomain]);

    } else if (this.pageActive === 'thanks') {
      this.router.navigate(['', this.subdomainService.subdomain.id, 'jukebox', this.jukeboxService.jukeboxIdSubscribed]);

    } else if (this.pageActive === 'feedback') {
      this.router.navigate(['', this.subdomain]);

    } else if (this.subdomain && this.jukeboxId && this.trackId) {
      this.router.navigate(['', this.subdomainService.subdomain.id, 'jukebox', this.jukeboxService.jukeboxIdSubscribed]);

    } else if (this.subdomain && this.jukeboxId) {
      this.router.navigate(['', this.subdomain]);   

    } else {
      this.router.navigate(['']);
    }      
  }


  /**
   * @returns route ActivatedEnd event snapshot
   */
  getRouteParams(): Observable<any> {
    return this.router.events
      .pipe(
        filter( event => event instanceof ActivationEnd ),
        filter( (event: ActivationEnd) => event.snapshot.firstChild === null  ),
        map( (event: ActivationEnd) => event.snapshot ),
      );
  }


}
