import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducers';
import { UIService } from 'src/app/shared/services/ui.service';
import { NgfireHelperService } from 'src/app/shared/services/ngfire-helper.service';
import { take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Subdomain } from '../models/subdomain.model';

@Injectable({
  providedIn: 'root'
})
export class SubdomainService {

  subdomain: Subdomain;

  constructor( private alog: NGXLogger,
               private store: Store<AppState>,
               private uiService: UIService,
               private ngFireService: NgfireHelperService ) { }


  getSubdomain(subdomainId: string): Promise<Subdomain> {

    this.alog.debug('SubdomainService > getSubdomain: ', subdomainId);

    // return this.ngFireService.getSubdomain(subdomainId).pipe(take(1)).toPromise();

    return new Promise( (resolve, reject) => {

      this.ngFireService.getSubdomain(subdomainId)
        .pipe(take(1))
        .subscribe(
          (subdomain: Subdomain) => {
            this.alog.debug('SubdomainService > getSubdomain - subdomain:', subdomain);
            this.subdomain = subdomain;
            // this.store.dispatch( new LoadingChangeAction(false) ); 
            resolve(subdomain);
          },
          (err) => {
            this.alog.debug('SubdomainService > getSubdomain - err', err);
            // this.store.dispatch( new LoadingChangeAction(false) );
            reject(err);
          }
        );
    });
  }  


}
