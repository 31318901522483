import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommerceInfoComponent } from '@app/users/commerce-info/commerce-info.component';
import { CommerceListComponent } from '@app/users/commerce-list/commerce-list.component';
import { FeedbackComponent } from '@app/users/feedback/feedback.component';
import { FormBaseComponent } from '@app/users/jukebox/form-base/form-base.component';
import { SongListComponent } from '@app/users/jukebox/song-list/song-list.component';
import { ThanksComponent } from '@app/users/jukebox/thanks/thanks.component';
import { MenuComponent } from '@app/users/menu/menu.component';
import { PlacePickerComponent } from '@app/users/place-picker/place-picker.component';
import { JukeboxResolver } from '@app/users/shared/resolvers/jukebox-resolver.resolver';
import { JukeboxWithoutCommerceResolver } from '@app/users/shared/resolvers/jukebox-without-commerce-resolver.resolver';
import { SingleJukeboxResolver } from '@app/users/shared/resolvers/single-jukebox-resolver.resolver';
import { SubdomainResolver } from '@app/users/shared/resolvers/subdomain-resolver.resolver';

export const routes: Routes = [

  { path: '', component: CommerceListComponent, pathMatch: 'full' },

  { path: ':subdomain', 
    resolve: { subdomainResolver: SubdomainResolver },

    children: [
      { path: '', component: CommerceInfoComponent, pathMatch: 'full',
        resolve: { jukeboxWithoutCommerceResolver: JukeboxWithoutCommerceResolver },
      },
      { path: 'menu', component: MenuComponent },
      { path: 'feedback', component: FeedbackComponent },
      { path: 'jukebox', component: PlacePickerComponent,
        resolve: { singleJukeboxResolver: SingleJukeboxResolver },
      },
      { path: 'jukebox/:jukeboxId', 
        resolve: { jukeboxResolver: JukeboxResolver },
        children: [    
          { path: '', component: SongListComponent, pathMatch: 'full' },
          { path: 'play-track/:trackId', component: FormBaseComponent },
          { path: 'thanks', component: ThanksComponent },
        ]
      },
    ],
        
  }
  
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsersRoutingModule {}
