
<div class="container">

  <app-loading *ngIf="isLoading"></app-loading>

  <div *ngIf="!isLoading" class="">
    <br><br>
    <div class="row">
      <div class="col-12">
        <button (click)="onClickDownloadLeads()" class="btn btn-primary btn-block"><i class="fas fa-cloud-download-alt"></i>&nbsp;Descargar emails</button>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <table class="table table-striped">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Email</th>
            <th scope="col">Nombre</th>
            <th scope="col">Último acceso</th>
            <th scope="col">Dias en el local</th>
            <th scope="col">Total solicitudes</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let lead of leadsDownloadTable; index as i">
            <th scope="row">{{ i + 1 }}</th>
            <td>{{ lead.email }}</td>
            <td>{{ lead.name }}</td>
            <td>{{ lead.lastRequest | date }}</td>
            <td>{{ lead.daysUsingPaviado.size | number }}</td>
            <td>{{ lead.totalRequest | number }}</td>
          </tr>
          </tbody>
        </table>

        <div class="d-flex justify-content-between p-2">
          <ngb-pagination [collectionSize]="leadsDownload.length" [(page)]="page" [pageSize]="pageSize" (pageChange)="refreshLeads()">
          </ngb-pagination>
        
          <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshLeads()">
            <option [ngValue]="50">50 items per page</option>
            <option [ngValue]="100">100 items per page</option>
            <option [ngValue]="200">200 items per page</option>
          </select>
        </div>
      </div>
    </div>

  </div>

</div>
