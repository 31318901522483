import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Router } from '@angular/router';
import { Commerce } from '@app/shared/models/commerce.model';
import { CommerceService } from '@app/shared/services/commerce.service';
import { DynamicScriptLoaderService } from '@app/shared/services/dynamic-script-loader.service';
import { Store } from '@ngrx/store';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducers';

import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-commerce-list',
  templateUrl: './commerce-list.component.html',
  styleUrls: ['./commerce-list.component.css']
})
export class CommerceListComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('mapContainer') private elementMapContainerRef: ElementRef;
  @ViewChild(GoogleMap, { static: false }) googleMap: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow;

  isLoading: boolean = false;
  isMapShown: boolean = true;
  isFilterShown: boolean = false;
  isMapScriptLoaded: boolean = false;

  searchFilterCommerce: string = '';
  musicStyleFilterCommerce: string[] = [];

  mapHeight: number;

  commerces: Commerce[] = [];
  searchCommerceNameForm: FormGroup;


  zoom = 13;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    zoomControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: false,
    mapTypeId: "hybrid",
    mapTypeControl: false,
    backgroundColor: "#240838",
    streetViewControl: false,
    fullscreenControl: false
  };

  markers = [];
  markerCustom: any = '';
  
  markerCommerceName: string = '';
  markerCommerceSubdomain: string = '';

  dropdownList = [];
  musicStyleFilterSelectedItems = [];
  dropdownSettings: IDropdownSettings = {};

  
  uiSubscription: Subscription = new Subscription();
  commercesSubscription: Subscription = new Subscription();
  

  constructor( private alog: NGXLogger,
               private router: Router,
               private store: Store<AppState>,
               private commerceService: CommerceService,
               private dynamicScriptLoader: DynamicScriptLoaderService ) { }

  ngOnInit(): void {

    this.uiSubscription = this.store.select('ui')
      .subscribe( uiState => {
        this.isLoading = uiState.loadingItems.length > 0;
    });

    this.searchCommerceNameForm = new FormGroup({
      'searchFilterCommerce': new FormControl(null, [Validators.required])
    });

    this.isMapShown = localStorage.getItem('user_view_map') == 'true' ? true : false;
    this.initDropdownMusicSelector();
    this.observeCommerceFormChanges();
    this.commerceService.cancelCommerceSubscription();

    // Load google map Script
    const url = 'https://maps.googleapis.com/maps/api/js?key=' + environment.googleMapsKey;
    this.dynamicScriptLoader.load('googlemapsjs')
      .then( data => {
      // Script Loaded Successfully
      this.initMap();
      this.commerceService.observeCommerces();
      this.subscribeCommerces();
      this.isMapScriptLoaded = true;
    })
    .catch(error => { this.alog.error('CommerceListComponent > ngOnInit - error: ', error) });
    
  }

  initDropdownMusicSelector() {
    this.dropdownList = [
      { item_id: 1, item_text: 'Rock' },
      { item_id: 2, item_text: 'Pop' },
      { item_id: 3, item_text: 'Reggae' },
      { item_id: 4, item_text: 'Reggaeton' },
      { item_id: 4, item_text: 'Jazz' },
      { item_id: 5, item_text: 'Swing' },
      { item_id: 6, item_text: 'Soul' }
    ];
    this.musicStyleFilterSelectedItems = [
      { item_id: 1, item_text: 'Rock' },
      { item_id: 2, item_text: 'Pop' },
      { item_id: 3, item_text: 'Reggae' },
      { item_id: 4, item_text: 'Reggaeton' },
      { item_id: 4, item_text: 'Jazz' },
      { item_id: 5, item_text: 'Swing' },
      { item_id: 6, item_text: 'Soul' }
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar todos',
      unSelectAllText: 'Eliminar selección',
      itemsShowLimit: 7,
      allowSearchFilter: true
    };
  }

  ngAfterViewInit() {    
    this.setMapHeight(null);
  }

  observeCommerceFormChanges() {
    this.searchCommerceNameForm.valueChanges
      .pipe(debounceTime(500))
      .subscribe( value => {
        this.searchFilterCommerce = value.searchFilterCommerce;
      });
  }

  initMap() {
    // center in Valencia by default
    this.center = {
      lat: 39.470263,
      lng: -0.376234
    };

    // Center in user after accept geolocation
    // navigator.geolocation.getCurrentPosition(x => {
    //   this.center = {
    //     lat: x.coords.latitude,
    //     lng: x.coords.longitude
    //   };
      // this.markers.push({
      //   position: {
      //     lat: x.coords.latitude,
      //     lng: x.coords.longitude
      //   },
      //   label: {
      //     color: "blue",
      //     text: "Marker Label"
      //   },
      //   title: "Marker Title",
      //   info: "Marker info",
      //   options: {
      //     animation: google.maps.Animation.BOUNCE
      //   }
      // });
    // });    
  }

  subscribeCommerces() {
    this.commercesSubscription = this.store.select('commerces')
      .subscribe( commerceState => {
        this.alog.debug('CommerceList > subscribeCommerces this.commerces:', commerceState.commerces);
        this.commerces = commerceState.commerces;
        this.markers = [];

        this.commerces.forEach( iCommerce => {
          this.addMapMarker(iCommerce);
        });
    });
  }

  addMapMarker(iCommerce: Commerce) {
    if (iCommerce.place.site.location.coords) {
      // this.alog.debug('CommerceList > addMapMarker iCommerce.profile.attributes?.location.coords.latitude:', iCommerce.place.site.location.coords.latitude);

      this.markers.push({
        position: {
          lat: iCommerce.place.site.location.coords.latitude,
          lng: iCommerce.place.site.location.coords.longitude
        },
        profile: {
          displayName: iCommerce.place.profile.displayName
        },
        subdomain: iCommerce.subdomain,
        urlLogoThumbnail: iCommerce.place.profile.images.urlLogoThumbnail,
        options: {
          animation: google.maps.Animation.DROP,
          icon: 'https://firebasestorage.googleapis.com/v0/b/test-paviado.appspot.com/o/uploads%2Fic_launcher_maps_3.png?alt=media&token=86633b5c-3d69-4135-a330-4ddf1fe4a424'
        },
        ...iCommerce
      });
    }
  }

  onClickListView() {
    this.alog.debug('CommerceList > onClickListView: ');
    this.isMapShown = false;
    localStorage.setItem('user_view_map', 'false');
  }

  onClickMapView() {
    this.alog.debug('CommerceList > onClickMapView: ');
    this.isMapShown = true;
    localStorage.setItem('user_view_map', 'true');
  }

  setMapHeight(event: any) {
    const innerScreenHeight = window.innerHeight;
    
    // this.elementMapContainerRef.nativeElement.getBoundingClientRect().top == 147
    this.mapHeight = (innerScreenHeight - 128) - 10;// - 50;

    if (this.googleMap) {
      this.googleMap.height = this.mapHeight + 'px';
    }
    if (this.elementMapContainerRef) {
      this.elementMapContainerRef.nativeElement.style.height = this.mapHeight + 'px';      
    }
  }

  /**
   * Request song Form. Save on database request
   */
  onCommerceFilterFormSubmit() {

  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setMapHeight(null);
  }

  openInfo(marker: MapMarker, markerCustom: any) {
    this.markerCustom = markerCustom;
    this.info.open(marker);
  }

  onItemSelect(item: any) {
    this.alog.debug('item', item);
  }
  onSelectAll(items: any) {

  }
  
  ngOnDestroy() {
    this.uiSubscription.unsubscribe();
    this.commercesSubscription.unsubscribe();
  }

}
