import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  subdomain: string = '';
  imgUrlCompanyLogo: string = '';

  constructor(private http: HttpClient) { }

  /**
   * Reads and load data related to subdomain
   */
  initializeApp(): Promise<any> {
    return new Promise( (resolve, reject) => {
      
      const domain = window.location.hostname;
      if ( domain.indexOf('.') < 0 || domain.split('.')[0] === 'example' ) {

        // If you want to Emulate subdomains, uncomment this lines:
        // this.subdomain = 'mi';
        // this.subdomain = 'app';
      } else {
        // This redirects to correct site. Old paviado version uses subdomains but new one is a path
        this.subdomain = domain.split('.')[0];

        if ( this.subdomain === 'delorean'
          || this.subdomain === 'flash'
          || this.subdomain === 'fumiferro'
          || this.subdomain === 'laclave'
          || this.subdomain === 'piccadilly' ) {

          window.location.href = 'https://pavi.do/' + this.subdomain;
        }
      }

      resolve();

    });
  }


  getSubdomain(): string {
    return this.subdomain;
  }


}
