import { Injectable } from '@angular/core';
import { AppState } from '@app/app.reducers';
import LeadValidator from '@app/shared/helpers/lead-validator.helper';
import { LeadDownload } from '@app/shared/models/lead-download.model';
import { Sale } from '@app/shared/models/sale.model';
import { LOADING_ITEM_FETCH_SALES, LoadingAddAction, LoadingRemoveAction } from '@app/shared/redux/actions/ui.actions';
import { NgfireHelperService } from '@app/shared/services/ngfire-helper.service';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class SalesService {
  
  constructor(
    private log: NGXLogger,
    private store: Store<AppState>,
    private ngFireService: NgfireHelperService
  ) { }

  // , dateStart: string, dateEnd: string
  async fetchSales(partyId: string, dateStart: string, dateEnd: string): Promise<LeadDownload[]> {
    this.log.debug('SalesService > fetchSales - partyId:', partyId);

    this.store.dispatch(new LoadingAddAction(LOADING_ITEM_FETCH_SALES) );

    try {
      const sale: Sale[] = await this.ngFireService.fetchSales(partyId, dateStart, dateEnd);

      let leadsDownload: LeadDownload[] = [];
      sale.forEach(iSale => {
        if (iSale.lead?.email && LeadValidator.isRealEmail(iSale.lead?.email)) {
          const email = iSale.lead?.email.trim().toLowerCase();
          const leadIndex = leadsDownload.findIndex(iLead => iLead.email == email);
          if (leadIndex != -1) {
            leadsDownload[leadIndex] = {
              email: email,
              name: iSale.lead.name,
              daysUsingPaviado: leadsDownload[leadIndex].daysUsingPaviado.set(iSale.date_transaction.substring(0, 10), leadsDownload[leadIndex].daysUsingPaviado.get(iSale.date_transaction.substring(0, 10))),
              lastRequest: new Date(iSale.date_transaction),
              totalRequest: leadsDownload[leadIndex].totalRequest + 1,
            }
          } else {
            let map = new Map();
            map.set(iSale.date_transaction.substring(0, 10), 1);
            leadsDownload.push({
              email: email,
              name: iSale.lead.name,
              daysUsingPaviado: map,
              lastRequest: new Date(iSale.date_transaction),
              totalRequest: 1,
            });          
          }
        }
      });
      leadsDownload.reverse();
      return Promise.resolve(leadsDownload);
      
    } catch (error) {
      this.log.error('LeadsDataService > getDownloadLeadsURL - error:', error);
      return Promise.reject(error);

    } finally {
      this.store.dispatch(new LoadingRemoveAction(LOADING_ITEM_FETCH_SALES));
    }

  }
  

}
