import 'firebase/analytics';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { JukeboxConfig, JukeboxData } from '@app/shared/models/jukebox.model';
import { QueuedTrack } from '@app/shared/models/queued-track.model';
import { NgfireHelperService } from '@app/shared/services/ngfire-helper.service';
import { SubdomainService } from '@app/shared/services/subdomain.service';
import { UIService } from '@app/shared/services/ui.service';
import { Store } from '@ngrx/store';
import * as firebase from 'firebase/app';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducers';
import { Track } from 'src/app/shared/models/track.model';
import { User } from 'src/app/shared/models/user.model';
import { JukeboxService } from 'src/app/shared/services/jukebox.service';

@Component({
  selector: 'app-form-base',
  templateUrl: './form-base.component.html',
  styleUrls: ['./form-base.component.css']
})
export class FormBaseComponent implements OnInit, OnDestroy {
  
  isLoading: boolean;

  jukeboxData: JukeboxData;
  jukeboxConfiguration: JukeboxConfig;

  user: User;
  trackSelected: Track;
  analytics = firebase.analytics();

  uiSubscription: Subscription = new Subscription();
  authSubscription: Subscription = new Subscription();
  queuedTracksSubscription: Subscription = new Subscription();
  selectedTrackSubscription: Subscription = new Subscription();
  jukeboxRealtimeSubscription: Subscription = new Subscription();
  jukeboxConfigurationSubscription: Subscription = new Subscription();


  queuedTracks: QueuedTrack[] = [];
  playQueueSize: number = 0;
  isCurrentlyPlaying: boolean = false;
  tooManyRequestsByUser: boolean = false;
  isTrackRequestedByUser: boolean = false;


  constructor( private router: Router,
               private alog: NGXLogger,     
               private route: ActivatedRoute,
               private store: Store<AppState>,
               private uiService: UIService,
               public jukeboxService: JukeboxService,
               private subdomainService: SubdomainService,
               private ngFireService: NgfireHelperService ) { }


  ngOnInit() {
    
    this.uiSubscription = this.store.select('ui')
      .subscribe( uiState => {
        this.isLoading = uiState.loadingItems.length > 0;
    });

    // this.subscribeReduxJukebox();
    this.jukeboxData = this.jukeboxService.jukeboxDataSubscribed;
    this.subscribeReduxJukeboxConfiguration();
    this.subscribeReduxQueuedTracks();
    this.subscribeReduxAuth();
    this.subscribeReduxJukeboxRealtime();

    // Send a song feature: Check last request by user and limit
    this.tooManyRequestsByUser = this.jukeboxService.userHaveRequestedTracksRecently();
        
  }


  subscribeReduxAuth() {
    this.authSubscription = this.store.select('auth')
      .subscribe( authState => {
        this.alog.debug('FormBase > ngOnInit authState: ', authState);
        this.user = authState.user;
    });
  }

  subscribeReduxJukeboxConfiguration() {
    this.jukeboxConfigurationSubscription = this.store.select('jukeboxConfiguration')
      .pipe(
        filter( (jukeState) => jukeState.jukeboxConfiguration != null)
      )
      .subscribe( (jukeState) => {
        this.jukeboxConfiguration = jukeState.jukeboxConfiguration;
        this.observeSelectedTrackAndShow();
    });
  }

  // observeReduxJukeboxDataAndConfiguration() {    
  //   this.jukeboxDataAndConfigurationSubscription = 
  //     combineLatest(
  //       this.store.select('jukeboxData').pipe(filter( (jukeState) => jukeState.jukeboxData != null)),
  //       this.store.select('jukeboxConfiguration').pipe(filter( (jukeState) => jukeState.jukeboxConfiguration != null)))
  //       .subscribe( ([jukeboxStateData, jukeboxStateConfiguration]) => {
  //         this.jukeboxData = jukeboxStateData.jukeboxData;
  //         this.jukeboxConfiguration = jukeboxStateConfiguration.jukeboxConfiguration;
  //         this.observeSelectedTrackAndShow();
  //       });    
  // }
  
  observeSelectedTrackAndShow() {
    if (this.jukeboxConfiguration.isSongSearchOpen) {
      this.trackSelected = this.jukeboxService.searchOpenSelectedTrack;
      if (!this.trackSelected) {
        this.router.navigate(['', this.subdomainService.subdomain.id, 'jukebox', this.jukeboxService.jukeboxIdSubscribed]);
      }

    } else {
      this.route.params.subscribe(
        (params: Params) => {
          let trackId = params['trackId'];
          if ( trackId ) {
            this.subscribeSelectedTrack( this.jukeboxData.playlistsId, trackId );                  
          } 
        }
      );
    }
  }

  subscribeReduxQueuedTracks() {
    this.queuedTracksSubscription = this.store.select('queuedTracks')
      .pipe(
        filter( (queueTracksState) => queueTracksState.queuedTracks != null)
      )
      .subscribe( (queueTracksState) => {
        this.alog.debug('FormBaseComponent > subscribeReduxQueuedTracks:', queueTracksState.queuedTracks);
        if (this.playQueueSize !== queueTracksState.queuedTracks.length) {
          // Conditional to avoid code executed twice. Rxjs to check only once.
          // Otherwise when queuedtrack changes executes again
        }
        this.checkTrackIsInQueuedTracks();
        this.queuedTracks = queueTracksState.queuedTracks;            
        this.playQueueSize = queueTracksState.queuedTracks.length;        
    });
  }

  subscribeReduxJukeboxRealtime() {
    this.jukeboxRealtimeSubscription = this.store.select('jukeboxRealtime')
      .pipe(
        filter( (jukeboxRealtimeState) => jukeboxRealtimeState.jukeboxRealtime != null)
      )
      .subscribe( (jukeboxRealtimeState) => {
        this.alog.debug('FormBaseComponent > subscribeReduxJukeboxRealtime:', jukeboxRealtimeState.jukeboxRealtime);
        if ( jukeboxRealtimeState?.jukeboxRealtime?.me_player ) {
          this.isCurrentlyPlaying = jukeboxRealtimeState.jukeboxRealtime.me_player.is_playing;
        }
    });
  }
  
  checkTrackIsInQueuedTracks() {
    this.alog.debug('FormBaseComponent > checkTrackIsInQueuedTracks - this.trackSelected, :', this.trackSelected, this.isTrackRequestedByUser);
    if (this.trackSelected && !this.isTrackRequestedByUser) {
      for (let iQueuedTrack of this.queuedTracks) {
        if (iQueuedTrack.trackUri == this.trackSelected.uri) {
          this.router.navigate(['', this.subdomainService.subdomain.id, 'jukebox', this.jukeboxService.jukeboxIdSubscribed]);
          this.uiService.showSuccess('Acaban de seleccionar tu canción. ¡Puedes elegir otra!');
          break;
        }  
      }
    }
  }

  checkSelectedTrackIsInQueuedTracks() {
    this.alog.debug('FormBaseComponent > checkSelectedTrackIsInQueuedTracks - this.trackSelected, :', this.trackSelected, this.isTrackRequestedByUser);
    if (this.trackSelected && !this.isTrackRequestedByUser) {
      for (let iQueuedTrack of this.queuedTracks) {
        if (iQueuedTrack.trackUri == this.trackSelected.uri) {
          this.router.navigate(['', this.subdomainService.subdomain.id, 'jukebox', this.jukeboxService.jukeboxIdSubscribed]);
          this.uiService.showSuccess('La canción elegida ya está en cola. ¡Puedes elegir otra!');
          break;
        }  
      }
    }
  }

  /**
   * 
   * @param playlistId 
   * @param trackId 
   */
  subscribeSelectedTrack(playlistId: string, trackId: string) {

    this.selectedTrackSubscription.unsubscribe();
    // TODO Move to redux
    this.selectedTrackSubscription = this.ngFireService.subscribeSelectedTrack(playlistId, trackId)
      .subscribe(
        (response: any) => {
          const track: Track = response as Track;
          this.alog.debug('FormBaseComponent > subscribeSelectedTrack track', track);
          if ( !track || !( track.isAvailableToPlay )) {
            //this.router.navigate(['/']);
            this.router.navigate(['', this.subdomainService.subdomain.id, 'jukebox', this.jukeboxService.jukeboxIdSubscribed]);
            this.uiService.showSuccess('Tu canción ya ha sido seleccionada');            
          } else {
            this.trackSelected = track;
            this.checkSelectedTrackIsInQueuedTracks();
            this.analytics.logEvent('view_track', { track_name: track.name, track_artist: track.artists[0].name, source: 'web' });
          }
        },
        (err) => {
          this.alog.error('FormBaseComponent > subscribeSelectedTrack', err);
          this.router.navigate(['', this.subdomainService.subdomain.id, 'jukebox', this.jukeboxService.jukeboxIdSubscribed]);
        }
      );
  }

  /**
   * 
   */
  onTrackRequested(isTrackRequested: boolean) {
    this.alog.debug('form-base > onVoted isTrackRequested', isTrackRequested);
    this.isTrackRequestedByUser = isTrackRequested;
  }

  ngOnDestroy() {
    this.alog.debug('FormBaseComponent > ngOnDestroy subscriptions');
    this.uiSubscription.unsubscribe();
    this.authSubscription.unsubscribe();
    this.queuedTracksSubscription.unsubscribe();
    this.selectedTrackSubscription.unsubscribe();
    this.jukeboxRealtimeSubscription.unsubscribe();
    this.jukeboxConfigurationSubscription.unsubscribe();
  }

}
