import { Component, OnInit, OnDestroy } from '@angular/core';
import { User } from 'src/app/shared/models/user.model';
import { Observable, Subscription } from 'rxjs';
import { JukeboxConfig } from '../../../shared/models/jukebox.model';
import { NGXLogger } from 'ngx-logger';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { filter, finalize } from 'rxjs/operators';
import { MenuImage } from '../../../shared/models/commerce.model';
import { CommerceService } from '../../../shared/services/commerce.service';
import { Commerce } from 'src/app/shared/models/commerce.model';

@Component({
  selector: 'app-menu-images',
  templateUrl: './menu-images.component.html',
  styleUrls: ['./menu-images.component.css']
})
export class MenuImagesComponent implements OnInit, OnDestroy {

  public readonly MAX_MENU_IMAGES: number = 10;
  private readonly MS_IN_ONE_SECOND: number = 1000;
  private readonly MS_IN_ONE_MINUTE: number = 60000;

  private readonly URL_IMAGE_BK: string = 'https://firebasestorage.googleapis.com/v0/b/test-paviado.appspot.com/o/assets%2Fbk_dialog_placeholder.png?alt=media&token=a6a4a911-219d-4a9e-8549-ab6f9c8fdae9';
  private readonly URL_IMAGE_ADS_MODE_BK: string = 'https://firebasestorage.googleapis.com/v0/b/test-paviado.appspot.com/o/assets%2Fbk_dialog_placeholder.png?alt=media&token=a6a4a911-219d-4a9e-8549-ab6f9c8fdae9';
  private readonly URL_IMAGE_POPUP: string = 'https://firebasestorage.googleapis.com/v0/b/test-paviado.appspot.com/o/assets%2Fbk_dialog_placeholder.png?alt=media&token=a6a4a911-219d-4a9e-8549-ab6f9c8fdae9';
  private readonly URL_IMAGE_BANNER: string = 'https://firebasestorage.googleapis.com/v0/b/test-paviado.appspot.com/o/uploads%2Fbk_error_loading_bk.png?alt=media&token=beae432a-af05-4cf7-9659-46be6e561e09';
  private readonly URL_IMAGE_HEADER: string = 'https://firebasestorage.googleapis.com/v0/b/test-paviado.appspot.com/o/uploads%2Fbk_error_loading_bk.png?alt=media&token=beae432a-af05-4cf7-9659-46be6e561e09';


  user: User;
  commerce: Commerce;

  isLoading = false;
  uiSubscription: Subscription = new Subscription();
  authSubscription: Subscription = new Subscription();
  commerceSubscription: Subscription = new Subscription();

  downloadURL: Observable<string>;
  uploadProgress: Observable<number>;

  commerceMenuImages: MenuImage[] = [];


  constructor( private alog: NGXLogger,
               private store: Store<AppState>,
               private adminService: AdminService,
               private afStorage: AngularFireStorage,
               private commerceService: CommerceService ) { }


  ngOnInit() {

    this.subscribeUI();

    this.subscribeAuth();

    this.subscribeCommerce();

    // this.jukeboxConfigurationSubscription = this.store.select('jukeboxConfiguration')
    //   .pipe(
    //     filter( jukeboxState => jukeboxState.jukeboxConfiguration != null)
    //   )
    //   .subscribe( (jukeState) => {
    //     // Make a copy of the object. Because Redux return a readonly object
    //     this.jukeboxConfiguration = JSON.parse(JSON.stringify(jukeState.jukeboxConfiguration));

    //     if (this.jukeboxConfiguration.imgs_url && this.jukeboxConfiguration.imgs_url.popups) {
    //       this.jukeboxPopupImages = Object.values(this.jukeboxConfiguration.imgs_url.popups);
    //     }
    //     this.updateUI();
    // });
  }

  subscribeUI() {
    this.uiSubscription = this.store.select('ui')
      .subscribe( ui => {
        this.isLoading = ui.loadingItems.length > 0;
    });
  }

  subscribeAuth() {
    this.authSubscription =  this.store.select('auth')
      .pipe(
        filter( auth => auth.user != null)
      )
      .subscribe( (auth) => {
        this.user = auth.user;
    });
  }

  subscribeCommerce() {
    this.commerceSubscription = this.store.select('commerces')
      .pipe(filter( (commerceState) => commerceState.commerce != null))
      .subscribe( commerceState => {
        this.alog.debug('menu-images > subscribeCommerce commerceState.commerce:', commerceState.commerce);
        this.commerce = JSON.parse(JSON.stringify(commerceState.commerce));
        if (this.commerce.features?.menu?.default?.menu_images) {
          this.commerceMenuImages = Object.values(this.commerce.features.menu.default.menu_images);
        }
        this.updateUI();
    });
  }

  updateUI() {
    
  }


  /**
   * Upload image 
   * When upload image with same name overwrite last version on Firebase Storage
   * Jukeboxes can only have 4 group of images asociated
   * Image file name is formed by: 
   * img_banner, img_header, img_popup, img_dialog + '' + this.jukeboxConfiguration.id;
   * 
   * File path is created by UserId. Since a user can have multiple images,
   * we concatenate the jukeboxId in the file name so is unique.
   * 
   */
  upload(event, image_section: string) {
    this.alog.debug('upload image_section: ', image_section);
    
    this.isLoading = true;

    let path: string;
    let file: string = event.target.files[0];
    let fileName: string = '';
    let menuImage: MenuImage = new MenuImage();

    this.alog.debug('upload file: ', file);

    if (image_section === 'img_menu') {
      // Image with same name will be overwrite. We generate unique image id with random (0 - 99999)
      let random = [...Array(5)].map(i=>(~~(Math.random()*10)).toString(10)).join('');
      fileName = image_section + '_' + random;
      path = '/' + this.commerce.id + '/menu_images/' + fileName;
      // path = '/' + this.user.uid + '/popups/' + fileName;
      this.alog.debug('upload fileName & path', fileName, path);

      menuImage.isActive = true;
      menuImage.storagePath = path;
    }
    
    const ref = this.afStorage.ref(path);    
    const task = ref.put(file);
    this.uploadProgress = task.percentageChanges();

    task.snapshotChanges().pipe(
      finalize( () => {
        this.downloadURL = ref.getDownloadURL()
        this.downloadURL.subscribe(
          url => {
            this.alog.debug('upload url', url);
            if (image_section.startsWith('img_menu')) {
              menuImage.url = url;
              this.adminService.pushCommerceMenuImage(this.commerce, menuImage);
            }
            this.updateUI();
            this.isLoading = false;
          });
      })
    )
    .subscribe();
  }

  onClickDeleteMenuImage(menuImageId: string) {
    this.alog.debug('menuImages > onClickDeleteMenuImage menuImageId: ', menuImageId);
    this.commerceService.deleteCommerceMenuImage(this.commerce.id, menuImageId);
  }

  onClickToogleIsActive(menuImageId: string) {
    this.alog.debug('menuImages > onClickToogleIsActive menuImageId: ', this.commerce.features.menu.default.menu_images[menuImageId]);
    // let menuImage: MenuImage = this.jukeboxConfiguration.imgs_url.popups[adImageId];
    let menuImage: MenuImage = this.commerce.features.menu.default.menu_images[menuImageId];
    // adImage.isActive = false;
    // this.adminService.updatePopupImage(this.jukeboxConfiguration.id, adImage);
    menuImage.isActive = !menuImage.isActive;
    this.commerceService.updateCommerceMenuImage(this.commerce.id, menuImage);
  }

  ngOnDestroy() {
    this.uiSubscription.unsubscribe();
    this.authSubscription.unsubscribe();
    this.commerceSubscription.unsubscribe();
  }

}
