


<header >

  <nav class="navbar navbar-dark justify-content-center py-3">

    <!-- Back -->
    <div *ngIf="subdomain" class="home cursor" style="position: absolute; left: 15px;">
      <i (click)="onClickNavigationBack()" class="fas fa-arrow-circle-left fa-2x" style="color: whitesmoke;"></i>      
    </div>
 
    <!-- Logo & name commerce -->
    <section *ngIf="this.commerce">
      <div *ngIf="this.commerce" class="d-flex align-items-center cursor">
        <img [routerLink]="['/', this.commerce.subdomain]" 
              src="{{ this.commerce.place.profile.images.urlLogoThumbnail }}" 
              class="rounded-circle" style="width: 50px;" alt="" 
              onerror="this.src='assets/images/ic_launcher.png'">
        <!-- <h2 class="pl-3 pt-3 text-white">{{ this.commerce.place.profile.displayName }}</h2> -->
      </div>
    </section>

    <!-- Logo paviado -->
    <section *ngIf="!this.commerce">
      <img [routerLink]="['/']" 
           src="/assets/images/paviado_wordmark.png" alt="Wordmark" class="img-fluid cursor" style="width: 190px;">
    </section>

    <!-- User login/ logout -->
    <button (click)="onClickOpenModalMenu(content)" class="navbar-toggler px-1 py-0 border-0" 
      type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
      
      <img *ngIf="user" src="{{ this.user.photoURL }}" id="user-pic" alt="" onerror="this.src='assets/images/profile_placeholder.png'">
      <div *ngIf="!user">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" class="svg-inline--fa fa-bars fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">        
          <path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
        </svg>
      </div>
    </button>
  </nav>

  <!-- <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse" id="navbarToggleExternalContent">
    <ul class="navbar-nav text-right p-3">
      <li class="nav-item active">
        <h4 *ngIf="true" (click)="open(content)" class="text-white cursor"><i class="fab fa-google"></i> - Login Google</h4>
      </li>
      <li class="nav-item active">
        <h4 *ngIf="!user" (click)="signInWithGoogle()" class="text-white cursor"><i class="fab fa-google"></i> - Login Google</h4>
      </li>
      <li class="nav-item active">
        <h4 *ngIf="!user" (click)="signInWithFacebook()" class="text-white cursor"><i class="fab fa-facebook"></i> - Login Facebook</h4>
      </li>
      <li class="nav-item active">
        <h4 *ngIf="user" (click)="logout()" class="text-white cursor">Logout</h4>
      </li>
      <li class="nav-item active">
        <h4 *ngIf="user?.commerces" (click)="logout()" class="text-white cursor">Gestión tienda</h4>
      </li>
    </ul>
  </div> -->

</header>


<ng-template #content let-modal>
  <div class="modal-header">
    <h5 *ngIf="!user" class="modal-title" id="modal-basic-title"> Inicio sesión </h5>
    <h5 *ngIf="user" class="modal-title" id="modal-basic-title"> {{ user.displayName ? user.displayName : user.email }} </h5>
    
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div *ngIf="!user">
      <div class="login-form">
        <form>
          <!-- <h3 class="text-center">Inicio sesión</h3> -->
          <div class="text-center social-btn">
            <a (click)="signInWithFacebook();modal.dismiss('Cross click')" class="btn btn-primary btn-block" style="color: whitesmoke;"><i class="fab fa-facebook-f"></i> Entrar con <b>Facebook</b></a>
            <a (click)="signInWithGoogle();modal.dismiss('Cross click')" class="btn btn-danger btn-block" style="color: whitesmoke;"><i class="fab fa-google"></i> Entrar con <b>Google</b></a>
            <!-- <a (click)="signInWithFacebook()" class="btn btn-info btn-block" style="color: whitesmoke;"><i class="fab fa-twitter"></i> Entrar con <b>Twitter</b></a> -->
          </div>
          
        </form>
        <!-- <div class="hint-text small">Don't have an account? <a href="#" class="text-success">Register Now!</a></div> -->
      </div>
    </div>
    <div *ngIf="user">
      
      <h3 class="text-center"></h3>		
        <div class="text-center social-btn">
        <!-- <a (click)="logout();modal.dismiss('Cross click')" class="btn btn-block btn-paviado"><i class="fas fa-store-alt"></i> Gestión tienda</a> -->
        <a (click)="logout();modal.dismiss('Cross click')" class="btn btn-block btn-paviado"><i class="fas fa-sign-out-alt"></i> Cerrar sesión</a>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button> -->
  </div>
</ng-template>