import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducers';
import { JukeboxService } from 'src/app/shared/services/jukebox.service';
import { SubdomainService } from '../../shared/services/subdomain.service';
import { Commerce } from '../../shared/models/commerce.model';

@Component({
  selector: 'app-place-picker',
  templateUrl: './place-picker.component.html',
  styleUrls: ['./place-picker.component.css']
})
export class PlacePickerComponent implements OnInit, OnDestroy {

  isLoading: boolean = false;
  isPiccadilly: boolean = false;
  isAppSubdomain: boolean = false;

  commerce: Commerce;
  
  uiSubscription: Subscription = new Subscription();
  commerceSubscription: Subscription = new Subscription();

  constructor( private alog: NGXLogger,
               private router: Router,
               private store: Store<AppState>,
               public jukeboxService: JukeboxService,
               public subdomainService: SubdomainService ) { }

  ngOnInit() {

    this.uiSubscription = this.store.select('ui')
      .subscribe( ui => {
        this.isLoading = ui.loadingItems.length > 0;
    });

    this.commerceSubscription = this.store.select('commerces')
      .pipe(filter( (commerceState) => commerceState.commerce != null))
      .subscribe( commerceState => {
        this.alog.debug('Place-picker > commerceState.commerce:', commerceState.commerce);
        this.commerce = commerceState.commerce;
    });

    if ( this.subdomainService.subdomain && this.subdomainService.subdomain.id == 'piccadilly' ) {
      this.isPiccadilly = true;
    }

    if ( !this.subdomainService.subdomain ) {
      // TODO Navigate to root. No company defined
      // this.router.navigate(['/']);
      window.location.href = 'https://app.paviado.com/';
    }
    
  }
  
  ngOnDestroy() {
    this.uiSubscription.unsubscribe();
    this.commerceSubscription.unsubscribe();
  }

}
