import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

  signInForm: FormGroup;

  constructor( private alog: NGXLogger,
               private router: Router,
               public authService: AuthService) { }

  ngOnInit() {

    this.signInForm = new FormGroup({
      'username': new FormControl(null, [Validators.required, Validators.email]),
      'password': new FormControl(null, [Validators.required])
    });
  }


  /**
   * 
   */
  public onSignInFormSubmit() {

    if (this.signInForm.valid) {

      let username = this.signInForm.get('username').value;
      let password = this.signInForm.get('password').value;

      this.authService.signIn(username, password)
        .then( (_) => {
          this.alog.debug('SignIn > onSignInFormSubmit - success');
          this.router.navigate(['/admin'])
            .then( (_) => {
            })
            .catch(err => {
              this.alog.debug('SignIn > onSignInFormSubmit - err:', err);
            });
        })
        .catch( (err) => {
          this.alog.error('SignIn > onSignInFormSubmit - error', err);
      });
    }
  }

}
