import { Action } from '@ngrx/store';




export const LOADING_CHANGE = '[UI] Loading change...';
export const LOADING_ADD = '[UI] Loading add...';
export const LOADING_REMOVE = '[UI] Loading remove...';
export const TOGGLE_SIDEBAR = '[UI] Toggle sidebar...';

export const LOADING_ITEM_FEEDBACK_MESSAGE_PUSH = 'feedback_push_message';
export const LOADING_ITEM_ADMIN_STATISTICS = 'admin_statistics';
export const LOADING_ITEM_FETCH_SALES = 'admin_fetch_sales';

export class LoadingChangeAction implements Action {
    readonly type = LOADING_CHANGE;

    constructor( public loading: boolean ) { }
}

export class ToggleSidebarAction implements Action {
  readonly type = TOGGLE_SIDEBAR;
}

export class LoadingAddAction implements Action {
  readonly type = LOADING_ADD;
  constructor( public item: string ) { }
}

export class LoadingRemoveAction implements Action {
  readonly type = LOADING_REMOVE;
  constructor( public item: string ) { }
}

export type acciones = ToggleSidebarAction
                      | LoadingChangeAction
                      | LoadingAddAction
                      | LoadingRemoveAction;


