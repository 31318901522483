
<br><br><br><br>



<div class="container h-100">
  <div class="row">

    <div class="user_card">

      <!-- <div class="d-flex justify-content-center">
        <div class="brand_logo_container">
          <img src="../../assets/images/ic_launcher.png" class="brand_logo" alt="Logo">
        </div>
      </div> -->

      <div class="row">
        <h3>Gracias por registrate</h3>
      </div>
      <div class="row">
        <br>

        <div *ngIf="authService.user as user">
          <p>Hemos enviado un correo de confirmación a <strong>{{user.email}}</strong>.</p>
          <p>Por favor comprueba tu correo y haz click en el enlace de verificar tu cuenta.</p>
        </div>
      </div>      
      
      <br>
      <div class="row">
        <p>
          ¿No has recibido el correo? <br> 
          Haz click <span (click)="authService.sendVerificationMail()" style="cursor: pointer;"> aquí <i class="fas fa-redo-alt"></i></span> para reenviar correo de verificación
        </p>
      </div>

      <br>
        
      <div class="row">
        <a routerLink="/admin/sign-in">Volver a inicio</a>
      </div>
    </div>
  </div>
</div>

