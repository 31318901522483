import { Injectable } from '@angular/core';
import { Observable, EMPTY, from } from 'rxjs';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { SubdomainService } from '../../../shared/services/subdomain.service';
import { CommerceService } from '../../../shared/services/commerce.service';
import { NGXLogger } from 'ngx-logger';
import { JukeboxService } from 'src/app/shared/services/jukebox.service';
import { Subdomain } from '../../../shared/models/subdomain.model';



@Injectable()
export class SubdomainResolver implements Resolve<any> {

  subdomain: Subdomain;
 
  constructor( private alog: NGXLogger,
               private router: Router,
               public subdomainService: SubdomainService,
               public commerceService: CommerceService,
               public jukeboxService: JukeboxService ) { }
 

  async resolve( route: ActivatedRouteSnapshot,
                 state: RouterStateSnapshot ): Promise<any> {

    const subdomain = route.params['subdomain'];
    this.alog.debug('SubdomainResolver > resolve - subdomain: ', subdomain);

    await this.subdomainService.getSubdomain(subdomain)
      .then( (subdomainSnapshot) => {
        this.alog.debug('SubdomainResolver > resolve - subdomainSnapshot: ', subdomainSnapshot);
        this.subdomain = subdomainSnapshot;
        // Observe commerce if exists
        this.observeCommerce(subdomainSnapshot?.commerces);
      
        // Observe jukebox if exists
        this.loadJukeboxes(subdomainSnapshot?.jukeboxes_parties);
        this.observeJukebox(subdomainSnapshot?.jukeboxes_parties);

      }).catch( (error) => {
        this.alog.error('SubdomainResolver > resolve - error: ', error);
        return this.router.navigate(['']);
      });

    if (!this.subdomainService.subdomain) {
      this.alog.debug('SubdomainResolver > resolve - subdomain dont exists');
      return this.router.navigate(['']);
    }

    if (!this.subdomain.commerces && !this.subdomain.jukeboxes_parties) {
      return this.router.navigate(['']);
    }

    if (!this.subdomain.commerces && this.subdomain.jukeboxes_parties) {
      const jukeboxesId: string[] = Object.keys(this.subdomain.jukeboxes_parties);
      // return this.router.navigate([ '', this.subdomain.id, 'jukebox' ]);
      this.alog.warn('SubdomainResolver > resolve - subdomain with only jukeboxes');
    }

    return Promise.resolve();
  }

  observeJukebox(jukeboxes_parties: { key: string; value: string; }[]) {
    if (jukeboxes_parties) {
      const jukeboxesId: string[] = Object.keys(jukeboxes_parties);
      if ( jukeboxesId.length > 0 ) {
        // TODO A commerce might have multiple jukeboxes. Should observe all
        this.jukeboxService.observeJukebox(jukeboxesId[0]);            
      }
    }
  }

  observeCommerce(commerces: { key: string; value: string; }[]) {
    if (commerces) {
      const commercesId: string[] = Object.keys(commerces);
      if ( commercesId.length > 0 ) {
        this.commerceService.observeCommerce(commercesId[0]);            
      }
    }
  }

  /**
   * 
   */
  loadJukeboxes(jukeboxes_parties: { key: string; value: string; }[]) {

    this.jukeboxService.jukeboxes = [];
    
    if (jukeboxes_parties) {
      const jukeboxesId: string[] = Object.keys(jukeboxes_parties);
      for ( let key of jukeboxesId ) {
        if ( !this.jukeboxService.jukeboxes.find( iJukebox => iJukebox?.jukebox_data?.id == key ) ) {
          this.jukeboxService.getJukebox(key)
            .then( (jukebox) => {
              this.alog.debug('NavbarComponent > loadJukeboxes jukebox:', jukebox);
              this.jukeboxService.jukeboxes.push(jukebox);
            })
            .catch( (err) => {
              this.alog.error('NavbarComponent > loadJukeboxes error:', err);
            });
        }
      }
    }
  }


}