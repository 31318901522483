import { Component, OnInit, OnDestroy, AfterViewInit, HostListener } from '@angular/core';
import { KeyValue } from '@angular/common';
import { Subscription } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import * as moment from 'moment';
import { filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducers';
import { StatisticsService } from 'src/app/shared/services/statistics.service';
import LeadValidator from '../../shared/helpers/lead-validator.helper';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit, AfterViewInit, OnDestroy {

  isLoading: boolean = true;
  isProcessingData: boolean = true;

  dateStart = moment().subtract(24, 'week');
  dateEnd = moment();

  // TOP TRACKS
  limitToTop: number = 5;
  topArtistsMap = new Map<string, number>();
  valueDescOrder = (a: KeyValue<string, number>, b: KeyValue<string, number>): number => {
    return a.value > b.value ? -1 : (b.value > a.value ? 1 : 0);
  }

  // SONG REQUESTS
  salesTotal: number = 0;
  week0: number[] = [0, 0, 0, 0, 0, 0, 0];
  week1: number[] = [0, 0, 0, 0, 0, 0, 0];
  week2: number[] = [0, 0, 0, 0, 0, 0, 0];
  week3: number[] = [0, 0, 0, 0, 0, 0, 0];
  
  
  // Chart Histogram - sale per day
  salesPerDayChartData: any[] = [];
  salesPerDayChartDimensions: any[] = [700, 400];  
  salesPerDayxAxisLabel: string = 'Fecha';
  salesPerDayyAxisLabel: string = 'Peticiones';
  salesPerDayChartColorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  // Chart Pie - Platform & valid leads
  salesPerPlatformColorScheme = {
    domain: ['#5AA454', '#000666', '#AAAAAA', '#C7B42C', '#A10A28', '#aae3f5']
  };
  salesPerPlatformChartData: any[] = [];
  salesPerPlatformDimensions: any[] = [700, 400];

  
  uiSubscription: Subscription = new Subscription();
  jukeboxDataSubscription: Subscription = new Subscription();


  constructor(private alog: NGXLogger,
              private store: Store<AppState>,
              private statisticsService: StatisticsService) { }

  ngOnInit() {

    this.uiSubscription = this.store.select('ui')
      .subscribe( uiState => {
        this.alog.debug('StatisticsComponent > ngOnInit - uiState.loadingItems:', uiState.loadingItems);
        this.isLoading = uiState.loadingItems.length > 0;          
    });

    this.jukeboxDataSubscription = this.store.select('jukeboxData')
      .pipe(
        filter( jukeboxState => jukeboxState.jukeboxData != null)
      )
      .subscribe( jukeboxState => {
        this.getSales(jukeboxState.jukeboxData.partiesId);
    });

  }

  ngAfterViewInit() {
    this.alog.debug('GraphicalAnalysisComponent > ngAfterViewInit');
    this.setGraphsDimensions();
  }

  ngOnDestroy() {
    this.uiSubscription.unsubscribe();
    this.jukeboxDataSubscription.unsubscribe();
  }

  /**
   * Triggered on screen resize
   * @param event window resize event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.alog.debug('StatisticsComponent > onResize');
    this.setGraphsDimensions();
  }

  /**
   * 
   * @param partiesId 
   */
  private async getSales(partiesId: string) {
    this.isProcessingData = true;
    await this.statisticsService.getSales(partiesId, this.dateStart.toISOString(), this.dateEnd.toISOString());
    this.getSalesPerDayChartData();
    this.getSalesPerPlatformData();
    this.getTopArtistsData();
    this.salesTotal = this.statisticsService.sales.length;

    this.isProcessingData = false;
  }


  /**
   * 
   */
  getTopArtistsData() {
    this.topArtistsMap.clear;
    this.topArtistsMap = this.statisticsService.topArtistsMap;
    let count: number = 0;
    this.topArtistsMap.forEach( (value, key, map) => {
      if (count >= this.limitToTop) {
        this.topArtistsMap.delete(key);
      }
      count++;   
    });
  }

  /**
   * 
   */
  getSalesPerPlatformData() {
    this.alog.debug('StatisticsComponent > getSalesPerPlatformData');
    this.salesPerPlatformChartData = [];
    this.salesPerPlatformChartData.push({ name: LeadValidator.LEAD_TABLET_MAIL_OK, value: this.statisticsService.salesPerPlatform.get(LeadValidator.LEAD_TABLET_MAIL_OK)});
    this.salesPerPlatformChartData.push({ name: LeadValidator.LEAD_WEB_MAIL_OK, value: this.statisticsService.salesPerPlatform.get(LeadValidator.LEAD_WEB_MAIL_OK)});
    this.salesPerPlatformChartData.push({ name: LeadValidator.LEAD_TABLET_NO_DATA, value: this.statisticsService.salesPerPlatform.get(LeadValidator.LEAD_TABLET_NO_DATA)});
    this.salesPerPlatformChartData.push({ name: LeadValidator.LEAD_TABLET_MAIL_KO, value: this.statisticsService.salesPerPlatform.get(LeadValidator.LEAD_TABLET_MAIL_KO)});
    this.salesPerPlatformChartData.push({ name: LeadValidator.LEAD_WEB_MAIL_KO, value: this.statisticsService.salesPerPlatform.get(LeadValidator.LEAD_WEB_MAIL_KO)});
  }

  /**
   * 
   */
  getSalesPerDayChartData() {
    let lineChartSalesPerDay: {name: string, value: number}[] = [];

    this.statisticsService.salesPerDay.forEach( (value: number, key: string) => {
      lineChartSalesPerDay.push({ name: key, value: value });
    });
    // [ { name: 'Legend label', series: [ {name: string, value: number}, ...] }, ... ];
    this.salesPerDayChartData = [ { name: 'Solicitudes por día', series: lineChartSalesPerDay } ];
  }

  /**
   * Setup chart dimensions according to screen size
   */
  setGraphsDimensions() {
    this.alog.debug('GraphicalAnalysisComponent > setGraphsDimensions - window size:',  window.innerWidth, window.innerHeight);
    const chartWidth = window.innerWidth * 0.90;
    const chartHeight = window.innerHeight * 0.5;
    this.salesPerDayChartDimensions = [chartWidth, chartHeight];
    this.salesPerPlatformDimensions = [chartWidth, chartHeight];
    
    this.alog.debug('GraphicalAnalysisComponent > setGraphsDimensions - chartWidth, chartHeight:', chartWidth, chartHeight);
  }
 

}
