import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { SharedModule } from '@app/shared/shared.module';
import { CommerceInfoComponent } from '@app/users/commerce-info/commerce-info.component';
import { CommerceListComponent } from '@app/users/commerce-list/commerce-list.component';
import { FeedbackComponent } from '@app/users/feedback/feedback.component';
import { FormBaseComponent } from '@app/users/jukebox/form-base/form-base.component';
import { FormCustomComponent } from '@app/users/jukebox/form-base/form-custom/form-custom.component';
import { FormLeadsComponent } from '@app/users/jukebox/form-base/form-leads/form-leads.component';
import { FormPromosComponent } from '@app/users/jukebox/form-base/form-promos/form-promos.component';
import { FormTrackComponent } from '@app/users/jukebox/form-base/form-track/form-track.component';
import { SongItemComponent } from '@app/users/jukebox/song-list/song-item/song-item.component';
import { SongListComponent } from '@app/users/jukebox/song-list/song-list.component';
import { ThanksComponent } from '@app/users/jukebox/thanks/thanks.component';
import { MenuComponent } from '@app/users/menu/menu.component';
import { PlacePickerComponent } from '@app/users/place-picker/place-picker.component';
import { FooterComponent } from '@app/users/shared/footer/footer.component';
import { NavbarComponent } from '@app/users/shared/navbar/navbar.component';
import { UsersLayoutComponent } from '@app/users/users-layout/users-layout.component';
import { UsersRoutingModule } from '@app/users/users-routing.module';
import { NgbModule, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    SongItemComponent,
    SongListComponent,
    FooterComponent,    
    ThanksComponent,
    FormBaseComponent,
    FormTrackComponent,    
    FormLeadsComponent,
    FormPromosComponent,
    UsersLayoutComponent,
    NavbarComponent,
    FeedbackComponent,
    FormCustomComponent,
    PlacePickerComponent,
    CommerceListComponent,
    CommerceInfoComponent,
    MenuComponent
  ],
  imports: [
    CommonModule,
    UsersRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgbRatingModule,
    NgbModule,
    GoogleMapsModule
  ],
  providers: [
  ]
})
export class UsersModule { }
