import 'firebase/analytics';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as firebase from 'firebase/app';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducers';
import { JukeboxService } from 'src/app/shared/services/jukebox.service';

import { JukeboxConfig, JukeboxData } from '../../../shared/models/jukebox.model';
import { QueuedTrack } from '../../../shared/models/queued-track.model';
import { SubdomainService } from '../../../shared/services/subdomain.service';


@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.css']
})
export class ThanksComponent implements OnInit, OnDestroy {
    
  jukeboxData: JukeboxData;
  jukeboxConfiguration: JukeboxConfig;

  isLoading: boolean = false;
  displayText: string = 'Cargando...';
  playQueueSize: number = 1;
  isRateActiveForUser: boolean = false;

  analytics = firebase.analytics();

  //rate: number;
  currentRate: number = 0;
  alreadyClickRate: boolean = false;

  uiSubscription: Subscription = new Subscription();
  jukeboxSubscription: Subscription = new Subscription();
  jukeboxDataSubscription: Subscription = new Subscription();
  jukeboxConfigurationSubscription: Subscription = new Subscription();
  queuedTracksSubscription: Subscription = new Subscription();

  constructor( private alog: NGXLogger,  
               private route: ActivatedRoute,
               private store: Store<AppState>,
               private jukeboxService: JukeboxService,
               public subdomainService: SubdomainService,
               private sanitizer: DomSanitizer )  { }

  ngOnInit() {

    this.uiSubscription = this.store.select('ui')
        .subscribe( ui => {
          this.isLoading = ui.loadingItems.length > 0;
    });

    this.jukeboxData = this.jukeboxService.jukeboxDataSubscribed;
    this.subscribeQueuedTracks(this.jukeboxData.partiesId);
    this.subscribeReduxJukebox();

    this.isRateActiveForUser = this.jukeboxService.isUserFeedbackActive();

    this.analytics.logEvent('web_screen', { screen: 'thanks' });
  }

  subscribeReduxJukebox() {
    this.jukeboxSubscription = this.store.select('jukeboxConfiguration')
      .pipe(
        filter( (jukeState) => jukeState.jukeboxConfiguration != null)
      )
      .subscribe( (jukeState) => {
        this.jukeboxConfiguration = jukeState.jukeboxConfiguration;
    });
  }

  /**
   * 
   * @param partyId 
   */
  subscribeQueuedTracks(partyId: string) {
    this.queuedTracksSubscription.unsubscribe();
    this.queuedTracksSubscription = this.jukeboxService.subscribeQueuedTracks(partyId)
      .pipe(take(1))
      .subscribe(
        (queuedTracks: QueuedTrack[]) => {
          this.alog.debug('ThanksComponent > subscribeQueuedTrackList queuedTracks', queuedTracks);
          this.playQueueSize = queuedTracks.length;
          if (this.playQueueSize <= 1) {
            this.displayText = 'Tu canción es la siguiente. ¡Disfruta!';
          } else {
            this.displayText = 'Canción añadida con éxito. Sonará después de ' + this.playQueueSize + ' canciones. ¡Disfruta!';
          }
        },
        (err) => {
          this.alog.error('ThanksComponent > subscribeQueuedTrackList err', err);
        }
      );
  }

  public getSantizeUrl(url : string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  clickOnInstagramLink() {
    this.alog.debug('ThanksComponent > clickOnInstagramLink');
    this.analytics.logEvent('web_button_click', { name: 'instagramLink', link: this.jukeboxConfiguration.instagramUser });
  }

  clickOnFeedbackLink() {
    this.alog.debug('ThanksComponent > clickOnFeedbackLink', this.currentRate.toString());
    this.jukeboxService.setUserAccessedRatingLinkOnLocalStorage();
    this.jukeboxService.setUserFeedbackOnLocalStorage(this.currentRate.toString());
    this.analytics.logEvent('web_button_click', { name: 'feedbackSiteLink', link: '/feedback' });
    this.analytics.logEvent('web_feedback', { screen: 'thanks', feedback_score: this.currentRate.toString() });
  }

  clickOnRatingLink() {
    this.alog.debug('ThanksComponent > clickOnRatingLink', this.currentRate.toString());
    this.jukeboxService.setUserAccessedRatingLinkOnLocalStorage();
    this.jukeboxService.setUserFeedbackOnLocalStorage(this.currentRate.toString());    
    this.analytics.logEvent('web_button_click', { name: 'ratingSiteLink', link: this.jukeboxConfiguration.ratingSiteUrl });
    this.analytics.logEvent('web_feedback', { screen: 'thanks', feedback_score: this.currentRate.toString() });
  }

  onClickRate() {
    this.alreadyClickRate = true;    
  }

  ngOnDestroy() {
    this.uiSubscription.unsubscribe();
    this.jukeboxSubscription.unsubscribe();
    this.queuedTracksSubscription.unsubscribe();
  }

}
