


<div class="container text-center" *ngIf="isLoading">
  <div class="spinner-border" role="status" style="color: whitesmoke">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<section *ngIf="!isLoading" class="commerce-info">

  <div *ngIf="commerce?.id == 'laclaveId'" class="top d-flex p-2 justify-content-around">
    <span class="text-white cursor" [routerLink]="['/laclave/menu']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">Todo</span>
    <span class="text-white cursor" [routerLink]="['/laclave/menu']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" [queryParams]="{ filter: 'bebida' }">Bebidas</span>
    <span class="text-white cursor" [routerLink]="['/laclave/menu']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" [queryParams]="{ filter: 'comida' }">Comida</span>
    <span class="text-white cursor" [routerLink]="['/laclave/menu']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" [queryParams]="{ filter: 'cachimbas' }">Cachimbas</span>
  </div>

  <div class="py-1">
    <div class="container">

      <div class="row">
        <div class="p-1 col-lg-12" style="text-align:center; width:100%;">

          <ngb-carousel [ngClass]="{ 'invert-color': commerce?.id !== 'laclaveId' }"
            *ngIf="menuImagesActive">

            <ng-template ngbSlide
              *ngFor="let iMenuImage of this.menuImagesActive; let i = index">
              
              <div class="row">
                <img class="img-fluid" src="{{ iMenuImage.url }}" alt="Menu" style="width: 100%;height: 100vh;">
              </div>
              <!-- <div class="carousel-caption">
                <h3>First slide label</h3>
                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
              </div> -->
            </ng-template>

          </ngb-carousel>

        </div>
      </div>

    </div>
  </div>
</section>