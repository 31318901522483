


<div class="container text-center"
     *ngIf="isLoading">
  <div class="spinner-border" role="status" style="color: whitesmoke">
    <span class="sr-only">Loading...</span>
  </div>
</div>


<section *ngIf="!isLoading" class="filter-lists">

      <div [ngClass]="{ 'show': isFilterShown }" class="collapse px-3" id="advanceFilter">

        <form  [formGroup]="searchCommerceNameForm"
               (ngSubmit)="onCommerceFilterFormSubmit()">
          <div class="input-group p-3">
            <input formControlName="searchFilterCommerce" type="text" class="form-control px-3 search-input border-0 collapsed" placeholder="Buscar por nombre">
          </div>
        </form>
        
        <!-- <div class="input-group  mt-3">
            <select class="form-control rounded-0 pl-2">
                <option>Tipo de sitio al que quieres ir</option>
            </select>
            <div class="input-group-append position-absolute right-0 rounded-0">                
                <span class="input-group-text rounded-0">V</span>
            </div>
        </div> -->
        <ng-multiselect-dropdown 
          [placeholder]="'¿Qué tipo de música te gusta más?'"
          [settings]="dropdownSettings"
          [data]="dropdownList"
          [(ngModel)]="musicStyleFilterSelectedItems"
          (onSelect)="onItemSelect($event)"
          (onSelectAll)="onSelectAll($event)">
        </ng-multiselect-dropdown>
          
        <!-- <div class="input-group mt-3">
            <select class="form-control rounded-0 pl-2" multiple (change)="onSelectedFilterMusic(selectElem.value)">
                <option>¿Qué tipo de música te gusta más?</option>
                <option>¿Qué tipo de música te gusta más?</option>
                <option>¿Qué tipo de música te gusta más?</option>
            </select>
            <div class="input-group-append position-absolute right-0 rounded-2">
                <span class="input-group-text rounded-2">V</span>
            </div>
        </div> -->
        <!-- <div class="radios pt-3 px-3 pb-2">
            <p class="mb-2 text-white d-block">¿Quieres reservar por adelantado?</p>
            <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="customRadioInline1" name="customRadioInline1" class="custom-control-input">
                <label class="custom-control-label text-white" for="customRadioInline1">SI</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="customRadioInline2" name="customRadioInline1" class="custom-control-input">
                <label class="custom-control-label text-white" for="customRadioInline2">NO</label>
            </div>
        </div> -->
        <!-- <div class="text-right submit-button pb-1">
            <button type="submit" class="btn btn-outline-light ml-auto px-4 py-2">BUSCAR</button>
        </div> -->
      </div>
  <!-- </form> -->

  <ul class="nav nav-pills" id="pills-tab" role="tablist">
      <li class="nav-item">
          <a (click)="onClickListView()" class="nav-link cursor" 
             [ngClass]="{ 'active': !isMapShown }" id="pills-Lista-tab" data-toggle="pill" role="tab" aria-controls="pills-Lista" aria-selected="true">Lista</a>
      </li>
      <li class="nav-item">
          <a (click)="onClickMapView()" class="nav-link cursor" 
             [ngClass]="{ 'active': isMapShown }" id="pills-Mapa-tab" data-toggle="pill" role="tab" aria-controls="pills-Mapa" aria-selected="false">Mapa</a>
      </li>
      <li class="collapse-button">
        <button (click)="isFilterShown = !isFilterShown"
                class="navbar-toggler px-1 py-0 border-0 advanceFilterbtn advancedFilter"
                type="button" data-toggle="collapse" data-target="#advanceFilter" aria-controls="advanceFilter" aria-expanded="false" aria-label="Toggle navigation">
          <span>filtros</span>
        </button>
      </li>
  </ul>

  <div class="tab-content" id="pills-tabContent">

      <div *ngIf="!isMapShown" class="tab-pane fade show active" id="pills-Lista" role="tabpanel" aria-labelledby="pills-Lista-tab">

          <div class="lists">

            <div class="list-item cursor" *ngFor="let iCommerce of commerces | commerceFilter:searchFilterCommerce:musicStyleFilterSelectedItems; let i = index" [routerLink]="['/', iCommerce.subdomain]">
                <div class="row no-gatter">
                  <div class="col-8 d-flex align-items-center">
                      <img src="{{ iCommerce.place.profile.images.urlLogoThumbnail }}" class="rounded-circle" alt="" onerror="this.src='assets/images/ic_launcher.png'">
                      <p class="text-white">{{ iCommerce.place.profile.displayName }}</p>
                  </div>
                  <div class="col-4 d-flex align-items-center text-right justify-content-end">
                      <!-- <p class="text-white">40%</p> -->
                      <a>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                        <path d="M20 11H6.83l2.88-2.88c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L3.71 11.3c-.39.39-.39 1.02 0 1.41L8.3 17.3c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L6.83 13H20c.55 0 1-.45 1-1s-.45-1-1-1z" />
                        </svg>
                      </a>
                  </div>
              </div>
            </div>

          </div>
      </div>

      <div *ngIf="isMapShown && isMapScriptLoaded" class="tab-pane fade show active" id="pills-Mapa" role="tabpanel" aria-labelledby="pills-Mapa-tab">          
        <div class="map" [style.height.px]="mapHeight" #mapContainer>
          
          <google-map width="100%" height="100%" [zoom]="zoom" [center]="center" [options]="options">
    
            <map-marker #markerElem *ngFor="let marker of markers | commerceFilter:searchFilterCommerce:musicStyleFilterSelectedItems; let i = index" 
              [position]="marker.position" 
              [label]="marker.label"
              [title]="marker.title" 
              [options]="marker.options"
              (mapClick)="openInfo(markerElem, marker)" >    
            </map-marker>
        
            <map-info-window >
              <div style="text-align: center;">  
                <div style="width: 100px;">       
                  <img src="{{ markerCustom.urlLogoThumbnail }}" 
                       class="img-responsive" 
                       style="height: 32px; width: 32px;" 
                       onerror="this.src='assets/images/ic_launcher.png'"
                       sizes="32px">
                  <div>
                    <a [routerLink]="['/', markerCustom.subdomain]"> {{ markerCustom.profile?.displayName }}</a> 
                  </div>
                </div>
              </div>
            </map-info-window>
        
          </google-map>
        </div>
      </div>

  </div>
</section>