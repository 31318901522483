import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '@app/app.reducers';
import FileUtils from '@app/shared/helpers/file-utils.helper';
import { LeadDownload } from '@app/shared/models/lead-download.model';
import { SalesService } from '@app/shared/services/sales.service';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';




@Component({
  selector: 'app-leads-download',
  templateUrl: './leads-download.component.html',
  styleUrls: ['./leads-download.component.css']
})
export class LeadsDownloadComponent implements OnInit, OnDestroy {

  isLoading: boolean = true;

  page = 1;
  pageSize = 50;

  partyId: string;
  leadsDownload: LeadDownload[] = [];
  leadsDownloadTable: LeadDownload[] = [];

  uiSubscription$: Subscription = new Subscription();
  jukeboxDataSubscription$: Subscription = new Subscription();

  constructor(
    private log: NGXLogger,
    private store: Store<AppState>,
    private salesService: SalesService
  ) { }
  

  ngOnInit(): void {
    this.uiSubscription$ = this.store.select('ui')
      .subscribe( ui => {
        this.isLoading = ui.loadingItems.length > 0;
    });

    this.getPartyId();
    
  }

  ngOnDestroy(): void {
    this.uiSubscription$.unsubscribe();
    this.jukeboxDataSubscription$.unsubscribe();
  }

  getPartyId() {
    this.jukeboxDataSubscription$ = this.store.select('jukeboxData')
      .pipe(
        filter( jukeboxState => jukeboxState.jukeboxData != null)
      )
      .subscribe( jukeboxState => {
        this.partyId = jukeboxState.jukeboxData.partiesId;       
        this.fetchSales(this.partyId); 
    });
  }

  /**
   * 
   * @param partyId 
   */
  async fetchSales(partyId: string) {
    let dateStart = moment();
    let dateEnd = moment();

    try {
      this.leadsDownload = await this.salesService.fetchSales(partyId, dateStart.toISOString(), dateEnd.toISOString());
      this.refreshLeads();
      this.log.debug('LeadsDownloadComponent > fetchSales - this.leadsDownload:', this.leadsDownload);

    } catch (error) {
      this.log.error('LeadsDownloadComponent > fetchSales - error:', error);

    } finally {
      
    }
  }

  /**
   * 
   */
  onClickDownloadLeads() {
    this.downloadFile(this.leadsDownload, 'paviado - emails');
  }

  
  downloadFile(data, filename='data') {
    let csvData = FileUtils.convertToCSV(data, ['email','name', 'lastRequest', 'totalRequest']);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  refreshLeads() {
    this.leadsDownloadTable = this.leadsDownload
      .map((leadDownload, i) => ({id: i + 1, ...leadDownload}))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

}
