


<div class="card" 
     (click) = "onTrackSelected()"
     [routerLink]="['/', this.subdomainService.subdomain.id, 'jukebox', this.jukeboxService.jukeboxIdSubscribed, 'play-track', track.id]">

  <div class="song text-center" style="cursor: pointer;">
    
    <img src="{{ track.album.images[0].url }}" class="img-responsive track-thumbnail" sizes="124px">

    <p class="card-title">{{ track.name | truncate: 14}}</p>
    <p class="card-text">{{ track.artists[0].name | truncate: 15}}</p>
  </div>
</div>
