
<div class="container-fluid">

  <br>

  <div class="row" joyrideStep="step-9" [stepContent]="customJoyrideStepContent2">
      
    <div class="col-md-8">
      <p><b>Playlist actual: </b> <span style="color: #6200EE; font-weight: bold;">{{ loadedPlaylist }}</span></p>
    </div>

    <div class="col-md-4 text-center">
      <div *ngIf="loadedPlaylist != 'Ninguna'">        
        <a class="btn btn-primary" routerLink="/admin/jukebox-tracks">Gestionar canciones</a>
      </div>
    </div>

  </div>

  <hr>

  <div class="row d-flex flex-wrap align-items-center" joyrideStep="step-8" [stepContent]="customJoyrideStepContent1">

    <div class="col-md-8">
      <div *ngIf="!isSpotifyAuthenticated">
        <p><b> Para cambiar la playlist, conéctate con tu cuenta de Spotify </b></p>
      </div>
      <div *ngIf="isSpotifyAuthenticated">
        <p><b> Cambiar la playlist para la jukebox </b></p>
      </div>        
    </div>
    
    <div class="col-md-4">
      <div *ngIf="!isSpotifyAuthenticated" class="text-center">
        <img class="img-fluid" src="../../../assets/images/button-login-spotify.png" (click)="authWithSpotify()" style="cursor: pointer; max-width: 200px;">  
      </div>
      <div *ngIf="isSpotifyAuthenticated" class="text-center">
        <a class="btn btn-primary" routerLink="/admin/playlists">Cargar nueva Playlist</a>
      </div>
    </div>
  </div>

  <br>

  <div class="card" joyrideStep="step-10" [stepContent]="customJoyrideStepContent3">
    <div class="card-header">
      <h6>Últimas 20 canciones solicitadas para añadir a tu playlist</h6>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Nombre</th>
            </tr>
          </thead>
          <tbody>

            <tr *ngFor="let request of songRequests; let i = index">
              <th scope="row"> {{ i + 1 }} </th>
              <td>{{ request.substring(0, request.indexOf('#'))}}</td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>

  <ng-template #customJoyrideStepContent1>
    <i class="fas fa-music"></i>
    Para cargar una nueva playlist, primero debes autorizar con una cuenta de Spotify. Una vez dentro podrás seleccionar una de tus playlists de Spotify para volcarla en Paviado.
  </ng-template>

  <ng-template #customJoyrideStepContent2>
    <i class="fas fa-music"></i>
    Si te cansas de escuchar algunas canciones de tu Paviado, puedes quitarlas aquí sin eliminarlas de tu playlist de Spotify.
  </ng-template>

  <ng-template #customJoyrideStepContent3>
    <i class="fas fa-music"></i>
    Tus usuarios pueden solicitar nuevas canciones desde el móvil. Aquí verás las últimas 20 solicitudes. Esto te ayudará a perfeccionar la banda sonora de tu local <i class="far fa-smile-wink fa-sm"></i>
  </ng-template>

</div>