


import { Action } from '@ngrx/store'
import { Commerce } from '../../models/commerce.model';


export const SET_COMMERCES = '[Commerce] Set Commerces';
export const UNSET_COMMERCES = '[Commerce] Unset Commerces';

export const SET_COMMERCE = '[Commerce] Set Commerce';
export const UNSET_COMMERCE = '[Commerce] Unset Commerce';
export const UPDATE_COMMERCE = '[Commerce] Update Commerce';


export class SetCommercesAction implements Action {
  readonly type = SET_COMMERCES;

  constructor( public commerces: Commerce[] ) { }
}

export class UnsetCommercesAction implements Action {
  readonly type = UNSET_COMMERCES;
}

export class SetCommerceAction implements Action {
  readonly type = SET_COMMERCE;

  constructor( public commerce: Commerce ) {}
}

export class UnsetCommerceAction implements Action {
  readonly type = UNSET_COMMERCE;
}

export class EditCommerceAction implements Action {
  readonly type = UPDATE_COMMERCE;

  constructor( public id: string, public commerce: Commerce ) {}
}

export type acciones = SetCommercesAction | UnsetCommercesAction |
                       SetCommerceAction | UnsetCommerceAction | EditCommerceAction;