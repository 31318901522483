import { AbstractControl } from '@angular/forms';
import { NgfireHelperService } from 'src/app/shared/services/ngfire-helper.service';

export class SubdomainAvailableValidator {

  static checkSubdomainAvailability(ngFireHelperService: NgfireHelperService) {

    return (control: AbstractControl) => {      
      const subdomain = control.value;
      return ngFireHelperService.checkSubdomainAvailability(subdomain);
    }
  }
}