import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-spotify-auth-code',
  templateUrl: './spotify-auth-code.component.html',
  styleUrls: []
})
export class SpotifyAuthCodeComponent implements OnInit {

  constructor( private route: ActivatedRoute,
               private router: Router ) { }

  ngOnInit() {


    this.route.fragment.subscribe( fragment => {

      // nsole.log('SpotifyCallbackComponent URLSearchParams', new URLSearchParams(fragment).toString());

      /*
      const access_token = new URLSearchParams(fragment).get('access_token');
      const expires_in = new URLSearchParams(fragment).get('expires_in');

      const expiration_time = new Date();
      expiration_time.setSeconds(expiration_time.getSeconds() + +expires_in);

      if (access_token) {
          localStorage.setItem('spotify_token', access_token);
          localStorage.setItem('expires_in', expires_in);
          localStorage.setItem('expiration_time', expiration_time.toString());
      }*/

      this.router.navigate(['/admin/jukebox-music']);
    });
  }

}
