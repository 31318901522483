


<div class="container text-center"
     *ngIf="isLoading">
  <div class="spinner-border" role="status" style="color: whitesmoke">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<section *ngIf="!isLoading && this.commerce" class="commerce-info">

  <div class="top d-flex justify-content-between align-items-center p-2">
    <div class="d-flex align-items-center">
      <h2 class="pl-3 text-white">{{ this.commerce.place.profile.displayName }}</h2>
    </div>
  </div>
  
  <div class="details px-3 py-2 text-white">
    <p>Música: {{ this.commerce.place.profile.music }}</p>
    <p>Descripción: {{ this.commerce.place.profile.description }}</p>
    <p>{{ this.commerce.place.site.location.address }}</p>
    <!-- <h2 class="text-center pt-2">Ocupación estimada</h2>
    <h1 class="text-center text-success">40%</h1> -->

    <div class="px-4 pb-4">
      <a *ngIf="this.menuImages.length > 0" [routerLink]="['menu']" class="btn btn-outline-light btn-block py-1 mb-3">Ver carta</a>
      <!-- <a *ngIf="this.commerce.features?.booking" [routerLink]="['booking']" href="" class="btn btn-outline-light btn-block py-1 mb-3">Reserva con prepago</a> -->
      <a *ngIf="this.commerce.features?.jukeboxes" [routerLink]="['jukebox']" href="" class="btn btn-outline-light btn-block py-1 mb-3">Gramola</a>
    </div>

    <!-- <p class="mb-0">Caracteristicas:</p>
    <ul class="list">
        <li><span class="mr-2">-</span> Chat con staff</li>
        <li><span class="mr-2">-</span> Proceso de check in & check out</li>
        <li><span class="mr-2">-</span> Consumicion minima 6€</li>
        <li><span class="mr-2">-</span> Consumicion minima 6€</li>
    </ul> -->
  </div>
</section>