import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducers';
import { NgfireHelperService } from 'src/app/shared/services/ngfire-helper.service';
import { LoadingAddAction, LoadingRemoveAction } from '../redux/actions/ui.actions';
import { SetMessagesAction } from '../redux/actions/messages.actions';
import { Message } from 'src/app/shared/models/message.model';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
   
  userId: string;
  commerceId: string;
  chatGroupId: string;

  messagesSubscription: Subscription = new Subscription();

  constructor( private alog: NGXLogger,
               private store: Store<AppState>,
               private ngFireService: NgfireHelperService ) { }


  cancellSubscriptions() {
    this.messagesSubscription.unsubscribe();
  }

  observeMessages(commerceId: string, userId: string) {
    this.alog.debug('MessageService > observeMessages - commerceId, userId', commerceId, userId);

    this.store.dispatch( new LoadingAddAction('messages') );

    this.messagesSubscription = this.ngFireService.observeMessages(commerceId, userId)
      .subscribe(
        (messages: Message[]) => {
          this.alog.debug('CommerceService > observeCommerces - messages: ', messages);
          this.store.dispatch( new SetMessagesAction(messages) ); 
          this.store.dispatch( new LoadingRemoveAction('messages') );
        },
        (err) => {
          this.alog.debug('CommerceService > observeCommerces err', err);
          this.store.dispatch( new LoadingRemoveAction('messages') );
        },
        () => {
          this.alog.debug('CommerceService > observeCommerces complete');
        }
      );
  }

  pushMessage(commerceId: string, userId: string, message: Message): Promise<void> {
    this.alog.debug('MessageService > pushMessage - message: ', message);
    return this.ngFireService.pushMessage(commerceId, userId, message);
  }

  // Requests permissions to show notifications.
  requestNotificationsPermissions(commerceId: string, userId: string) {
    this.alog.debug('MessageService > requestNotificationsPermissions START');
    
    return firebase.messaging().requestPermission()
      // Notification permission granted.
      .then( () => this.saveMessagingDeviceToken(commerceId, userId))
      .catch( (err) => {
        this.alog.error('MessageService > requestNotificationsPermissions error: ', err);
      });
  };

  // Saves the messaging device token to the datastore.
  saveMessagingDeviceToken(commerceId: string, userId: string) {
    this.alog.debug('MessageService > saveMessagingDeviceToken START');
    
    return firebase.messaging().getToken()
      .then( (currentToken) => {
        this.alog.debug('MessageService > saveMessagingDeviceToken - currentToken: ', currentToken);

        if (currentToken) {
          // Save the Device Token to the datastore.
          this.ngFireService.addFcmToken(currentToken, commerceId, userId, userId);
          
        } else {
          // Need to request permissions to show notifications.
          this.alog.debug('MessageService > saveMessagingDeviceToken - currentToken: ', currentToken);
          return this.requestNotificationsPermissions(commerceId, userId);
        }
      })
      .catch( (err) => {
        this.alog.error('MessageService > saveMessagingDeviceToken error: ', err);
      });
  };

}
