import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducers';
import { ToggleSidebarAction } from '../../../shared/redux/actions/ui.actions';
import { AuthService } from '../../../shared/services/auth.service';
import { AdminService } from '../../../shared/services/admin.service';
import { User } from '../../../shared/models/user.model';
import { Jukebox, JukeboxConfig } from '../../../shared/models/jukebox.model';
import { NGXLogger } from 'ngx-logger';
import { CommerceService } from '../../../shared/services/commerce.service';

@Component({
  selector: 'app-navbar-admin',
  templateUrl: './navbar-admin.component.html',
  styleUrls: ['./navbar-admin.component.css']
})
export class NavbarAdminComponent implements OnInit, OnDestroy {

  showSidebar: boolean = true;
  isMenuCollapsed: boolean = false;
  isJukeboxListExpanded: boolean = false;

  user: User;
  // Loaded once (Not subscribed)
  jukeboxes: Jukebox[] = [];

  jukeboxesConfiguration: JukeboxConfig[] = [];
  jukeboxActive: string = 'Jukeboxes';

  uiSubscription: Subscription = new Subscription();
  authSubscription: Subscription = new Subscription();
  jukeboxConfigurationSubscription: Subscription = new Subscription();

  constructor( private alog: NGXLogger,
               private store: Store<AppState>,
               private authService: AuthService,
               private adminService: AdminService,
               private commerceService: CommerceService ) { }

  ngOnInit() {

    this.authSubscription =  this.store.select('auth')
      .pipe(
        filter( auth => auth.user != null)
      )
      .subscribe( (auth) => {
        // FIXME This may be executed twice if user collectino chages. ie when complete the isAdminTourCompleted
        this.user = auth.user;
        this.subscribeJukeboxConfiguration();
        this.loadDefaultOrSelectedJukebox(auth.user.jukeboxes_parties);
        this.loadJukeboxes(auth.user.jukeboxes_parties);
        this.observeCommerce(auth.user.commerces?.owns);
      });

  }

  /**
   * 
   * @param owns 
   */
  observeCommerce(owns: { key: string; value: string; }[]) {

    if ( owns ) {
      // this.jukeboxesCount = Object.keys( jukeboxes_parties ).length;
      const edited_commerceId = localStorage.getItem('edited_commerceId');

      if (edited_commerceId) {
        // The user already selected a Commerce
        this.commerceService.observeCommerce(edited_commerceId);
        
      } else {
        // Get one by default
        for ( var key in owns ) {
          if (owns.hasOwnProperty(key)) {
            this.commerceService.observeCommerce(key);
            break;
          }
        }
      }
    }
  }
  
  /**
   * 
   */
  subscribeJukeboxConfiguration() {
    
    this.jukeboxConfigurationSubscription =  this.store.select('jukeboxConfiguration')
      .subscribe( (jukeboxState) => {
        if (!jukeboxState.jukeboxConfiguration) {
          this.jukeboxActive = 'Jukeboxes';            
          
        } else {
          this.jukeboxActive = jukeboxState.jukeboxConfiguration.displayName;
        }
    });
  }

  /**
   * 
   */
  loadDefaultOrSelectedJukebox(jukeboxes_parties: any[]) {
    
    if ( jukeboxes_parties ) {
      // this.jukeboxesCount = Object.keys( jukeboxes_parties ).length;
      const edited_jukeboxId = localStorage.getItem('edited_jukeboxId');

      if (edited_jukeboxId) {
        // The user already selected a Jukebox
        this.adminService.subscribeJukebox(edited_jukeboxId);
        
      } else {
        // Get one by default
        for ( var key in jukeboxes_parties ) {
          if (jukeboxes_parties.hasOwnProperty(key)) {
              //consol.log(key + " -> " + jukeboxes_parties[key]);
              this.adminService.subscribeJukebox(key);
              break;
          }
        }
      }
    }
  }

  /**
   * 
   */
  loadJukeboxes(jukeboxes_parties: any[]) {
    // Get one by default
    if ( jukeboxes_parties ) {

      for ( var key in jukeboxes_parties ) {
        if (jukeboxes_parties.hasOwnProperty(key)) {
          
            this.adminService.getJukebox(key)
              .then( (jukebox) => {
                // Check if already exists in array
                if ( !this.jukeboxes.find( iJukebox => iJukebox.jukebox_data.id == jukebox.jukebox_data.id) ) {
                  this.jukeboxes.push(jukebox);
                  // order alphabetical
                  if (this.jukeboxes.length > 1) {
                    this.jukeboxes = this.jukeboxes.sort( (left, right) => {
                      if (left.jukebox_configuration.displayName < right.jukebox_configuration.displayName) return -1;
                      if (left.jukebox_configuration.displayName > right.jukebox_configuration.displayName) return 1;
                      return 0;
                    });
                  }
                }
                
              })
              .catch( (err) => {
                this.alog.error('NavbarAdminComponent > loadJukeboxes error:', err);
              });
        }
      }
    }
  }

  /**
   * 
   */
  toggleSidebar() {
    this.showSidebar = !this.showSidebar;
    this.store.dispatch( new ToggleSidebarAction() );
  }

  /**
   * 
   */
  toggleJukeboxList() {
    this.isJukeboxListExpanded = !this.isJukeboxListExpanded;
  }

  /**
   * 
   * @param jukeboxesId 
   */
  onJukeboxSelect(jukeboxesId: string) {
    localStorage.setItem('edited_jukeboxId', jukeboxesId);
    this.adminService.subscribeJukebox(jukeboxesId);
  }

  signOut() {
    this.authService.signOut('admin');
    this.adminService.cancellSubscriptions();
    localStorage.clear();
  }

  ngOnDestroy() {
    this.uiSubscription.unsubscribe();
    this.authSubscription.unsubscribe();
    this.jukeboxConfigurationSubscription.unsubscribe();
  }
  
}
