


<br><br><br><br>

<div class="container h-100">
  <div class="d-flex justify-content-center h-100">
    <div class="user_card">
      <div class="d-flex justify-content-center">
        <div class="brand_logo_container">
          <img src="../../assets/images/ic_launcher.png" class="brand_logo" alt="Logo">
        </div>
      </div>
      <div class="d-flex justify-content-center form_container">
        <form [formGroup]="signUpForm">

          <div class="input-group mb-2">
            <div class="input-group-append">
              <span class="input-group-text"><i class="fas fa-user"></i></span>
            </div>
            <input type="text" name="username" class="form-control input_user" value="" placeholder="email" formControlName="email" #userName required
                   [ngClass]="{ 'is-invalid': signUpForm.get('email').invalid && signUpForm.get('email').touched,
                                'is-valid': signUpForm.get('email').valid && signUpForm.get('email').dirty }">
          </div>
          
          <div class="input-group mb-2">
            <div class="input-group-append">
              <span class="input-group-text"><i class="fas fa-key"></i></span>
            </div>
            <input type="password" name="password" class="form-control input_pass" value="" placeholder="contraseña" formControlName="password" #userPassword required
                   [ngClass]="{ 'is-invalid': signUpForm.get('password').invalid && signUpForm.get('password').touched,
                                'is-valid': signUpForm.get('password').valid && signUpForm.get('password').dirty }">
          </div>

          <div class="text-center">
            <p style="color: white; font-size: 1em;">
              <br/>
              ¿Cómo se llamará tu playlist online?
            </p>
          </div>

          <div class="input-group mb-2">
            
            <div class="input-group-append">
              <span class="input-group-text"><i class="fas fa-globe"></i></span>
            </div>
            <div class="input-group-prepend">
              <span class="input-group-text" id="subdomain">app.paviado.com/</span>
            </div>
            <input type="text" name="subdomain" class="form-control" value="" placeholder="empresa" pattern="[a-zA-Z0-9]*"
                   formControlName="subdomain" maxlength="16"
                   [ngClass]="{ 'is-invalid': signUpForm.get('subdomain').invalid && signUpForm.get('subdomain').touched,
                                'is-valid': signUpForm.get('subdomain').valid && signUpForm.get('subdomain').dirty }">  

            <div *ngIf="signUpForm.get('subdomain').invalid && signUpForm.get('subdomain').dirty" 
                 style="color: coral; font-size: small;"
                 class="notification is-danger">
                 Este usuario ya está en uso. Prueba con otro.
            </div>
            <div *ngIf="signUpForm.get('subdomain').pending" 
                  style="color: whitesmoke; font-size: small;"
                  class="notification is-info">
              Comprobando disponibilidad del nombre...
            </div>
            <!-- <div class="input-group-append">
              <span class="input-group-text input-group-text-info" style="cursor: pointer; background: grey !important;" (click)="onClickCompanyFieldInfo()">
                <i class="fas fa-question-circle"></i>
              </span>
            </div> -->
            
            <!-- <p style="color: white; font-size: 0.5em;">
              <br/>
              Utilizaremos el nombre de tu empresa para habilitar una web desde la cual tus clientes podrán solicitar canciones. 
              Por ejemplo, si tu elección es "cocacola", tus clientes podrán pedir canciones desde la web "cocacola.paviado.com".
              <br/>
              Una vez completes el registro, pueden transcurrir 4 horas hasta que dicha web se encuentre disponible.
            </p> -->
          </div>

          <div class="input-group mb-3">
            <div class="input-group-append">
              <re-captcha 
                #captchaRef="reCaptcha"
                [siteKey] = "siteKey" 
                size = "invisible"
                (resolved)="$event && onSignUpFormSubmit($event)">
              </re-captcha>
            </div>
          </div>
          
        <div class="d-flex justify-content-center mt-3 login_container">
          <button name="button" class="btn login_btn"
                  [disabled]="!signUpForm.valid || !signUpForm.dirty"
                  (click)="captchaRef.execute()">
                  Crear cuenta
          </button>
        </div>
        </form>
      </div>
      
      <br>
      <div class="d-flex justify-content-center links">
        <a href="/admin/sign-in" style="color: aliceblue">Ya tengo cuenta</a>
      </div>
      
    </div>
  </div>
</div>


<!-- <ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Empresa: ¿Qué es este campo?</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Utilizaremos el nombre de tu empresa para habilitar una web desde la cual tus clientes podrán solicitar canciones. 
      Por ejemplo, si tu elección es "cocacola", tus clientes podrán pedir canciones desde la web "cocacola.paviado.com".
      <br/>
      Una vez completes el registro, pueden transcurrir 4 horas hasta que dicha web se encuentre disponible.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Cerrar</button>
  </div>
</ng-template> -->
