import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { SubdomainAvailableValidator } from './subdomain-available-validator';
import { NgfireHelperService } from 'src/app/shared/services/ngfire-helper.service';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  siteKey = environment.googleRecaptchaV3;
  signUpForm: FormGroup;


  constructor( private alog: NGXLogger,
               private router: Router,
               public authService: AuthService,
               public ngFireHelperService: NgfireHelperService ) { }

  ngOnInit() {
    this.signUpForm = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'password': new FormControl(null, [Validators.required]),
      'subdomain': new FormControl(null, [Validators.required], SubdomainAvailableValidator.checkSubdomainAvailability(this.ngFireHelperService)),
    });
  }

  onSignUpFormSubmit(captchaResponse: string) {
    //co.log('submit captchaResponse:', captchaResponse);

    if (this.signUpForm.valid) {

      let email = this.signUpForm.get('email').value;
      let password = this.signUpForm.get('password').value;
      let subdomain = this.signUpForm.get('subdomain').value;

      this.authService.signUp(email, password, subdomain)
        .then( (_) => {
          this.alog.debug('SignIn > onSignInFormSubmit success');
          this.router.navigate(['/admin']);
        })
        .catch( (err) => {
          this.alog.error('SignIn > onSignInFormSubmit - error', err);
        });
    }
    
  }

}
