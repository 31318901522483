import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducers';
import { Message } from 'src/app/shared/models/message.model';
import { User } from 'src/app/shared/models/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SubdomainService } from 'src/app/shared/services/subdomain.service';

import { CommerceService } from '../../../shared/services/commerce.service';
import { MessageService } from '../../../shared/services/message.service';

@Component({
  selector: 'app-chat-item',
  templateUrl: './chat-item.component.html',
  styleUrls: ['./chat-item.component.css']
})
export class ChatItemComponent implements OnInit, OnDestroy {

  // user: Observable<firebase.User>;
  user: User;
  currentUser: firebase.User;
  chatGroupId: string;

  // messages: Observable<any[]>;
  messages: Message[] = [];
  topics = '';
  value = '';

  isLoading: boolean = false;

  uiSubscription: Subscription = new Subscription();
  authSubscription: Subscription = new Subscription();
  messagesSubscription: Subscription = new Subscription();

  constructor( private alog: NGXLogger,
               private store: Store<AppState>,
               private authService: AuthService,
               private messageService: MessageService,
               private commerceService: CommerceService,
               private subdomainService: SubdomainService ) { }


  ngOnInit(): void {

    this.uiSubscription = this.store.select('ui')
      .subscribe( ui => {
        this.isLoading = ui.loadingItems.length > 0;
    });

    this.authSubscription = this.store.select('auth')
      .pipe(filter( (authState) => authState.user != null))
      .subscribe( authState => {
        this.user = authState.user;

        const commerceId = Object.keys(this.user.commerces.owns)[0];
        this.messageService.observeMessages(commerceId, this.chatGroupId); // this.user.uid
        this.messageService.saveMessagingDeviceToken(this.commerceService.commerceId, this.chatGroupId); //this.user.uid
    });

    this.messagesSubscription = this.store.select('messages')
      .subscribe( messagesState => {
        this.messages = messagesState.messages;
        this.alog.debug('Booking > ngOnInit - messagesState: ', messagesState.messages);

        // Make sure new message scroll into view
        setTimeout(() => {
          const messageList = document.getElementById('messages');
          messageList.scrollTop = messageList.scrollHeight;
          document.getElementById('message').focus();
        }, 500);
    });
  }

  // TODO: Refactor into text message form component
  update(value: string) {
    this.value = value;
  }

  onImageClick(event: any) {
    event.preventDefault();
    document.getElementById('mediaCapture').click();
  }

  // TODO: Refactor into text message form component
  saveMessage(event: any, el: HTMLInputElement) {
    event.preventDefault();

    if (this.value) {

      let sender: string = null;
      if (this.user.displayName) {
        sender = this.user.displayName
      }

      let message: Message = {
        id: '',
        text: this.value,
        sender: sender,
        photoUrl: this.user.photoURL,
        type: 'text',
        timestamp: +new Date()
      }

      this.messageService.pushMessage(this.commerceService.commerceId, this.chatGroupId, message)
        .then( () => {
          // Clear message text field and SEND button state.
          this.alog.debug('Booking > saveMessage - success');
          el.value = '';
        }, (err) => {          
          console.error(err);
        });
    }
  }



  // TODO: Refactor into image message form component
  /*
  saveImageMessage(event: any) {
    event.preventDefault();
    const file = event.target.files[0];

    // Clear the selection in the file picker input.
    const imageForm = <HTMLFormElement>document.getElementById('image-form');
    imageForm.reset();

    // Check if the file is an image.
    if (!file.type.match('image.*')) {
      this.snackBar.open('You can only share images', null, {
        duration: 5000
      });
      return;
    }

    // Check if the user is signed-in
    if (this.checkSignedInWithMessage()) {

      // We add a message with a loading icon that will get updated with the shared image.
      const messages = this.db.list('/messages');
      messages.push({
        name: this.currentUser.displayName,
        imageUrl: LOADING_IMAGE_URL,
        photoUrl: this.currentUser.photoURL || PROFILE_PLACEHOLDER_IMAGE_URL
      }).then((data) => {
        // Upload the image to Cloud Storage.
        const filePath = `/messages/${this.currentUser.uid}/${data.key}/${file.name}`;
        return firebase.storage().ref(filePath).put(file)
          .then((snapshot) => {
            // Get the file's Storage URI and update the chat message placeholder.
            const fullPath = snapshot.metadata.fullPath;
            const imageUrl = firebase.storage().ref(fullPath).toString();

            return firebase.storage().refFromURL(imageUrl).getMetadata();
          }).then((metadata) => {
            // TODO: Instead of saving the download URL, save the GCS URI and
            //       dynamically load the download URL when displaying the image
            //       message.
            return data.update({
              imageUrl: metadata.downloadURLs[0]
            });
          });
      }).then(conole.log, (err) => {
        this.snackBar.open('There was an error uploading a file to Cloud Storage.', null, {
          duration: 5000
        });
        console.error(err);
      });
    }
  }*/


  ngOnDestroy() {
    this.uiSubscription.unsubscribe();
    this.authSubscription.unsubscribe();
    this.messagesSubscription.unsubscribe();
    this.messageService.cancellSubscriptions();
  }

}
