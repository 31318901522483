import { Injectable } from '@angular/core';
import { Observable, EMPTY, from } from 'rxjs';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { SubdomainService } from '../../../shared/services/subdomain.service';
import { CommerceService } from '../../../shared/services/commerce.service';
import { NGXLogger } from 'ngx-logger';
import { JukeboxService } from 'src/app/shared/services/jukebox.service';



@Injectable()
export class SingleJukeboxResolver implements Resolve<any> {
 
  constructor( private alog: NGXLogger,
               private router: Router,
               public subdomainService: SubdomainService,
               public commerceService: CommerceService,
               public jukeboxService: JukeboxService ) { }
 
  /**
   * In case the user have a single jukebox, redirect to that URL
   * @param route 
   * @param state 
   */
  resolve( route: ActivatedRouteSnapshot,
           state: RouterStateSnapshot ): Promise<any> {

    this.alog.debug('SingleJukeboxResolver > resolve');

    if ( this.subdomainService.subdomain?.jukeboxes_parties ) {

      const jukeboxesId: string[] = Object.keys(this.subdomainService.subdomain?.jukeboxes_parties);
      this.alog.debug('SingleJukeboxResolver > resolve - jukeboxesId.length', jukeboxesId.length);
      this.alog.debug('SingleJukeboxResolver > resolve - jukeboxesId', jukeboxesId);
      if ( jukeboxesId.length === 1 ) {
        return this.router.navigate([ '', this.subdomainService.subdomain.id, 'jukebox', jukeboxesId[0] ]);
      }
    }

    return Promise.resolve();
  }

}