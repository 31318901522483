import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { AdminService } from 'src/app/shared/services/admin.service';

import { AppState } from '../../app.reducers';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.css']
})
export class AdminLayoutComponent implements OnInit, OnDestroy {

  showSidebar: boolean = false;

  toggleSidebarSubscription: Subscription = new Subscription();

  constructor( private alog: NGXLogger, 
               private store: Store<AppState>,
               private adminService: AdminService ) { }

  ngOnInit() {

    this.adminService.initAdminListener();
    
    this.toggleSidebarSubscription =  this.store.select('ui')
        .subscribe( ui => {
          this.showSidebar = ui.showSidebar;
        });
  }


  /*********************
   *** LIFECYCLE   ****
   *********************/

  ngOnDestroy() {
    this.toggleSidebarSubscription.unsubscribe();
  }

}
