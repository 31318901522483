import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducers';
import { Commerce } from 'src/app/shared/models/commerce.model';
import { User } from 'src/app/shared/models/user.model';
import { FeedbackService } from 'src/app/shared/services/feedback.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { NgfireHelperService } from 'src/app/shared/services/ngfire-helper.service';
import { SubdomainService } from 'src/app/shared/services/subdomain.service';
import { UIService } from 'src/app/shared/services/ui.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit, OnDestroy {

  user: User;
  siteKey = environment.googleRecaptchaV3;
  feedbackForm: FormGroup;
  currentRate: number;
  commerce: Commerce;
  isLoading: boolean = true;
  isFeedbackMessageSent: boolean = false;

  private uiSubscription$: Subscription = new Subscription();
  private authSubscription: Subscription = new Subscription();
  private commerceSubscription$: Subscription = new Subscription();

  constructor(
    private log: NGXLogger,
    private store: Store<AppState>,
    private uiService: UIService,
    private feedbackService: FeedbackService,
    private localStorageService: LocalStorageService,
    public subdomainService: SubdomainService,
    public ngFireHelperService: NgfireHelperService
  ) { }

  ngOnInit() {

    this.uiSubscription$ = this.store.select('ui')
      .subscribe( ui => {
        this.isLoading = ui.loadingItems.length > 0;
    });

    this.authSubscription = this.store.select('auth')
      .subscribe( authState => {
        this.log.debug('Navbar > ngOnInit authState: ', authState);
        this.user = authState.user;
    });

    this.feedbackForm = new FormGroup({
      'message': new FormControl(null, [Validators.required]),
    });

    this.currentRate = this.localStorageService.getCommerceUserFeedbackRating();

    this.subscribeCommerce();    
  }

  ngOnDestroy() {
    this.uiSubscription$.unsubscribe();
    this.commerceSubscription$.unsubscribe();
  }

  async onFeedbackFormSubmit(captchaResponse: string) {
    this.log.debug('Feedback > onFeedbackFormSubmit - captchaResponse, this.feedbackForm, this.currentRate,', captchaResponse, this.feedbackForm, this.currentRate);

    if (this.feedbackForm.valid) {

      let message = this.feedbackForm.get('message').value;
      this.localStorageService.setCommerceUserFeedbackRating(this.currentRate);

      try {
        await this.feedbackService.pushFeedbackMessage(this.commerce.id, message, this.currentRate, this.user);
        this.uiService.showSuccess('Hemos guardado tu mensaje');
        this.isFeedbackMessageSent = true;
      
      } catch (err) {
        this.uiService.showError('Se ha producido un error. Vuelve a intentarlo mas tarde');
      }

    }
  }

  subscribeCommerce() {
    this.commerceSubscription$ = this.store.select('commerces')
      .pipe(filter( (commerceState) => commerceState.commerce != null))
      .subscribe( commerceState => {
        this.log.debug('Feedback > subscribeCommerce - commerceState.commerce:', commerceState.commerce);
        this.commerce = commerceState.commerce;
    });
  }


  onClickRate() {
    // this.alreadyClickRate = true; 
  }


}
