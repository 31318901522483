import { NgModule } from '@angular/core';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { RouterModule, Routes } from '@angular/router';

import { AdminLayoutComponent } from './admin/admin-layout/admin-layout.component';
import { SpotifyAuthCodeComponent } from './admin/spotify/spotify-callback/spotify-auth-code/spotify-auth-code.component';
import { SpotifyCallbackComponent } from './admin/spotify/spotify-callback/spotify-callback.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { VerifyEmailComponent } from './auth/verify-email/verify-email.component';
import { AuthAnonymousGuard } from './shared/guard/auth-anonymous.guard';
import { AuthGuard } from './shared/guard/auth.guard';
import { SecureInnerPagesGuard } from './shared/guard/secure-inner-pages.guard';
import { JukeboxResolver } from './users/shared/resolvers/jukebox-resolver.resolver';
import { JukeboxWithoutCommerceResolver } from './users/shared/resolvers/jukebox-without-commerce-resolver.resolver';
import { MessagesResolver } from './users/shared/resolvers/messages-resolver.resolver';
import { SingleJukeboxResolver } from './users/shared/resolvers/single-jukebox-resolver.resolver';
import { SubdomainResolver } from './users/shared/resolvers/subdomain-resolver.resolver';
import { UsersLayoutComponent } from './users/users-layout/users-layout.component';

const routes: Routes = [

  // Section: mi.paviado.com
  { path: 'admin/sign-in', component: SignInComponent, canActivate: [SecureInnerPagesGuard] },
  { path: 'admin/sign-up', component: SignUpComponent, canActivate: [SecureInnerPagesGuard] },
  { path: 'admin/forgot-password', component: ForgotPasswordComponent, canActivate: [SecureInnerPagesGuard] },

  { path: 'admin/verify-email-address', component: VerifyEmailComponent, canActivate: [AuthGuard] },  
  { path: 'login/spotify/callback', component: SpotifyCallbackComponent, canActivate: [AuthGuard] },  
  { path: 'login/spotify/callback-auth-code', component: SpotifyAuthCodeComponent, canActivate: [AuthGuard] },  

  { path: 'admin', 
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
  },

  { path: '', 
    component: UsersLayoutComponent, 
    canActivate: [AuthAnonymousGuard],
    loadChildren: () => import('./users/users.module').then((m) => m.UsersModule),      
  },
  
  // TODO Redirect to 404 page ?
  { path: '**', redirectTo: '', pathMatch: 'full' },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    AngularFireAuthGuardModule,
  ],
  providers: [
    JukeboxResolver,
    MessagesResolver,
    SubdomainResolver,
    SingleJukeboxResolver,
    JukeboxWithoutCommerceResolver
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }