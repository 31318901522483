import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgbAccordionModule, NgbButtonsModule, NgbPopoverModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { JoyrideModule } from 'ngx-joyride';

import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { AdminRoutingModule } from './admin-routing.module';
import { ChatItemComponent } from './booking/chat-item/chat-item.component';
import { ChatListComponent } from './booking/chat-list/chat-list.component';
import { ChatPanelComponent } from './booking/chat-panel/chat-panel.component';
import { MenuImagesComponent } from './commerce/menu-images/menu-images.component';
import { HomeAdminComponent } from './home/home-admin.component';
import { JukeboxMusicComponent } from './jukebox-config/jukebox-music/jukebox-music.component';
import { JukeboxConfigComponent } from './jukebox-config/jukebox-settings/jukebox-config.component';
import { JukeboxTracksComponent } from './jukebox-config/jukebox-tracks/jukebox-tracks.component';
import { TrackItemAdminComponent } from './jukebox-config/jukebox-tracks/track-item-admin/track-item-admin.component';
import { HtmlEditorComponent } from './jukebox-config/mail-campaign/html-editor.component';
import { PromoCodeItemComponent } from './jukebox-config/promo-codes/promo-code-item/promo-code-item.component';
import { PromoCodesManagerComponent } from './jukebox-config/promo-codes/promo-codes-manager/promo-codes-manager.component';
import { PromoCodesComponent } from './jukebox-config/promo-codes/promo-codes.component';
import { PromoImagesComponent } from './jukebox-config/promo-images/promo-images.component';
import { LeadsDownloadComponent } from './leads-download/leads-download.component';
import { ProfileComponent } from './profile/profile.component';
import { SaleItemComponent } from './sale-list/sale-item/sale-item.component';
import { SaleListComponent } from './sale-list/sale-list.component';
import { FooterAdminComponent } from './shared/footer-admin/footer-admin.component';
import { NavbarAdminComponent } from './shared/navbar-admin/navbar-admin.component';
import { SidebarAdminComponent } from './shared/sidebar-admin/sidebar-admin.component';
import { PlaylistsComponent } from './spotify/playlists/playlists.component';
import { SpotifyAuthCodeComponent } from './spotify/spotify-callback/spotify-auth-code/spotify-auth-code.component';
import { SpotifyCallbackComponent } from './spotify/spotify-callback/spotify-callback.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { LoadingComponent } from './shared/loading/loading.component';


@NgModule({
  declarations: [
    AdminLayoutComponent,
    ProfileComponent,
    SpotifyCallbackComponent,
    PlaylistsComponent,
    JukeboxTracksComponent,
    PromoCodesComponent,
    FooterAdminComponent,
    JukeboxConfigComponent,
    TrackItemAdminComponent,
    PromoCodeItemComponent,
    PromoImagesComponent,
    NavbarAdminComponent,
    SidebarAdminComponent,
    HomeAdminComponent,
    JukeboxMusicComponent,
    StatisticsComponent,
    SaleListComponent,
    SaleItemComponent,
    PromoCodesManagerComponent,
    SpotifyAuthCodeComponent,
    HtmlEditorComponent,
    ChatPanelComponent,
    ChatListComponent,
    ChatItemComponent,
    MenuImagesComponent,
    LeadsDownloadComponent,
    LoadingComponent,
  ],

  imports: [
    CommonModule,    
    AdminRoutingModule,
    JoyrideModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgbTabsetModule,
    NgbPopoverModule,
    NgbButtonsModule,
    NgbAccordionModule,
    HttpClientModule, 
    AngularEditorModule,
    NgxChartsModule
  ],
  exports: [RouterModule]

})
export class AdminModule { }
