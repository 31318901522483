import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';

import { AppConfigService } from '../../shared/services/app-config.service';

@Component({
  selector: 'app-users-layout',
  templateUrl: './users-layout.component.html',
  styleUrls: ['./users-layout.component.css']
})
export class UsersLayoutComponent implements OnInit, OnDestroy {

  showSidebar: boolean = false;

  constructor(private log: NGXLogger, 
              private router: Router,
              private appConfigService: AppConfigService) { }

  ngOnInit() {

    if ( this.appConfigService.getSubdomain() == 'mi' ) {      
      this.router.navigateByUrl('/admin');
    }

  }

  ngOnDestroy() {
  }

}
