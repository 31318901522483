


export class User {
  
  uid: string;
  email: string;
  displayName?: string;
  photoURL: string;
  emailVerified: boolean;
  role?: string;
  roleAccessRestrictions?: string;
  provider_data?: {
    google: any,
    facebook: any,
    twitter: any,
    spotify: any,
    paviado: any
  }
  providerData?: any;

  jukeboxes_parties?: any[]; // TODO Asignar tipo datos
  parties_jukeboxes?: any[];

  commerces?: {
    owns: { key: string, value: string }[],
  }

  isAdminTourCompleted?: boolean;

  subdomain?: string;
  subscription?: string; // free / basic

  constructor( obj: objUser ) {
    this.displayName                =  obj && obj.displayName || null;
    this.email                      =  obj && obj.email || null;
    this.uid                        =  obj && obj.uid || null;  
    this.displayName                =  obj && obj.displayName || null;  
    this.emailVerified              =  obj && obj.emailVerified || null;  
    this.role                       =  obj && obj.role || null;  
    this.roleAccessRestrictions     =  obj && obj.roleAccessRestrictions || null;  
    this.jukeboxes_parties          =  obj && obj.jukeboxes_parties || null;  
    this.parties_jukeboxes          =  obj && obj.parties_jukeboxes || null;  

    this.isAdminTourCompleted       =  obj && obj.isAdminTourCompleted || null;  

    this.subdomain                  =  obj && obj.subdomain || null;
    this.subscription               =  obj && obj.subscription || null;
  }
}


interface objUser {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
  role: string;
  roleAccessRestrictions: string;
  jukeboxes_parties: any[];
  parties_jukeboxes: any[];

  isAdminTourCompleted: boolean;

  subdomain: string;
  subscription: string;
}