import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { AppState } from 'src/app/app.reducers';
import { Sale } from 'src/app/shared/models/sale.model';
import { NgfireHelperService } from 'src/app/shared/services/ngfire-helper.service';
import { LoadingAddAction, LoadingRemoveAction, LOADING_ITEM_ADMIN_STATISTICS } from 'src/app/shared/redux/actions/ui.actions';
import LeadValidator from '../helpers/lead-validator.helper';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {
  
  sales: Sale[] = [];
  salesPerDay: Map<string, number> = new Map<string, number>();
  salesPerPlatform: Map<string, number> = new Map<string, number>();
  topArtistsMap: Map<string, number> = new Map();

  constructor(private log: NGXLogger,
              private store: Store<AppState>,
              private ngFireService: NgfireHelperService) { }

  
  async getSales(partiesId: string, dateStart: string, dateEnd: string) {
    this.log.debug('StatisticsService > getSales');
    this.store.dispatch( new LoadingAddAction(LOADING_ITEM_ADMIN_STATISTICS) );

    this.sales = await this.ngFireService.getSalesData(partiesId, dateStart, dateEnd);

    this.resetStatisticsValues();
    this.processStatisticsData();

    this.salesPerDay = this.orderMapByKey(this.salesPerDay, false);
    this.salesPerPlatform = this.orderMapByKey(this.salesPerPlatform, true);
    this.topArtistsMap = this.orderMapByValue(this.topArtistsMap, false);

    this.store.dispatch( new LoadingRemoveAction(LOADING_ITEM_ADMIN_STATISTICS) );
  }


  resetStatisticsValues() {

    this.topArtistsMap.clear;
    this.salesPerPlatform.clear;
    this.salesPerPlatform.set(LeadValidator.LEAD_TABLET_NO_DATA, 0);
    this.salesPerPlatform.set(LeadValidator.LEAD_TABLET_MAIL_OK, 0);
    this.salesPerPlatform.set(LeadValidator.LEAD_TABLET_MAIL_KO, 0);
    this.salesPerPlatform.set(LeadValidator.LEAD_WEB_MAIL_OK, 0);
    this.salesPerPlatform.set(LeadValidator.LEAD_WEB_MAIL_KO, 0);

    // Reset Sales per day Map
    this.salesPerDay.clear;
    if (this.sales.length > 0) {
      // From first request till last one
      const dateStartM = moment(this.sales[0].date_transaction); 
      const dateEndM = moment(this.sales[this.sales.length - 1].date_transaction); 
      const totalDays: number = dateEndM.diff(dateStartM, 'days');
      this.log.debug('StatisticsService > resetStatisticsValues - totalDays: ', totalDays);
  
      for (let i = 0; i < totalDays; i++) {
        const dateAdd = dateStartM.clone();
        dateAdd.add(i, 'days')
        this.salesPerDay.set(dateAdd.format('YYYY-MM-DD'), 0);
      }
    }
  }

  /**
   * 
   */
  processStatisticsData() {
    // Add sales
    this.sales.forEach(iSale => {
      this.groupSalesPerDay(iSale);
      this.groupArtistTrackRequests(iSale);
      this.groupSalesPerPlatform(iSale);
      // this.groupSalesPerPlatform(iSale);
      // this.groupLeadsRequests(iSale);
    });
  }

  /**
   * Group per platform and lead status. Can be:
   * - Tablet: Request with no data
   * - Tablet: Request with false data
   * - Tablet: Request with correct mail
   * - Web: Request with fake mail
   * - Web: Request with correct mail
   * @param iSale 
   */
  groupSalesPerPlatform(iSale: Sale) {
    if (iSale.lead) {
      let leadStatus: string = 'Unknown';
      if (iSale.source === 'tablet') {
        if (iSale.lead.isLeadWithoutData) {
          leadStatus = 'Tablet: Sin datos';
        } else {
          if (LeadValidator.isRealEmail(iSale.lead.shortText)) {
            leadStatus = 'Tablet: Mail Ok';
          } else {
            leadStatus = 'Tablet: Mail Inválido';
          }
        }
      } else { // web
        if (LeadValidator.isRealEmail(iSale.lead.shortText)) {
          leadStatus = 'Web: Mail Ok';
        } else {
          leadStatus = 'Web: Mail Inválido';
        }
      }

      this.groupInMap(this.salesPerPlatform, leadStatus);
    }
  }

  /**
   * 
   * @param iSale 
   */
  groupArtistTrackRequests(iSale: Sale) {
    if (iSale.song?.artists) {        
      this.groupInMap(this.topArtistsMap, iSale.song.artists);
    }
  }

  /**
   * 
   * @param iSale 
   */
  groupSalesPerDay(iSale: Sale) {
    if (iSale.date_transaction) {
      // We minus 6h to identify sunday 2am as saturday
      const saleDate: string = moment(iSale.date_transaction).subtract(6, 'hours').format('YYYY-MM-DD'); 
      this.groupInMap(this.salesPerDay, saleDate);
    }
  }

  orderMapByKey(map: Map<any, any>, reverse: boolean): Map<any, any> {
    if (reverse) {
      return new Map([...map.entries()].reverse());
    }
    return new Map([...Array.from(map.entries())].sort((a, b) => a[0] > b[0] ? 1 : -1));
    // return new Map([...map.entries()].sort());
  }

  orderMapByValue(map: Map<any, any>, reverse: boolean): Map<any, any> {
    if (reverse) {
      return new Map([...map.entries()].sort((a, b) => a[1] - b[1]));
    }
    return new Map([...map.entries()].sort((a, b) => b[1] - a[1]));
  }

  groupInMap(map: Map<any, any>, key: any) {
    if (key) {        
      if ( map.get(key) ) {
        map.set(key, map.get(key) + 1);
        
      } else {
        map.set(key, 1);      
      }
    }
  }

}
