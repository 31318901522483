import { Component, OnInit, OnDestroy } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducers';
import { JukeboxService } from 'src/app/shared/services/jukebox.service';
import { SubdomainService } from '../../shared/services/subdomain.service';
import { Subscription } from 'rxjs';
import { Commerce } from 'src/app/shared/models/commerce.model';
import { filter } from 'rxjs/operators';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { MenuImage } from '../../shared/models/commerce.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit, OnDestroy {

  isLoading: boolean = false;

  commerce: Commerce;
  menuFilter: string;
  menuImages: MenuImage[] = [];
  menuImagesActive: MenuImage[] = [];
  
  uiSubscription: Subscription = new Subscription();
  commerceSubscription: Subscription = new Subscription();

  constructor( private log: NGXLogger,
               private store: Store<AppState>,
               private jukeboxService: JukeboxService,
               private activatedRoute: ActivatedRoute,
               private subdomainService: SubdomainService,
               private ngbCarouselConfig: NgbCarouselConfig ) { }

  ngOnInit(): void {
    
    this.uiSubscription = this.store.select('ui')
      .subscribe( ui => {
        this.isLoading = ui.loadingItems.length > 0;
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.menuFilter = params['filter'];
      this.log.debug('MenuComponent > ngOnInit - filter:', this.menuFilter);
      this.manageMenuImages();
    });

    this.ngbCarouselConfig.interval = 50000;
    this.ngbCarouselConfig.wrap = true;
    this.ngbCarouselConfig.keyboard = false;
    this.ngbCarouselConfig.pauseOnHover = true;

    this.subscribeCommerce();
  }

  subscribeCommerce() {
    this.commerceSubscription = this.store.select('commerces')
      .pipe(filter( (commerceState) => commerceState.commerce != null))
      .subscribe( commerceState => {
        this.log.debug('MenuComponent > subscribeCommerce commerceState.commerce:', commerceState.commerce);
        this.commerce = commerceState.commerce;
        this.menuImages = commerceState.commerce.features?.menu?.default?.menu_images;
        this.manageMenuImages();
        this.log.debug('MenuComponent > subscribeCommerce - this.menuImages:', this.menuImages);
    });
  }

  manageMenuImages() {
    this.menuImagesActive = [];
    this.menuImagesActive = Object.values(this.menuImages).filter(iMenuImage => {
      if (iMenuImage.isActive) {
        if (!this.menuFilter) {
          return true;
        } else if (iMenuImage.tags?.toLocaleLowerCase().includes(this.menuFilter.toLocaleLowerCase())) {
          return true;
        }
      }
      return false;
    });
  }

  ngOnDestroy() {
    this.uiSubscription.unsubscribe();
    this.commerceSubscription.unsubscribe();
  }

}
