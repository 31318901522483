


<div *ngIf="isLoading || !jukeboxConfiguration" class="container text-center">
  <br><br>
  <div class="spinner-border" role="status" style="color: purple">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div *ngIf="!isLoading && jukeboxConfiguration">
  <br>
  <!-- Filter over playlist -->
  <div *ngIf="!jukeboxConfiguration.isSongSearchOpen">
    <div class="container">  
      <div class="row">
        <div class="col-sm-8 offset-sm-2 text-center">
          <input 
            class="input px-3 search-input border-0 collapsed"
            type="text" 
            style="width: 100%;" 
            maxlength="64"
            placeholder="Filtrar sobre {{ this.jukeboxConfiguration.displayName }}"
            [(ngModel)] = "searchText">
        </div>
      </div>
    </div>
  </div>

  <!-- Search any track on Spotify -->
  <div *ngIf="jukeboxConfiguration.isSongSearchOpen">
    <div class="container text-center">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12">
          <form [formGroup]="searchSongForm"
                (ngSubmit)="onRequestSongFormSubmit()">

            <input 
              class="form-control" 
              type="text"                     
              maxlength="64"
              formControlName="songName"
              placeholder="Busca aqui el nombre de tu canción">
          </form>
        </div>
      </div>
    </div>
  </div>

  <hr>

  <!-- Fallback playlist -->
  <div *ngIf="!searchSongForm.get('songName').value"
    class="container">
      <div class="row text-center">
        <div class="col-xs-2">    
          <app-song-item 
            *ngFor="let iTrack of jukeboxService.playlistTracks | songFilter:searchText; let i = index"
            [track]="iTrack">
          </app-song-item>
        </div>
      </div>
  </div>

  <!-- Search track results -->
  <div *ngIf="searchSongForm.get('songName').value"
    class="container">
      <div class="row text-center">

        <div *ngIf="isSearching"
              class="d-flex justify-content-center">
          <br>
          <div class="spinner-border" role="status" style="color: purple">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <div *ngIf="!isSearching"
              class="col-xs-2">    
          <app-song-item 
            *ngFor="let iTrack of jukeboxService.searchOpenTracks"
            [track]="iTrack">
          </app-song-item>
        </div>
      </div>
  </div>

  <!-- Go up nav button -->
  <div style ="position: fixed; bottom:50px; right:14px; z-index:1;">
    <button (click)="onClickScrollUp()"
      type="button" class="btn btn-primary btn-circle btn-lg"><i class="fas fa-arrow-up"></i>
    </button>
  </div>

  <hr>

  <!-- Suggest tracks to add playlist -->
  <div *ngIf="!jukeboxConfiguration.isSongSearchOpen">
    <div class="container text-center">
      <div class="row">
          <div class="col-12 col-sm-12 col-md-12">
              <form [formGroup]="requestSongForm"
                    (ngSubmit)="onRequestSongFormSubmit()">

                <input class="form-control" 
                  type="text" 
                  id="songName"
                  maxlength="64"
                  formControlName="songName"
                  placeholder="¿No encuentras tu cancion?, Pídela aquí al propietario">

                <button type="submit" name="" id="" class="btn btn-primary">
                  Solicitar
                </button>
              </form>
          </div>
      </div>
    </div>
  </div>

  <hr>

</div>
