


<header class="header">
  <nav class="navbar">
    <div class="container-fluid">
      <div class="navbar-holder d-flex align-items-center justify-content-between">
        <div class="navbar-header">
          <a id="toggle-btn" (click)="toggleSidebar()" class="menu-btn">
            <i class="fas fa-bars fa-2x"></i>
          </a>
          <a (click)="toggleSidebar()" class="navbar-brand">
            <div class="brand-text d-none d-md-inline-block">
              <strong class="text-primary"></strong>
            </div>
          </a>
        </div>
        <div class="navbar-nav mr-auto" style="display: inline-block;">
          <a href="https://wa.me/34647145521" target="_blank" rel="noopener noreferrer"><i class="fab fa-whatsapp" style="padding: 10px;"></i></a>
          <a href="mailto:hola@paviado.com?Subject=Ayuda"><i class="far fa-envelope" style="padding: 10px;"></i></a>
        </div>
        <ul class="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">
          
          <li *ngIf="jukeboxes.length > 1" joyrideStep="step-2" title="Tus Paviados" text="Con este desplegable podrás cambiar entre tus diferentes dipositivos"
              (click)="toggleJukeboxList()"
              class="nav-item dropdown" 
              [ngClass]="{ 'show': isJukeboxListExpanded }">

            <a id="languages" rel="nofollow"
               data-target="#" 
               data-toggle="dropdown" aria-haspopup="true" 
               aria-expanded="false" class="nav-link dropdown-toggle language cursor">
              <span class="d-none d-sm-inline-block">
                {{ jukeboxActive | truncate: 16 }}
              </span>
            </a>
            <ul *ngIf="user"
                aria-labelledby="languages" 
                class="dropdown-menu" 
                [ngClass]="{ 'show': isJukeboxListExpanded }">

              <li *ngFor="let jukebox of jukeboxes">
                <a (click)="onJukeboxSelect(jukebox.jukebox_data.id)"
                   rel="nofollow" 
                   class="dropdown-item"> 
                  <span>{{ jukebox.jukebox_configuration.displayName | truncate: 50 }}</span>
                </a>
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <a (click)="signOut()" class="nav-link logout" style="cursor: pointer; "> 
              <span class="d-none d-sm-inline-block">Cerrar Sesión</span><i class="fas fa-sign-out-alt"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>