import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { take, filter } from 'rxjs/operators';
import { SpotifyUser } from '../../../shared/models/spotify-user.model';
import { AppState } from '../../../app.reducers';
import { SpotifyService } from '../../../shared/services/spotify.service';
import { AdminService } from '../../../shared/services/admin.service';
import { JukeboxData } from '../../../shared/models/jukebox.model';

@Component({
  selector: 'app-jukebox-music',
  templateUrl: './jukebox-music.component.html',
  styleUrls: ['./jukebox-music.component.css']
})
export class JukeboxMusicComponent implements OnInit {

  isLoading: boolean;
  jukeboxData: JukeboxData;

  songRequests: string[] = [];
  loadedPlaylist: string = 'cargando...';
  isSpotifyAuthenticated: boolean = false;
  spotifyUser: SpotifyUser = new SpotifyUser();

  uiSubscription: Subscription = new Subscription();
  jukeboxDataSubscription: Subscription = new Subscription();

  constructor( private alog: NGXLogger,
               private store: Store<AppState>,
               private spotifyService: SpotifyService,
               private adminService: AdminService ) { }

  ngOnInit() {
    // TODO Add loading
    this.uiSubscription = this.store.select('ui')
      .subscribe( uiState => {
        this.isLoading = uiState.loadingItems.length > 0;          
    });

    this.jukeboxDataSubscription = this.store.select('jukeboxData')
      .pipe(
        filter( jukeboxState => jukeboxState.jukeboxData != null)
      )
      .subscribe( jukeboxState => {
        this.jukeboxData = jukeboxState.jukeboxData;
        this.getPlaylistLoaded(this.jukeboxData.playlistsId);
        this.getSongRequests(this.jukeboxData.partiesId);
      });

    //Spotify Auth
    this.isSpotifyAuthenticated = this.spotifyService.isAuthenticated();
    if (this.isSpotifyAuthenticated) {
      this.getSpotifyProfile();
    }
  }

  /**
   * 
   */
  authWithSpotify() {
    this.spotifyService.authorize();
  }

  /**
   * 
   */
  closeSessionSpotify() {
    // TODO Close spotify session
    // localStorage.setItem('spotify_token', access_token);
    // localStorage.setItem('expires_in', expires_in);
    // localStorage.setItem('expiration_time', expiration_time.toString());
  }


  /**
   * 
   */
  private getSpotifyProfile() {

    this.spotifyService.getProfile()
      .pipe(take(1))
      .subscribe(
        (response: any) => {
          localStorage.setItem('user_id', response.id);
          this.spotifyUser = response;

          this.alog.debug('jukebox-music/getSpotifyProfile spotifyUser', this.spotifyUser);
        },
        (err) => { 
          this.alog.error('jukebox-music/getSpotifyProfile err', err);
        }
    );
  }

  /**
   * 
   * @param playlistsId 
   */
  private getPlaylistLoaded(playlistsId: string) {

    this.adminService
        .getPlaylistLoaded(playlistsId)        
        .then( (playlistName) => {
          this.alog.debug('jukebox-music.getPlaylistLoaded playlist:', playlistName);
          this.loadedPlaylist = playlistName ? playlistName : 'Ninguna';
        })
        .catch( (err) => {
          this.alog.error('jukebox-music.getPlaylistLoaded err', err);
        })
        .finally( () => {
        });
  }

  /**
   * 
   * @param partiesId 
   */
  private getSongRequests(partiesId: string) {

    this.adminService
        .getSongRequests(partiesId)        
        .then( (songRequests: string[]) => {
          this.alog.debug('jukebox-music.getSongRequests partiesId:', songRequests);
          this.songRequests = songRequests.reverse();
        })
        .catch( (err) => {
          this.alog.error('jukebox-music.getSongRequests err', err);
        })
        .finally( () => {
        });
  }

  ngOnDestroy() {
    this.uiSubscription.unsubscribe();    
    this.jukeboxDataSubscription.unsubscribe();    
  }

}
