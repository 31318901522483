import { Component, OnInit, OnDestroy } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Router, ActivatedRoute, NavigationEnd, UrlSegment } from '@angular/router';
import { AppState } from 'src/app/app.reducers';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Commerce } from 'src/app/shared/models/commerce.model';
import { filter } from 'rxjs/operators';
import { MenuImage } from '../../shared/models/commerce.model';

@Component({
  selector: 'app-commerce-info',
  templateUrl: './commerce-info.component.html',
  styleUrls: ['./commerce-info.component.css']
})
export class CommerceInfoComponent implements OnInit, OnDestroy {

  isLoading: boolean = false;
  commerce: Commerce;
  menuImages: MenuImage[] = [];
  uiSubscription: Subscription = new Subscription();
  commerceSubscription: Subscription = new Subscription();

  constructor(private alog: NGXLogger,
              private store: Store<AppState>) { }

  ngOnInit(): void {

    this.uiSubscription = this.store.select('ui')
      .subscribe( ui => {
        this.isLoading = ui.loadingItems.length > 0;
    });

    this.subscribeCommerce();
  }

  subscribeCommerce() {
    this.commerceSubscription = this.store.select('commerces')
      .pipe(filter( (commerceState) => commerceState.commerce != null))
      .subscribe( commerceState => {
        this.alog.debug('CommerceInfo > subscribeCommerce commerceState.commerce:', commerceState.commerce);
        this.commerce = commerceState.commerce;
        // FIXME If select commerce > go back > select other commerce. Keeps data of previous one (subscription)
        this.menuImages = [];
        if (commerceState.commerce.features?.menu?.default?.menu_images) {
          this.menuImages = Object.values(commerceState.commerce.features.menu.default.menu_images)
            .filter(iMenuImage => {
              return iMenuImage.isActive
          });
        }
        this.alog.debug('CommerceInfo > subscribeCommerce this.menuImages.length:', this.menuImages.length);
    });
  }


  ngOnDestroy() {
    this.uiSubscription.unsubscribe();
    this.commerceSubscription.unsubscribe();
  }

}
