import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as firebase from 'firebase';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/app.reducers';
import { SetUserAction } from 'src/app/shared/redux/actions/auth.actions';
import { environment } from 'src/environments/environment';

import { Subdomain } from '../models/subdomain.model';
import { User } from '../models/user.model';
import { UnsetUserAction } from '../redux/actions/auth.actions';
import { NgfireHelperService } from './ngfire-helper.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  
  user: User;
  fbUserAnonymous: firebase.auth.UserCredential;

  private userSubscription: Subscription = new Subscription();

  readonly PATH_USERS: string = "users";

  URL_SALES: string;
  URL_SONGS_QUEUE: string;

  supportedPopupSignInMethods = [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.TwitterAuthProvider.PROVIDER_ID,
  ];

  constructor( 
    private log: NGXLogger,
    private router: Router,  
    private store: Store<AppState>,
    private afAuth: AngularFireAuth,
    private ngFire: NgfireHelperService,
    private afDatabase: AngularFireDatabase 
  ) {    
    // Saving user data in localstorage when logged in and setting up null when logged out
    this.initAuthListener();
  }

  /**
   * 
   */
  initAuthListener() {
    this.log.debug('AuthService > initAuthListener');

    this.afAuth.authState
      .subscribe( (fbUser: firebase.User) => {

        if (fbUser) {
          this.log.debug('AuthService > initAuthListener - fbUser:', fbUser);
          localStorage.setItem('user', JSON.stringify(fbUser));
          
          if (fbUser.isAnonymous === false) {
            this.log.debug('AuthService > initAuthListener - fbUser.isAnonymous:', fbUser.isAnonymous);
            this.subscribeUser(fbUser);
          }

        } else {
          this.user = null;
          localStorage.setItem('user', null);
          this.userSubscription.unsubscribe();
        }
      });
  }


  subscribeUser(fbUser: firebase.User) {
    this.userSubscription.unsubscribe();
    this.userSubscription = 
      this.afDatabase.object(this.PATH_USERS + `/${fbUser.uid}`)
        .valueChanges()
        .subscribe( (user: User) => {
          this.log.debug('initAuthListener > userSubscription > usuarioObj:', user);
          // If dont exists, set initial user data
          this.store.dispatch( new SetUserAction(user) );
          this.user = user;
          // this.updateProvider(fbUser, user);
          // var userFbPaviado = {...usuarioObj, ...fbUser};
          // localStorage.setItem('user', JSON.stringify(userFbPaviado));
        });
  }
  
  /**
   * Sign in anonymously with Firebase
   * @returns Promise<any>
   */
  async signInAnonymously(): Promise<any> {
    this.log.debug('AuthService > signInAnonymously');

    try {
      this.fbUserAnonymous = await this.afAuth.auth.signInAnonymously();
      this.log.debug('AuthService > signInAnonymously - this.fbUserAnonymous: ', this.fbUserAnonymous);
      return Promise.resolve();
    } catch (error) {
      this.log.error('AuthService > SignInAnonymously - error', error);
    }
    return Promise.reject();
  }

  // isAnonymousUser(): boolean {
  //   return this.au
  // }
  
  /**
   * Sign in with email/password
   * @param email 
   * @param password 
   */
  signIn(email, password) {

    return this.afAuth.auth
      .signInWithEmailAndPassword(email, password)
      .then( (fbUser) => {
        // localStorage have to be set on very begining so its read on Guard
        localStorage.setItem('user', JSON.stringify(fbUser));
        // WARNING! Esta seccion sobreescribe valor de BD  
        const updatedUser: User = {
          uid: fbUser.user.uid,
          email: fbUser.user.email,
          photoURL: fbUser.user.photoURL,
          emailVerified: fbUser.user.emailVerified
        }
        this.ngFire.updateUser(fbUser.user.uid, updatedUser);
      })
      .catch((error) => {
        this.log.error('AuthService > SignIn: error', error);
        window.alert(error.message);
      });
  }

  signInWithGoogle() {
    this.log.debug('AuthService > signInWithGoogle');
    this.afAuth.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then( async (fbUser: firebase.auth.UserCredential) => {
        this.log.debug('AuthService > signInWithGoogle - fbUser: ', fbUser);
        // Check user exists, If not exists first install
        const firebaseDbUser = await this.ngFire.getUser(fbUser.user.uid);
        if (!firebaseDbUser) {
          this.setInitialUserData(fbUser, fbUser.user.uid.substring(0, 6));
          
        } else {
          if ( !firebaseDbUser.provider_data?.google ) {
            this.updateProviderInfo(fbUser);
          }
        }
      })
      .catch((error) => {
        this.log.error('AuthService > signInWithGoogle - error: ', error);
        window.alert(error.message);
      });
  }

  async signInWithFacebook() {
    this.log.debug('AuthService > signInWithFacebook');
    this.oauthLogin(new firebase.auth.FacebookAuthProvider());    
  }

  signInWithTwitter() {
    this.log.debug('AuthService > signInWithTwitter');
    this.oauthLogin(new firebase.auth.TwitterAuthProvider());
    // this.afAuth.auth.signInWithPopup(new firebase.auth.TwitterAuthProvider());
  }

  getProvider(providerId) {
    switch (providerId) {
      case firebase.auth.GoogleAuthProvider.PROVIDER_ID:
        return new firebase.auth.GoogleAuthProvider();
      case firebase.auth.FacebookAuthProvider.PROVIDER_ID:
        return new firebase.auth.FacebookAuthProvider();
      case firebase.auth.GithubAuthProvider.PROVIDER_ID:
        return new firebase.auth.GithubAuthProvider();
      case firebase.auth.TwitterAuthProvider.PROVIDER_ID:
        return new firebase.auth.TwitterAuthProvider();
      default:
        throw new Error(`No provider implemented for ${providerId}`);
    }
  }
  
  async oauthLogin(provider) {
    try {
      const fbUser: firebase.auth.UserCredential = await this.afAuth.auth.signInWithPopup(provider);
      // Check user exists, If not exists first install
      const firebaseDbUser = await this.ngFire.getUser(fbUser.user.uid);
      if (!firebaseDbUser) {
        this.setInitialUserData(fbUser, fbUser.user.uid.substring(0, 6));
        
      } else {
        this.updateProviderInfo(fbUser);
      }
      
    } catch (err) {
      if (err.email && err.credential && err.code === 'auth/account-exists-with-different-credential') {
        const providers = await firebase.auth().fetchSignInMethodsForEmail(err.email)
        const firstPopupProviderMethod = providers.find(p => this.supportedPopupSignInMethods.includes(p));
  
        // User might be logged with Paviado account.
        if (!firstPopupProviderMethod) {
          alert('El email está asociado con una cuenta Paviado. Debes iniciar sesión con tu usuario y contraseña');
          // TODO Navigate to SignIn.
          // Allow to merge accounts afterwards: 
          // https://firebase.google.com/docs/auth/web/account-linking#vincula-credenciales-de-direcci%C3%B3n-de-correo-electr%C3%B3nico-y-contrase%C3%B1a-con-una-cuenta-de-usuario
          throw new Error(`Your account is linked to a provider that isn't supported.`);
        }
  
        const linkedProvider = this.getProvider(firstPopupProviderMethod);
        linkedProvider.setCustomParameters({ login_hint: err.email });
  
        const result = await firebase.auth().signInWithPopup(linkedProvider);
        this.log.debug('AuthService > oauthLogin: result', result);
        this.updateProviderInfo(result);
        const resultLink = await result.user.linkWithCredential(err.credential);
        this.log.debug('AuthService > oauthLogin: resultLink', resultLink);
        this.updateProviderInfo(resultLink);
      }
  
      // Handle errors...
      // toast.error(err.message || err.toString());
    }

    // // Check user exists, If not exists first install
    // const firebaseDbUser = await this.ngFire.getUser(fbUser.user.uid);
    // if (!firebaseDbUser) {
    //   this.setInitialUserData(fbUser, fbUser.user.uid);
      
    // } else {
    //   if ( !firebaseDbUser.provider_data?.google ) {
    //     this.updateProviderInfo(fbUser);
    //   }
    // }
  }

  /**
   * Sign up with email/password
   * @param email 
   * @param password 
   * @param subdomain
   */
  signUp(email, password, subdomain): Promise<void> {

    return this.afAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .then( (userCredential: firebase.auth.UserCredential) => {
        this.sendVerificationMail();
        this.setInitialUserData(userCredential, subdomain);

      }).catch( (error) => {
        this.log.error('AuthService > SignUp: error', error);
        window.alert(error.message)
      });
  }

  /**
   * Send email verfificaiton when new user sign up
   */
  sendVerificationMail(): Promise<void> {
    return this.afAuth.auth.currentUser
      .sendEmailVerification()
      .then( () => {
        // If we want to validate the email address
        //this.router.navigate(['/admin/verify-email-address']);
        this.router.navigate(['/admin']);
      })
      .catch( (error) => {
        this.log.error('AuthService > SendVerificationMail: error', error);
        window.alert(error.message)
      });
  }

  /**
   * Reset Forggot password
   * @param passwordResetEmail 
   */
  forgotPassword(passwordResetEmail): Promise<void> {

    return this.afAuth.auth
      .sendPasswordResetEmail(passwordResetEmail)
      .then( () => {
        window.alert('Email restablecimiento contraseña reenviado, comprueba tu correo de entrada.');
      })
      .catch((error) => {
        this.log.error('AuthService/ForgotPassword: error', error);
        window.alert(error);
      })
  }

  /**
   * Returns true when user is looged in with email and password
   */
  get isLoggedIn(): boolean {    
    const user = JSON.parse(localStorage.getItem('user'));
    // Desactivado confirmacion de email
    // return (user !== null && user.emailVerified !== false) ? true : false;
    return (user !== null && !user.isAnonymous) ? true : false;
  }

  /**
   * Returns true when user is looged in with email and password
   */
  get isPremiumAccount(): boolean {    
    const user = JSON.parse(localStorage.getItem('user'));
    // Desactivado confirmacion de email
    // // return (user !== null && user.emailVerified !== false) ? true : false;
    return (user !== null && user.role !== 'free') ? true : false;
  }

  /**
   * Returns true when user is looged in any type (Anonymous included)
   */
  get isLoggedInAny(): boolean {    
    const user = JSON.parse(localStorage.getItem('user'));
    // Desactivado confirmacion de email
    // return (user !== null && user.emailVerified !== false) ? true : false;
    return (user !== null) ? true : false;
  }

  /**
  * Create the user for FIRST access (SignUp)
  * @param user 
  */
  setInitialUserData(userCredential: firebase.auth.UserCredential, subdomainRequested: string) {
    this.log.debug('AuthService > setInitialUserData - userCredential: ', userCredential);

    const providerData = this.getProviderData(userCredential);

    const userData: User = {
      role: 'owner', 
      subscription: 'free', 
      uid: userCredential.user.uid,
      isAdminTourCompleted: false,
      email: userCredential.user.email,
      displayName: userCredential.user.displayName,
      photoURL: userCredential.user.photoURL,
      provider_data: providerData,
      emailVerified: userCredential.user.emailVerified,
      subdomain: subdomainRequested,
      roleAccessRestrictions: ''
    }
    
    this.ngFire.setInitialUserData(userData.uid, userData)
      .then( () => {
        // Create subdomain collection
        if (subdomainRequested) {
          var now = moment().locale('es').format('YYYY-MM-DDTHH:mm:ssZ');
          const subdomain: Subdomain = {
            id: subdomainRequested,
            createDate: now 
          }
          this.ngFire.setSubdomainRequested(subdomain);  
        }

      }).catch( (error) => {
        this.log.error('AuthService > setInitialUserData: error', error);
        window.alert('An error occurred, code: 1001')
      });
  }

  getProviderData(userCredential: firebase.auth.UserCredential): any {

    let provider_data: any = {
      google: null,
      facebook: null,
      twitter: null,
      paviado: null,
      spotify: null
    };

    switch (userCredential.additionalUserInfo?.providerId) {
      case firebase.auth.GoogleAuthProvider.PROVIDER_ID: { //google.com
        this.log.debug('AuthService > updateProviderInfo - profile: ', userCredential.additionalUserInfo.profile);
        provider_data.google = userCredential.additionalUserInfo.profile;
        break; 
      } 
      case firebase.auth.FacebookAuthProvider.PROVIDER_ID: { 
        this.log.debug('AuthService > updateProviderInfo - profile: ', userCredential.additionalUserInfo.profile);
        provider_data.facebook = userCredential.additionalUserInfo.profile;
        break; 
      } 
      case firebase.auth.TwitterAuthProvider.PROVIDER_ID: { 
        this.log.debug('AuthService > updateProviderInfo - profile: ', userCredential.additionalUserInfo.profile);
        provider_data.twitter = userCredential.additionalUserInfo.profile;
        break; 
      } 
      default: { 
        this.log.warn('AuthService > updateProviderInfo - providerId not defined');
        break;              
      }
    }

    return provider_data;
  }

  /**
  * Create the user for FIRST access (SignUp)
  * @param user 
  */
  updateProviderInfo(userCredential: firebase.auth.UserCredential) {
    this.log.debug('AuthService > updateProviderInfo - userCredential: ', userCredential);

    // TODO Insertar solo en campo indicado. Sino sobreescribe
    const provider_data = this.getProviderData(userCredential);  

    const user: User = {
      uid: userCredential.user.uid,
      email: userCredential.user.email,
      emailVerified: userCredential.user.emailVerified,
      photoURL: userCredential.user.photoURL,
      provider_data: provider_data
    }

    this.ngFire.updateUser(userCredential.user.uid, user);

  }

  /**
   * 
   */
  signOut(source: string) {

    this.store.dispatch( new UnsetUserAction() );
    
    return this.afAuth.auth
      .signOut()
      .then( () => {
        localStorage.removeItem('user');
        // TODO 
        if (environment.production) {
          if (source === 'users') {
            window.location.href = 'https://app.paviado.com/';
          } else {
            window.location.href = 'https://mi.paviado.com/';
          }
        } else {
          window.location.href = 'http://localhost:4200/';
        }
        //this.router.navigate(['/']);
    });
  }

}