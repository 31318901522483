


export class Commerce {

  id: string;
  subdomain?: string;

  owners?: {
    pendingOwnership: boolean
  }

  features: {
    jukeboxes?: {
      jukeboxes_parties: { key: string, value: string }[]
    },
    menu: {
      default: {
        holder: {
          images: string[]  
        }, 
        menu_images: MenuImage[]  
      }
    },
    booking: {
      method: string
    }
    entertainments: any
  }

  place: {
    profile: {
      displayName: string,
      description: string,
      music: string[],
      types: string[],
      images: {
        urlBanner: string,
        urlImgBanner: string,
        urlLogoThumbnail: string
      }
    }
    site: {
      structure: {
        capacity: {
          inside: number,
          outsite: number
        }
        terrace: boolean,
      }
      location: {
        city: string,
        country: string,
        address: string,
        coords: {
          latitude: string,
          longitude: string
        }
      }
    }
  }

  metadata: {
    createDate?: string;
    updateDate?: string;
  }

}

export class MenuImage {  
  id: string;
  url: string;
  tags: string;
  isActive: boolean;
  storagePath: string;
  order: number;
}