


import { Action } from '@ngrx/store'
import { Message } from '../../models/message.model';


export const SET_MESSAGES = '[Messages] Set Messages';
export const UNSET_MESSAGES = '[Messages] Unset Messages';


export class SetMessagesAction implements Action {
  readonly type = SET_MESSAGES;

  constructor( public messages: Message[] ) { }
}

export class UnsetMessagesAction implements Action {
  readonly type = UNSET_MESSAGES;
}

export type actions = SetMessagesAction | UnsetMessagesAction;