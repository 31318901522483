


import * as fromUI from '../actions/ui.actions';

export interface UIState {
    isLoading: boolean;
    showSidebar: boolean;
    loadingItems: string[];
}

const initState: UIState = {
    isLoading: false,
    showSidebar: false,
    loadingItems: []
};

export function uiReducer( state = initState, 
                           action: fromUI.acciones ): UIState {

    switch ( action.type ) {

        case fromUI.LOADING_CHANGE:
            return {
                ...state,
                isLoading: action.loading
            };

        case fromUI.LOADING_ADD:
          return {
            ...state,
            loadingItems: [...state.loadingItems, action.item]
          };

        case fromUI.LOADING_REMOVE:
            // const loadingItems = state.loadingItems < 1 ? 0 : state.loadingItems - action.items;
            return {
                ...state,
                loadingItems: [
                  ...state.loadingItems.filter( iItem => iItem !== action.item )
              ]
            };

        case fromUI.TOGGLE_SIDEBAR:
            return {
              ...state,
              showSidebar: !state.showSidebar
            };
    
        default:
            return state;
    }
}