


export default class LeadValidator {

  static LEAD_TABLET_NO_DATA = 'Tablet: Sin datos';
  static LEAD_TABLET_MAIL_OK = 'Tablet: Mail Ok';
  static LEAD_TABLET_MAIL_KO = 'Tablet: Mail Inválido';
  static LEAD_WEB_MAIL_OK = 'Web: Mail Ok';
  static LEAD_WEB_MAIL_KO = 'Web: Mail Inválido';

  static SWEARING: string[] = ['putas','zorra']


  static isRealEmail(email: string): boolean {
    return this.isValidEmail(email) && this.mailProviderReasonable(email) && this.isNotSwearing(email);
  }
  
  /**
   * 
   * @param email 
   */
  static isValidEmail(email: string): boolean {
    return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
  }

  /**
   * 
   * @param email 
   */
  static isNotSwearing(email: string): boolean {
    if (this.SWEARING.some(swear => email.indexOf(swear) != -1)) {
      return false;
    }
    return true;
  }

  /**
   * 
   * @param email 
   */
  static mailProviderReasonable(email: string): boolean {
    
    if (email.indexOf('@gmail.com') || email.indexOf('@hotmail.com')) {
      if (email.substring(0, email.indexOf('@')).length < 5) {
        return false;
      }
    }

    return true;
  }

}